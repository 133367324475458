import { Injectable } from '@angular/core';
import { IMenuProvider, Menu } from '@maersk-global/angular-shared-library';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { AuthHelper } from '../../../helper/auth-helper';
import { CookieService } from 'ngx-cookie-service';
import { SessionTimeoutService } from '../common/session-time-out-service';

@Injectable({
  providedIn: 'root',
})
export class HeaderMenuService implements IMenuProvider {
  private _authHelper: AuthHelper = new AuthHelper(this._cookieService);
  constructor(
    private router: Router,
    private _cookieService: CookieService,
    private _sessionTimeOut: SessionTimeoutService
    // Inject User Auth Service to figure out which menus are accessible to the logged in user
  ) {}

  getMenu(): Menu[] {
    return [
      {
        onClick: () => {
          this._authHelper.logout();
        },
        icon: 'door-arrow-right',
        sequence: 4,
      },
      {
        icon: 'user-circle',
        sequence: 3,
        disabled: true,
      },
      {
        icon: 'bell',
        sequence: 2,
        disabled: true,
      },
      {
        icon: 'question-circle',
        sequence: 1,
        disabled: true,
      },
    ];
  }
}
