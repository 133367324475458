import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IForgeRockToken } from '../../../helper/auth-helper';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  constructor(private http: HttpClient) {}

  getUserList(): string[] {
    const userList: string[] = ['Name_1', 'Name_2', 'Name_3'];
    return userList;
  }

  httpCall(url: string): Observable<any> {
    return this.http.get(url);
  }

  fetchNewTokensUsingRefreshToken(requestBody: string) {
    const result = this.http.post<IForgeRockToken>(
      environment.access_token_api,
      requestBody,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      }
    );

    return result;
  }
}
