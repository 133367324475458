import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { ADMIN_DEV_EMAILS } from '../common/constants/app.constants';
export class AuthHelper {
  adminDevEmails: string[] = ADMIN_DEV_EMAILS;

  constructor(private _cookieService: CookieService) {}

  parseJwt<T>(token: string): T {
    if (token != '') {
      let encodedString: string = '';
      encodedString = token.split('.')[1].replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(encodedString)) as T;
    } else {
      throw 'Token Not Initialized';
    }
  }

  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    this._cookieService.delete(environment.cookie_id_token_store_name, '/');
    const applicationUrl = window.location.origin;
    const logoutUrl = applicationUrl + '/logout';
    window.location.href = logoutUrl;
  }

  checkIsAuthenticated(): boolean {
    const loginStatus: string = sessionStorage.getItem('loginStatus') ?? '';
    if (loginStatus != 'LoggedIn') {
      this.logout();
      return false;
    } else if (loginStatus == 'LoggedIn') {
      return true;
    }
    return false;
  }

  checkIsAuthorized(): boolean {
    const id_token: string =
      localStorage.getItem(environment.iam_key + 'id_token') ?? '';
    const token = this.parseJwt<IIdToken>(id_token);
    const email = token.email ?? '';
    if (!this.adminDevEmails.includes(email!)) {
      return false;
    }
    return true;
  }
}

export interface IIdToken {
  firstname: string;
  lastname: string;
  email: string;
  username: string;
  tokenName: string;
}

export interface IAccessToken {
  uniqueId: string;
  accessType: string;
  tokenName: string;
  sub: string;
  subname: string;
}

export interface IForgeRockToken {
  access_token: string;
  refresh_token: string;
  scope: string;
  id_token: string;
  token_type: string;
  expires_in: number;
}
