/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type ItemType = 'VendorEmails' | 'LiabilityLetter';

export const ItemType = {
    VendorEmails: 'VendorEmails' as ItemType,
    LiabilityLetter: 'LiabilityLetter' as ItemType
};