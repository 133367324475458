<lib-panel *ngIf="cargoDetail$ | async">
  <header>{{ item.label }} - {{ containerNumber }}</header>
  <left-corner-menu>
    <mc-button
      appearance="{{ !weightAsKg ? 'neutral' : 'primary' }}"
      (click)="weightAsKg = true; onWeightClick()"
      fit="small"
      label="KG"></mc-button>
    <mc-button
      fit="small"
      appearance="{{ weightAsKg ? 'neutral' : 'primary' }}"
      (click)="weightAsKg = false; onWeightClick()"
      label="LB"></mc-button>
  </left-corner-menu>
  <content>
    <app-lib-form [items]="items"></app-lib-form>
  </content>
</lib-panel>
