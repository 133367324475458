import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import {
  LibFormComponent,
  PanelComponent,
  TemplateModel,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { GlobalService } from '../../../global-service';
import { getStatusTextFromStatusCode } from '../../customer-recovery/temporary-constant';
import { combineLatest, lastValueFrom, map, Observable, tap } from 'rxjs';
import { InvoiceStatusEnum } from '../../../common/models/invoiceStatusEnum';
import { CaseInvoiceDetailDto } from '../../../common/models/caseInvoiceDetailDto';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { McSelect } from '@maersk-global/mds-components-core';

type InvoiceTemplateExtended = { template: Array<TemplateModel> } & {
  invoice: CaseInvoiceDetailDto;
  isCancelEnabled: boolean;
};

@Component({
  selector: 'app-invoice-letters-log',
  standalone: true,
  imports: [CommonModule, PanelComponent, LibFormComponent],
  templateUrl: './invoice-letters-log.component.html',
  styleUrl: './invoice-letters-log.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class InvoiceLettersLogComponent {
  userId: string = sessionStorage.getItem('userId') || '';
  showCancelInvoiceModal: boolean = false;
  enableIssueNewInvoiceButton: boolean = false;
  currentSelectedInvoice?: CaseInvoiceDetailDto;

  invoicesExtended$: Observable<InvoiceTemplateExtended[] | undefined> =
    this._globalService.invoices$.pipe(
      map((invoices) =>
        invoices
          ?.map((invoice, index) => {
            return {
              template: this.prepareTemplateModelsForInvoices(
                invoice,
                index + 1
              ),
              invoice: invoice,
              isCancelEnabled:
                invoice.invoiceNumber &&
                invoice.invoiceStatusCode &&
                ![
                  InvoiceStatusEnum.CancellationSubmitted,
                  InvoiceStatusEnum.InvoiceCancellationRequestFailed,
                  InvoiceStatusEnum.InvoiceCancellationRequested,
                  InvoiceStatusEnum.InvoiceCancelled,
                  InvoiceStatusEnum.PaymentReceived,
                ].includes(invoice.invoiceStatusCode),
            } as InvoiceTemplateExtended;
          })
          ?.sort(
            (letter1, letter2) =>
              new Date(letter2.invoice.createdDate ?? '').getTime() -
              new Date(letter1.invoice.createdDate ?? '').getTime()
          )
      ),
      tap((invoicesExtended) => {
        if (invoicesExtended && invoicesExtended.length > 0) {
          this.enableIssueNewInvoiceButton =
            invoicesExtended[0].invoice?.invoiceStatusCode ==
            InvoiceStatusEnum.InvoiceCancelled
              ? true
              : false;
        } else {
          this.enableIssueNewInvoiceButton = false;
        }
      })
    );

  isCountryS4HanaMigrated$: Observable<boolean> = combineLatest([
    this._globalService.customerRecoveryData$,
    this._globalService.countries$,
  ]).pipe(
    map(
      ([customerRecoveryData, countries]) =>
        !!countries.find(
          (country) =>
            country.code === customerRecoveryData.podCountryCode &&
            country.isS4HanaMigrated
        )
    )
  );

  constructor(
    private _globalService: GlobalService,
    private _toasterService: ToasterService,
    private _customerRecoveryClaimService: CustomerRecoveryClaimService
  ) {}

  issueNewInvoiceClicked() {
    this._globalService.updateIssueInvoiceVisibility(true);
  }

  onCancelInvoiceButtonClicked(invoice: CaseInvoiceDetailDto) {
    this.currentSelectedInvoice = invoice;
    this.showCancelInvoiceModal = true;
  }

  async onInvoiceCancelled(mcSelect: HTMLElement) {
    const response = await lastValueFrom(
      this._customerRecoveryClaimService.customerRecoveryClaimsCaseIdCancelInvoiceCaseInvoiceDetailIdPut(
        {
          reason: (mcSelect as McSelect).value?.toString(),
          user: this.userId,
        },
        this.currentSelectedInvoice?.caseId ?? 0,
        this.currentSelectedInvoice?.caseInvoiceDetailId ?? 0
      )
    );
    if (response.data) {
      this._globalService.reloadInvoices();
      this._toasterService.showToast({
        message: 'Invoice cancellation request initiated successfully!',
        type: 'success',
      });
    }
  }

  prepareTemplateModelsForInvoices(
    invoice: CaseInvoiceDetailDto,
    index: number
  ) {
    return [
      {
        label: 'Serial Number',
        value: index,
        type: 'label',
        width: { size: 18, unit: '%' },
        name: 'serialNumber',
      } as TemplateModel,
      {
        label: 'Created By',
        value: invoice.createdBy,
        type: 'label',
        width: { size: 18, unit: '%' },
        name: 'createdBy',
      } as TemplateModel,
      {
        label: 'Creation Date',
        value: invoice.createdDate,
        type: 'label',
        width: { size: 22, unit: '%' },
        name: 'createdDate',
        valueType: 'date',
      } as TemplateModel,
      {
        label: 'Invoice Status',
        value: this.getStatusTextFromStatusCode(invoice.invoiceStatusCode),
        type: 'label',
        width: { size: 20, unit: '%' },
        name: 'invoiceStatus',
      } as TemplateModel,
      {
        label: 'Invoice Number',
        value: !!invoice.invoiceNumber ? invoice.invoiceNumber : '-',
        type: 'label',
        width: { size: 22, unit: '%' },
        name: 'invoiceNumber',
      } as TemplateModel,
      {
        label: 'Payment Received',
        value: this.isPaymentReceived(invoice.invoiceStatusCode),
        type: 'label',
        width: { size: 18, unit: '%' },
        name: 'paymentReceived',
      } as TemplateModel,
      {
        label: 'Fact Reference Number',
        value: !!invoice.factReferenceNumber
          ? invoice.factReferenceNumber
          : '-',
        type: 'label',
        width: { size: 18, unit: '%' },
        name: 'factReferenceNumber',
      } as TemplateModel,
      {
        label: 'Due Date',
        value: invoice.dueDate,
        type: 'label',
        width: { size: 22, unit: '%' },
        name: 'dueDate',
        valueType: 'date',
      } as TemplateModel,
      {
        label: 'Payment Date',
        value: invoice.paymentDate,
        type: 'label',
        width: { size: 20, unit: '%' },
        name: 'paymentDate',
        valueType: 'date',
      } as TemplateModel,
      {
        label: 'Customer Number',
        value: invoice.factCode,
        type: 'label',
        width: { size: 18, unit: '%' },
        name: 'factCode',
      } as TemplateModel,
      {
        label: 'Cancellation Reason',
        value: invoice.cancellationReason,
        type: 'label',
        width: { size: 50, unit: '%' },
        name: 'cancellationReason',
        hidden: !invoice.cancellationReason,
      } as TemplateModel,
    ];
  }

  getStatusTextFromStatusCode(
    invoiceStatusCode: InvoiceStatusEnum | undefined
  ) {
    return getStatusTextFromStatusCode(invoiceStatusCode);
  }

  isPaymentReceived(invoiceStatusCode: InvoiceStatusEnum | undefined) {
    return InvoiceStatusEnum.PaymentReceived === invoiceStatusCode
      ? 'Yes'
      : 'No';
  }
}
