<ng-container *ngIf="invoicesExtended$ | async as invoicesExtended">
  <lib-panel *ngIf="invoicesExtended.length > 0">
    <header>Previously Issued Invoices</header>
    <left-corner-menu>
      <mc-button
        label="Issue new invoice"
        variant="outlined"
        appearance="neutral"
        fit="small"
        padding="default"
        [disabled]="!enableIssueNewInvoiceButton"
        (click)="issueNewInvoiceClicked()"></mc-button>
    </left-corner-menu>
    <content>
      <div class="invoice-list-wrapper">
        @for (invoiceAsFormItems of invoicesExtended; track $index) {
          <div *ngIf="$index > 0" class="hr"></div>
          <div class="invoice-list">
            <app-lib-form [items]="invoiceAsFormItems.template"></app-lib-form>
            <div>
              <mc-button
                *ngIf="invoiceAsFormItems.isCancelEnabled"
                label="Cancel"
                variant="filled"
                fit="small"
                padding="default"
                (click)="
                  onCancelInvoiceButtonClicked(invoiceAsFormItems.invoice)
                "></mc-button>
            </div>
          </div>
        }
      </div>
    </content>
  </lib-panel>

  <!--If country is not s4 hana migrated and doesn't have any invoices created then show the following screen-->
  <ng-container
    *ngIf="{
      value: isCountryS4HanaMigrated$ | async
    } as isCountryS4HanaMigrated">
    <lib-panel
      [hideHeader]="true"
      *ngIf="!isCountryS4HanaMigrated.value && invoicesExtended.length === 0">
      <content>
        <div class="invoice-feature-unavailable">
          <span>Invoice feature coming soon</span>
          <div>
            You will be able to raise an invoice once the POD country is
            migrated to S4 Hana. Please login to the
            <b>DERT</b>
            application for raising an invoice request.
          </div>
        </div>
      </content>
    </lib-panel>
  </ng-container>
</ng-container>

<ng-container *ngIf="showCancelInvoiceModal">
  <mc-modal
    heading="Cancel Invoice"
    [open]="true"
    fit="small"
    dimension="small"
    (closed)="showCancelInvoiceModal = false">
    <span class="modal-body">
      <mc-input
        label="Reason for cancellation (optional)"
        fit="medium"
        #mcInput
        name="reason"
        placeholder="Enter reason here"></mc-input>
    </span>
    <mc-button
      focusstartanchor
      slot="primaryAction"
      appearance="primary"
      onclick="onPrimaryActionClick"
      dialogaction="Submit"
      fit="small"
      (click)="onInvoiceCancelled(mcInput)">
      Save
    </mc-button>
    <mc-button
      focusendanchor
      slot="secondaryAction"
      appearance="neutral"
      variant="outlined"
      fit="small"
      onclick="onSecondaryActionClick"
      dialogaction="cancel">
      Cancel
    </mc-button>
  </mc-modal>
</ng-container>
