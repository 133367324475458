<div class="moves">
  <app-grid
    [schema]="gridSchema"
    [data]="containerMovesGridData$ | async"
    [showPanel]="false"
    [showRowSelector]="true"
    [showPagination]="false"
    [rowSelectorType]="'radio'"
    (rowSelectionChange)="rowSelectionChanged($event)"></app-grid>
</div>
