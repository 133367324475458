[
    {
        "value": "AEC",
        "label": "United Arab Emirates AREA"
    },
    {
        "value": "CAC",
        "label": "Central America, Andina, Caribbean"
    },
    {
        "value": "CAR",
        "label": "Caribbean Sea Area"
    },
    {
        "value": "CME",
        "label": "Central Mediterranean Area"
    },
    {
        "value": "CSE",
        "label": "Central South Europe Area"
    },
    {
        "value": "CWA",
        "label": "Central West Africa AREA"
    },
    {
        "value": "EAF",
        "label": "Eastern Africa Area"
    },
    {
        "value": "EEC",
        "label": "Eastern Europe Area"
    },
    {
        "value": "EME",
        "label": "Eastern Mediterranean Area"
    },
    {
        "value": "ESA",
        "label": "East Coast South America Area"
    },
    {
        "value": "GBC",
        "label": "United Kingdom and Ireland Area"
    },
    {
        "value": "GCC",
        "label": "Greater China Area"
    },
    {
        "value": "IBC",
        "label": "India and Bangladesh Area"
    },
    {
        "value": "IDP",
        "label": "Indonesia and Philippines Area"
    },
    {
        "value": "MEK",
        "label": "Mekong Area"
    },
    {
        "value": "MIC",
        "label": "Middle America Area"
    },
    {
        "value": "NDC",
        "label": "Nordic Area"
    },
    {
        "value": "NEA",
        "label": "North East Asia Area"
    },
    {
        "value": "NEC",
        "label": "North Europe Continent Area"
    },
    {
        "value": "NLC",
        "label": "North West Continent Area"
    },
    {
        "value": "NOA",
        "label": "North America Area"
    },
    {
        "value": "NWA",
        "label": "Northern West Africa Area"
    },
    {
        "value": "NWC",
        "label": "North West Continent Area"
    },
    {
        "value": "OCC",
        "label": "Oceania Area"
    },
    {
        "value": "PKC",
        "label": "Pakistan Area"
    },
    {
        "value": "SAC",
        "label": "Saudi Arabia AREA"
    },
    {
        "value": "SAI",
        "label": "Southern Africa and Islands Area"
    },
    {
        "value": "SCA",
        "label": "Scandinavia Area"
    },
    {
        "value": "SEA",
        "label": "South-East Asia Area"
    },
    {
        "value": "SNC",
        "label": "Northern West Africa Area"
    },
    {
        "value": "SWA",
        "label": "Southern West Africa Area"
    },
    {
        "value": "SWE",
        "label": "South West Europe Area"
    },
    {
        "value": "TMS",
        "label": "Thailand Malaysia and Singapore"
    },
    {
        "value": "VCM",
        "label": "Vietnam Cambodia and Myanmar Area"
    },
    {
        "value": "WSA",
        "label": "West Coast South America AREA"
    }
]