import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { EmailTemplateDto } from '../../common/models/emailTemplateDto';
import { EmailTemplateService } from '../../common/services/email/email-template.service';
import { EmailTemplateDtoIEnumerableResponse } from '../../common/models/emailTemplateDtoIEnumerableResponse';
import { ToasterService } from '@maersk-global/angular-shared-library';
import {
  AngularEditorConfig,
  AngularEditorModule,
} from '@kolkov/angular-editor';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthHelper } from '../../helper/auth-helper';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../../common/services/customer-recovery/common.service';
import { ClusterDto } from '../../common/models/clusterDto';

@Component({
  selector: 'email-template-edit',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AngularEditorModule],
  templateUrl: './email-template-edit.component.html',
  styleUrl: './email-template-edit.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [EmailTemplateService],
})
export class EmailTemplateEditComponent implements OnInit {
  @ViewChild('emailBodyContainer') emailBodyContainer?: ElementRef;
  authHelper: AuthHelper = new AuthHelper(this._cookieService);
  formGroup: FormGroup = new FormGroup({});
  selectedTemplate: EmailTemplateDto | undefined;
  updatingTemplate: boolean = false;
  useRichTextEditor: boolean = true;
  authorized: boolean = false;
  loadingClusters: boolean = true;
  loadingTemplates: boolean = false;
  countryClusters: ClusterDto[] = [];
  selectedCluster: ClusterDto | undefined;
  emailTemplates: EmailTemplateDto[] = [];

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: false,
    toolbarPosition: 'top',
  };

  /**
   *
   */
  constructor(
    private _cookieService: CookieService,
    private _toasterService: ToasterService,
    private _emailTemplateService: EmailTemplateService,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (
      this.authHelper.checkIsAuthenticated() &&
      this.authHelper.checkIsAuthorized()
    ) {
      this.authorized = true;
      this.formGroup = new FormGroup({
        cluster: new FormControl('', Validators.required),
        template: new FormControl('', Validators.required),
        defaultButton: new FormControl({ value: '', disabled: true }),
        saveButton: new FormControl({ value: '', disabled: true }),
        htmlContent: new FormControl(''),
        editorMode: new FormControl({ value: '', disabled: true }),
      });
      this.countryClusters$?.subscribe((clusters) => {
        if (!clusters) return;
        this.countryClusters = clusters;
        this.loadingClusters = false;
      });
      this.resetFormStates();
    } else {
      this.authorized = false;
      this.loadingClusters = false;
      this.loadingTemplates = false;
    }
  }

  /**
   *Main observable Country Clusters
   */
  countryClusters$?: Observable<ClusterDto[] | null> = this._commonService
    .commonCountryClustersGet()
    .pipe(
      map((response: ClusterDto[]) => {
        if (!response) return null;
        return response;
      }),
      shareReplay(1)
    );

  async clusterSelected(event: any) {
    const Id = event.detail.value;
    const clusters = await firstValueFrom(this.countryClusters$!);
    const selectedCluster = clusters?.find(
      (cluster) => cluster.clusterCode === Id
    );

    if (this.selectedCluster == selectedCluster) return;

    this.selectedCluster = selectedCluster;
    this.formGroup.controls['template'].setValue('');
    this.selectedTemplate = undefined;
    this.resetFormStates();
    if (!this.selectedCluster) {
      this.emailTemplates = [];
      return;
    }
    this.loadingTemplates = true;
    // get email templates based on selected cluster
    this._emailTemplateService
      .emailTemplateCountryClusterCountryClusterGet(
        this.selectedCluster!.clusterName!
      )
      .pipe(
        map((response: EmailTemplateDtoIEnumerableResponse) => {
          if (!response || !response.isSuccess || !response.data) return null;
          return response.data;
        })
      )
      .subscribe(
        (templates) => {
          if (!templates) return;
          this.emailTemplates = templates;
          this.loadingTemplates = false;
        },
        (error) => {
          console.error(error);
          this.loadingTemplates = false;
          this._toasterService.showToast({
            message: 'Something went wrong while loading templates!',
            type: 'error',
          });
        }
      );
  }

  async templateSelected(event: any) {
    const Id = event.detail.value;
    const selectedTemplate = this.emailTemplates.find(
      (template) => template.id === Id
    );
    this.selectedTemplate = selectedTemplate;
    this.formGroup.controls['htmlContent'].setValue(
      this.selectedTemplate?.templateContentForDisplay
    );
    this.resetFormStates();
  }

  ResetContentToDefault() {
    if (this.useRichTextEditor) {
      this.formGroup.controls['htmlContent'].setValue(
        this.selectedTemplate?.templateContentForDisplay
      );
    } else {
      this.emailBodyContainer!.nativeElement.innerHTML =
        this.selectedTemplate?.templateContentForDisplay || '';
    }
  }

  private resetFormStates() {
    if (!this.selectedCluster) {
      this.formGroup.controls['template'].disable();
    } else {
      this.formGroup.controls['template'].enable();
    }

    if (!this.selectedTemplate) {
      this.formGroup.controls['defaultButton'].disable();
      this.formGroup.controls['saveButton'].disable();
      this.formGroup.controls['editorMode'].disable();
      return;
    }
    this.formGroup.controls['defaultButton'].enable();
    this.formGroup.controls['saveButton'].enable();
    this.formGroup.controls['editorMode'].enable();
  }

  changeEditorMode(event: any) {
    this.useRichTextEditor = event.detail;
  }

  SaveTemplateContent() {
    if (
      this.authHelper.checkIsAuthenticated() &&
      this.authHelper.checkIsAuthorized()
    ) {
      const request = this.selectedTemplate;
      if (!this.detechChanges()) {
        return;
      }
      if (this.useRichTextEditor) {
        request!.templateContentForDisplay =
          this.formGroup.controls['htmlContent'].value;
      } else {
        request!.templateContentForDisplay =
          this.emailBodyContainer?.nativeElement.innerHTML;
      }

      this.updatingTemplate = true;
      this.editorConfig.editable = false;
      this._emailTemplateService.emailTemplatePut(request!).subscribe(
        () => {
          this.updatingTemplate = false;
          this.editorConfig.editable = true;
          this._toasterService.showToast({
            message: 'Template Updated!',
            type: 'success',
          });
        },
        (error) => {
          console.error(error);
          this.updatingTemplate = false;
          this.editorConfig.editable = true;
          this._toasterService.showToast({
            message: 'Something went wrong while updating template!',
            type: 'error',
          });
        }
      );
    } else {
      this.authorized = false;
    }
  }

  detechChanges(): boolean {
    let changsDetected = true;
    if (
      this.useRichTextEditor &&
      this.selectedTemplate?.templateContentForDisplay ==
        this.formGroup.controls['htmlContent'].value
    ) {
      changsDetected = false;
    } else if (
      this.selectedTemplate?.templateContentForDisplay ==
      this.emailBodyContainer?.nativeElement.innerHTML
    ) {
      changsDetected = false;
    }
    if (!changsDetected) {
      this._toasterService.showToast({
        message: 'No Changes detected!',
        type: 'info',
      });
    }
    return changsDetected;
  }
}
