import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  first,
  firstValueFrom,
  forkJoin,
  lastValueFrom,
  map,
  merge,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { CustomerRecoveryClaimService } from '../../common/services/customer-recovery/customer-recovery-claim.service';
import { UserPreferencesService } from '../../common/services/user-preferences/user-preferences.service';
import {
  ALIGN,
  COLUMN_CLICK_TYPE,
  COLUMN_TYPE,
  GridCellData,
  GridColumnSchema,
  GridFilter,
  GridRowData,
  MCDate,
  SORT_ORDER,
} from '@maersk-global/angular-shared-library';
import { Screen } from '../../common/models/screen';
import * as countriesJSon from '../../common/models/countries.json';
import * as clusterJson from '../../common/models/countryCluster.json';
import { CustomerRecovery } from '../../common/models/customer-recovery';
import { ClaimStatus } from '../../common/enum/claim-status';
import { CustomerRecoveryRequest } from '../../common/models/customer-recovery-request';
import { ClaimStatusRequest } from '../../common/models/claim-status-request';
import { ClaimStatusResponse } from '../../common/models/claim-status-response';
import { UserClaimsAssignmentRequest } from '../../common/models/user-claims-assignment-request';
import { KeyValue } from '../../common/models/keyValue';
import { WorkflowService } from '../../common/services/customer-recovery/workflow.service';
import { CommonService } from '../../common/services/customer-recovery/common.service';
import { VersionDetails } from '../../common/models/version-details';
import {
  DUPLICATE_WORK_ORDER_MSG,
  ICON_ARROW_CLOCKWISE,
  ICON_PAPER_CLIP,
  TEMPLATE_TYPE,
  IA_IN_PROGRESS_TEXT,
  ICON_CLOCK_STOPWATCH,
  ICON_ARROW_CLOCKWISE_TIMES,
  IA_IMAGE_AVAILABLE_TEXT,
  Image_Source,
  IA_IN_PROGRESS_VALUE,
  IA_NOT_AVAILABLE,
  IA_BOTH_AVAILABLE,
  IA_EIR_AVAILABLE,
  IA_VENDOR_AVAILABLE,
} from '../../common/constants/app.constants';
import { AssignedUsersResponse } from '../../common/models/assignedUsersResponse';
import { DropDownOption } from '@maersk-global/angular-shared-library/lib/models/drop-down';
import { environment } from '../../../environments/environment';
import { CountryDto } from '../../common/models/countryDto';
import { ClusterDto } from '../../common/models/clusterDto';
import { Region } from '../../common/models/region';
import {
  aboveCoverType,
  defaultFilters,
  getStatusTextFromStatusCode,
  statusTypeMaster,
  workOrderMode,
  workOrderStatusType,
} from './temporary-constant';
import { UserClaimsUnassignmentRequest } from '../../common/models/user-claims-unassignment-request';
import { InspectionDetailsDTO } from '../../common/models/inspectionDetailsDTO';
import { logger } from '../../logger';
import { GlobalService } from '../../global-service';
import { loggerType } from '../../common/models/logger-type';
import * as gridconfigurationMapping from './grid-configuration-mapping';
import { InvoiceStatusEnum } from '../../common/models/invoiceStatusEnum';

export class CustomerRecoveryDataManager {
  eirImageFetchError: boolean = false;
  gridConfigMapping = gridconfigurationMapping.default;
  constructor(
    private _customerRecoveryClaimService: CustomerRecoveryClaimService,
    private _userPreferencesService: UserPreferencesService,
    private _workflowService: WorkflowService,
    private _commonService: CommonService,
    private _globalService: GlobalService
  ) {
    this.setVersionDetailForTemplateAndWorkflow();
  }

  onGridColumnClick?: Function;
  apiVersion: string = '1.0';
  userId: string = sessionStorage.getItem('userId') || '';
  logger: logger = new logger(this._globalService);
  /**
   * grid filters are the default filters used by grid.
   */
  gridFilter: GridFilter = {
    pageNumber: 1,
    pageSize: 25,
    sortOrder: SORT_ORDER.ASC,
    sortColumn: '',
  };
  /**
   * request object used for API calls of customer recovery. We `always`/`will` maintain latest filter changes here.
   */
  customerRecoveryRequest: CustomerRecoveryRequest =
    this.initCustomerRecoveryObject();

  //to do via api
  countries: any = (countriesJSon as any).default;
  clusters: any = (clusterJson as any).default;
  eirAvailabilityIconClicked: boolean = false;

  private gridSchemaInitial$: Observable<GridColumnSchema[] | null> =
    this._userPreferencesService.customerRecoveryScreen$.pipe(
      switchMap((value: Screen | undefined) =>
        this.createCustomerRecoveryGridSchema(value)
      ),
      tap((schema) => this.gridSchemaSubject$$.next(schema))
    );

  private gridSchemaSubject$$: BehaviorSubject<GridColumnSchema[] | null> =
    new BehaviorSubject<GridColumnSchema[] | null>([]);

  private gridDataInitial$: Observable<GridRowData[] | null> =
    this._userPreferencesService.customerRecoveryScreen$.pipe(
      switchMap(async (value: Screen | undefined) => {
        const tabIndex = await firstValueFrom(
          this._globalService.currentTabIndex$
        );
        if (!value || tabIndex) return null;
        this.loadInitialFiltersForAllCasesGridView();
        await this.fetchDataFromServer();
        this.gridDataSubject$$.next(this.gridData);
        return this.gridData;
      })
    );

  private gridDataSubject$$: BehaviorSubject<GridRowData[] | null> =
    new BehaviorSubject<GridRowData[] | null>([]);

  private totalRecordsSubject$$: Subject<number> = new Subject<number>();

  totalRecords$?: Observable<number> =
    this.totalRecordsSubject$$.asObservable();

  gridSchema$: Observable<GridColumnSchema[] | null> =
    this.gridSchemaInitial$.pipe(first());

  gridData$: Observable<GridRowData[] | null> = merge(
    this.gridDataInitial$.pipe(take(1)),
    this.gridDataSubject$$.asObservable()
  );

  get gridSchema(): GridColumnSchema[] | null {
    return this.gridSchemaSubject$$.value;
  }

  set gridSchema(schema: GridColumnSchema[] | null) {
    this.gridSchemaSubject$$.next(schema);
  }

  get gridData(): GridRowData[] | null {
    return this.gridDataSubject$$.value;
  }

  set gridData(schema: GridRowData[] | null) {
    this.gridDataSubject$$.next(schema);
  }

  get userPreferences() {
    return this._userPreferencesService.userPreferences;
  }

  get screen() {
    return this.userPreferences?.preference.screens?.find(
      (screen) => (screen.id = 1)
    );
  }

  get podCusterFilter() {
    return this.screen?.searchCriteria?.find(
      (criteria) => criteria.key === 'podCluster'
    );
  }

  /**
   *
   * @param value screen specific preferences for the customer recovery grid.
   */
  async createCustomerRecoveryGridSchema(value: Screen | undefined) {
    const tabIndex = await firstValueFrom(this._globalService.currentTabIndex$);
    if (!value || !value.columns) return null;
    return value.columns.map((column) => {
      if (
        !column.name ||
        !column.displayName ||
        !column.columnType ||
        !column.sequence
      )
        return {} as GridColumnSchema;
      const filterMapping = this.gridConfigMapping.filterConfig.filter(
        (i: { name: any }) => i.name == column.name
      )[0];

      return {
        column: column.name,
        displayName: column.displayName,
        align: column.columnType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
        hidden: column.isHidden,
        columnType: column.columnType.toUpperCase() as COLUMN_TYPE,
        dateFormat: 'd MMM y h:mm:ss a',
        onClick: this.onGridColumnClick?.bind(this),
        columClickType: this.selectClickAbleColumnType(column.name),
        placeholderText: column.name === 'comments' ? 'View' : '',
        sequence: column.name === 'isReadyToInvoice' ? -1 : column.sequence,
        isClickable: this.isColumnClickable(column.name),
        filterType: filterMapping ? filterMapping.type : '',
        filterSequence: filterMapping ? filterMapping.sequence : 0,
        filterPlaceHolder: filterMapping ? filterMapping.placeholder : '',
        filterGroupName: filterMapping ? filterMapping.groupName : '',
        filterGroupId: filterMapping ? filterMapping.groupId : 0,
        disableColSorting: this.disableSorting(column.name),
        filterDisabled: this.getFilterDisabledStateByColumn(
          column.name,
          value,
          tabIndex
        ),
        filterOptions: this.getFilterOptionsByColumn(
          column.name,
          filterMapping
        ),
        filterOptionsAsync: this.getFilterOptionsByColumnAsync(column.name),
        columnHeaderHidden: column.name === 'isReadyToInvoice',
      };
    });
  }

  /**
   * This is the most important method where based of the filter options and customer recovery request object, we call various APIs to get grid data.
   */
  async fetchDataFromServer() {
    const tabIndex = await firstValueFrom(this._globalService.currentTabIndex$);
    this.prepareCustomerRecoveryRequest();
    // We are fetching total record count and customer recovery records with the specified filters.
    const result = await lastValueFrom(
      forkJoin({
        newTotalCount: this._customerRecoveryClaimService
          .customerRecoveryClaimsClaimscountPost(
            this.customerRecoveryRequest,
            this.apiVersion
          )
          .pipe(map((response) => response as number)),
        customerRecoveries:
          this._customerRecoveryClaimService.customerRecoveryClaimsListPost(
            this.customerRecoveryRequest,
            this.apiVersion
          ),
      })
    );

    this.totalRecordsSubject$$.next(result.newTotalCount);

    //Resetting grid if data is not available
    if (result.customerRecoveries.customerRecoveryDetails.length === 0) {
      this.gridData = null;
      return;
    }

    // We are finding unique group ids from the customer recoveries.
    const groupIds: string[] = [
      ...new Set(
        result.customerRecoveries.customerRecoveryDetails.map(
          (x: any) => x.groupNumber
        )
      ),
    ] as string[];
    // We are getting customer recovery records for the unique groups found earlier.
    const customerRecoveryRecordsByGroups = await lastValueFrom(
      this._customerRecoveryClaimService.customerRecoveryClaimsListByGroupIdPost(
        groupIds,
        this.apiVersion
      )
    );
    // We are excluding records which are already a part of current customer recovery page.
    const uniqueRecoveryGroupRecords =
      customerRecoveryRecordsByGroups.customerRecoveryDetails.filter(
        (group: any) =>
          !result.customerRecoveries.customerRecoveryDetails.some(
            (x: any) => x.id === group.id
          )
      );
    // We are combining all the customer recovery records together so that we can show them in the grid in groups.
    this.gridData = this.reduceCustomerRecoveriesIntoGroups(
      [
        ...result.customerRecoveries.customerRecoveryDetails,
        ...uniqueRecoveryGroupRecords,
      ],
      tabIndex
    );

    this.changeGridSchemaAfterDataLoaded(
      customerRecoveryRecordsByGroups.customerRecoveryDetails,
      tabIndex
    );
  }

  /**
   * This method hides/shows the grid schema columns based on the data.
   * @param customerRecoveryDetails customer recovery details
   * @returns
   */
  changeGridSchemaAfterDataLoaded(
    customerRecoveryDetails: Array<CustomerRecovery>,
    tabIndex: number
  ) {
    switch (tabIndex) {
      case 0: // All Cases
        this.changeGridSchemaForAllCasesTab();
        break;
      case 1: // My Cases
        this.changeGridSchemaForMyCasesTab();
        break;
      case 2: // Completed Cases
        this.changeGridSchemaForCompletedCasesTab();
        break;
    }

    const isReadyToInvoiceColumn = this.gridSchema?.find(
      (col) => col.column === 'isReadyToInvoice'
    );
    if (!isReadyToInvoiceColumn) return;
    const casesReadyForInvoice = customerRecoveryDetails.find(
      (recoveryCase) => recoveryCase.isReadyToInvoice
    );
    isReadyToInvoiceColumn.hidden = !casesReadyForInvoice;

    this.gridSchema = this.gridSchema;
  }

  /**
   * This method prepares the grid schema for All Cases tab.
   */
  private changeGridSchemaForAllCasesTab() {
    this.changeColumnVisibilityByTab(0);

    //Setting status column properties based on selected tab
    const statusIdCol = this.gridSchema?.find(
      (col) => col.column === 'claimStatusId'
    );
    if (statusIdCol) {
      statusIdCol.isClickable = true;
      statusIdCol.filterOptions = statusTypeMaster.filter((i) => !i.hide);
    }

    const assignedToNameCol = this.gridSchema?.find(
      (col) => col.column === 'assignedToName'
    );
    if (assignedToNameCol) assignedToNameCol.filterDisabled = false;
  }

  /**
   * This method prepares the grid schema for My Cases tab.
   */
  private changeGridSchemaForMyCasesTab() {
    this.changeColumnVisibilityByTab(1);

    //Setting status column properties based on selected tab
    const statusIdCol = this.gridSchema?.find(
      (col) => col.column === 'claimStatusId'
    );
    if (statusIdCol) {
      statusIdCol.isClickable = true;
      statusIdCol.filterOptions = statusTypeMaster.filter(
        (statusType) =>
          !statusType.hide &&
          this.customerRecoveryRequest.statusIds?.includes(statusType.value)
      );
    }

    const assignedToNameCol = this.gridSchema?.find(
      (col) => col.column === 'assignedToName'
    );
    if (assignedToNameCol) assignedToNameCol.filterDisabled = true;

    const imageCoverageCol = this.gridSchema?.find(
      (col) => col.column === 'imageCoverage'
    );

    if (imageCoverageCol) imageCoverageCol.filterDisabled = false;
  }

  /**
   * This method prepares the grid schema for Completed Cases tab.
   */
  private changeGridSchemaForCompletedCasesTab() {
    this.changeColumnVisibilityByTab(2);

    //Setting status column properties based on selected tab
    const statusIdCol = this.gridSchema?.find(
      (col) => col.column === 'claimStatusId'
    );
    if (statusIdCol) {
      statusIdCol.isClickable = false;
      statusIdCol.filterOptions = statusTypeMaster.filter(
        (statusType) =>
          !statusType.hide &&
          this.customerRecoveryRequest.statusIds?.includes(statusType.value)
      );
    }

    const assignedToNameCol = this.gridSchema?.find(
      (col) => col.column === 'assignedToName'
    );
    if (assignedToNameCol) assignedToNameCol.filterDisabled = false;

    const imageCoverageCol = this.gridSchema?.find(
      (col) => col.column === 'imageCoverage'
    );

    if (imageCoverageCol) imageCoverageCol.filterDisabled = true;
  }

  /**
   * This method hides/shows the grid schema columns based on the data and selected tab.
   */
  private changeColumnVisibilityByTab(tabIndex: number) {
    // get hidden columns from grid configuration mapping
    const hiddenColumns: string[] =
      this.gridConfigMapping.hideColumns[
        tabIndex as keyof typeof this.gridConfigMapping.hideColumns
      ];
    if (hiddenColumns) {
      hiddenColumns.forEach((colName) => {
        const col = this.gridSchema?.find((col) => col.column === colName);
        if (col) col.hidden = true;
      });
    }

    // make columns visible which were visible in user preferences and which were hidden for other tabs.
    const visibleColumnsForCustomerRecoveryGrid = this.screen?.columns
      ?.filter((column) => !column.isHidden)
      .map((column) => column.name);
    const columnsToMakeVisible = this.gridSchema?.filter(
      (col) =>
        !hiddenColumns?.includes(col.column) &&
        visibleColumnsForCustomerRecoveryGrid?.includes(col.column)
    );
    columnsToMakeVisible?.forEach((col) => {
      col.hidden = false;
    });
  }

  /**
   * This method calls the API to update the new grid schema (mostly column re-order) state as user preferences.
   * @param schema Grid schema
   * @returns promise
   */
  async updateGridSchemaToServer(schema: GridColumnSchema[]) {
    if (!this.userPreferences) return;
    this.screen?.columns?.forEach((column) => {
      const schemaColumn = schema.find(
        (columnSchema) => columnSchema.column === column.name
      );
      column.isHidden = schemaColumn?.hidden;
      column.sequence = schemaColumn?.sequence;
      column.isSticky = schemaColumn?.isSticky;
    });
    this.userPreferences.userId = this.userId;
    await this._userPreferencesService.updateUserPreferences(
      this.userPreferences
    );
  }

  /**
   * This method saves the user preferences filter data to server.
   * @param defaultFilterApplied is default filter reset
   * @returns
   */
  async updateGridFiltersToServer(defaultFilterApplied?: boolean) {
    if (!this.userPreferences) return;

    if (!this.screen || !this.gridFilter.filters) return;
    this.userPreferences.userId = this.userId;

    this.gridFilter.saveFilterConfiguration = false;

    // If user wants default filters to be applied, we will save default filters as user-preferences and return.
    if (defaultFilterApplied) {
      this.assignSystemDefaultFilters();
    }

    // We are always storing default cluster preference for the user (but only when filter is not reset).
    await this.updatePodClusterFilterOptionsAsPerClusterSelection(
      !!defaultFilterApplied
    );

    this.screen.searchCriteria = [];
    // If user has opted for saving filters and it is not default, then save the user's own filters.
    Object.entries(this.gridFilter.filters).forEach(([key, value]) => {
      if (
        !['pageNumber', 'pageSize', 'sortOrder', 'sortColumn'].includes(key) &&
        value &&
        (!Array.isArray(value) || (Array.isArray(value) && value.length > 0))
      ) {
        this.screen?.searchCriteria?.push({
          key: key,
          value: JSON.stringify(value),
        });
      }
    });
    await this._userPreferencesService.updateUserPreferences(
      this.userPreferences
    );
    this.gridFilter.defaultFilterApplied = false;
  }

  /**
   * If filter added for `podCluster` column then disabled that column filter immediately from grid filter.
   */
  async updatePodClusterFilterOptionsAsPerClusterSelection(
    defaultFilterApplied: boolean
  ) {
    // We are always storing default cluster preference for the user (But only if user has not reset filter).
    if (
      !defaultFilterApplied &&
      this.podCusterFilter?.key &&
      this.podCusterFilter.value
    ) {
      this.gridFilter.filters = {
        ...this.gridFilter.filters,
        podCluster: JSON.parse(this.podCusterFilter.value),
      };
    }

    const podClusterColumn = this.gridSchema?.find(
      (col) => col.column === 'podCluster'
    );

    if (
      !this.gridFilter.filters ||
      !podClusterColumn ||
      !podClusterColumn.filterOptionsAsync
    )
      return;

    let podClusterOptions = await lastValueFrom(
      this._globalService.countryClusters$.pipe(
        map((response: ClusterDto[] | undefined) => {
          if (!response) return [];
          return response.map((cluster) => ({
            value: cluster.clusterCode,
            label: cluster.clusterName,
            name: cluster.clusterName,
          }));
        })
      )
    );

    // Default filter is not applied so only show the filter options for the selected clusters in cluster filter dropdown.
    if (
      !defaultFilterApplied &&
      Object.entries(this.gridFilter.filters).find(
        ([key, _]) => key === 'podCluster'
      )
    ) {
      podClusterOptions = podClusterOptions.filter((cluster) =>
        (this.gridFilter.filters!['podCluster'] as string[]).includes(
          cluster.value as string
        )
      );
    }
    podClusterColumn.filterOptionsAsync = of(podClusterOptions);
    this.gridSchema = this.gridSchema;
  }

  /**
   * This method updates either new claim status or comment added to the claim via API.
   * @param claimStatusRequest new claim status
   * @param caseNumber case
   * @returns promise
   */
  async updateStatusAndCommentDataToServer(
    claimStatusRequest: ClaimStatusRequest,
    caseNumber: string
  ): Promise<ClaimStatusResponse> {
    return await lastValueFrom(
      this._customerRecoveryClaimService.customerRecoveryClaimsClaimStatusCaseNumberPut(
        claimStatusRequest,
        caseNumber,
        this.apiVersion
      )
    );
  }

  async checkImageAvailabilityFromEIR(cellData: any): Promise<number> {
    this.eirImageFetchError = false;

    return await lastValueFrom(
      this._customerRecoveryClaimService
        .customerRecoveryClaimsCaseNumberInspectionsGet(
          cellData.cellValue.value,
          this.apiVersion
        )
        .pipe(
          map((response) => {
            if (
              response &&
              response.inspections &&
              response.inspections.length > 0
            ) {
              const eirSource = response.inspections.filter(
                (o) => o.sourceId == Image_Source.EIR_MOBILE_APP
              )?.length;
              const vendorEmail = response.inspections.filter(
                (o) => o.sourceId == Image_Source.VENDOR_EMAIL
              )?.length;

              if (eirSource > 0 && vendorEmail > 0) return IA_BOTH_AVAILABLE;
              else if (eirSource > 0) return IA_EIR_AVAILABLE;
              else if (vendorEmail > 0) return IA_VENDOR_AVAILABLE;
              else return IA_NOT_AVAILABLE;
            } else return IA_NOT_AVAILABLE;
          }),
          catchError((error) => {
            this.eirImageFetchError = true;
            return of(IA_NOT_AVAILABLE);
          })
        )
    );
  }

  /**
   * Updates the recovery case row in the grid data to indicate that
   * an Eir image is available.
   * @param cellData - The data of the cell that contains the
   * recovery case number to be updated.
   */
  updateRecoveryWithAvailabilityStatus(
    cellData: any,
    imageCoverage: number,
    imageCoverageText: string,
    icon: string
  ) {
    const row = this.gridData![cellData.rowNumber].row;
    const customerRecovery =
      row['recoveryCaseNumber'].value === cellData.cellValue.value
        ? row
        : this.gridData![cellData.rowNumber].childRows?.find(
            (item: any) =>
              item['recoveryCaseNumber'].value === cellData.cellValue.value
          );

    if (!customerRecovery) return;

    customerRecovery['imageCoverage'].value = imageCoverage;
    customerRecovery['imageCoverageText'].value = imageCoverageText;
    customerRecovery['recoveryCaseNumber'].icon = icon;
    customerRecovery['recoveryCaseNumber'].tooltipText = imageCoverageText;
    this.gridData = [...this.gridData!];
  }

  /**
   * Saves the image availability status for a given recovery case number.
   * @param caseNumber - The recovery case number.
   * @returns A promise that resolves after the status is saved.
   */
  async saveImageAvailabilityStatus(
    caseNumber: string,
    imageAvailabilityStatus: number
  ) {
    const inspectionInfo: InspectionDetailsDTO = {
      caseNumber: caseNumber,
      damageProofStatus: imageAvailabilityStatus, //eir available
      updatedBy: sessionStorage.getItem('userId')?.toString(),
    };
    return await lastValueFrom(
      this._customerRecoveryClaimService.customerRecoveryClaimsCaseNumberImageAvailabilityPut(
        inspectionInfo,
        caseNumber,
        this.apiVersion
      )
    );
  }

  /**
   * This method calls the API for assigning claims to current users.
   * @param userClaimsAssignmentRequest request
   * @returns promise
   */
  async assignCasesToUserOnServer(
    userClaimsAssignmentRequest: UserClaimsAssignmentRequest
  ): Promise<any> {
    return await lastValueFrom(
      this._customerRecoveryClaimService.customerRecoveryClaimsCaseAssignmentPost(
        userClaimsAssignmentRequest,
        this.apiVersion
      )
    );
  }

  /**
   * This method calls the API for un-assigning claims.
   * @param userClaimsUnassignmentRequest request
   * @returns promise
   */
  async unassignCasesOnServer(
    userClaimsUnassignmentRequest: UserClaimsUnassignmentRequest
  ): Promise<any> {
    return await lastValueFrom(
      this._customerRecoveryClaimService.customerRecoveryClaimsCaseUnassignmentPost(
        userClaimsUnassignmentRequest,
        this.apiVersion
      )
    );
  }

  /**
   * This method maps the filters applied with the API request object for getting the claim records accordingly.
   */
  updateCustomerRecoveryRequestAsPerFilterObject() {
    if (this.gridFilter?.filters) {
      Object.keys(this.gridFilter?.filters)?.forEach((key, _) => {
        const keyValue = this.gridFilter?.filters
          ? this.gridFilter?.filters[key]
          : '';
        if (key === 'importReturn') {
          this.customerRecoveryRequest.importReturn = (
            keyValue as string[]
          ).includes('yes');
          return;
        }
        if (key === 'woCreatedOn') {
          this.customerRecoveryRequest = {
            ...this.customerRecoveryRequest,
            dateFrom: (keyValue as MCDate).from,
            dateTo: (keyValue as MCDate).to,
          };
          return;
        }
        const filterColumDetails = this.gridConfigMapping.filterConfig?.filter(
          (x: any) => x.name == key
        )[0];
        this.customerRecoveryRequest = {
          ...this.customerRecoveryRequest,
          [filterColumDetails && filterColumDetails.filtername
            ? filterColumDetails.filtername
            : key]: keyValue ?? null,
        };
      });
    }
    // Doing this change as API can not sort on `isReadyToInvoice`. This property is not stored in DB.
    if (this.customerRecoveryRequest.sortColumn === 'isReadyToInvoice') {
      this.customerRecoveryRequest.sortColumn = 'dateToInvoice';
    }
  }

  /**
   * This method adds the system default filters when no user preference is saved or when preferences are removed.
   */
  assignSystemDefaultFilters() {
    this.gridFilter.filters = {};
    this.gridFilter.defaultFilterApplied = true;
    defaultFilters.forEach((defaultFilter) => {
      if (this.gridFilter.filters && defaultFilter.key && defaultFilter.value)
        this.gridFilter.filters[defaultFilter.key] = JSON.parse(
          defaultFilter.value
        );
    });
  }

  /**
   * This method updates the customer recovery request with some default values as per the current tab.
   * @param tabIndex current tab selected
   * @returns
   */
  updateCustomerRecoveryRequestAsPerCustomFilters(tabIndex: number) {
    if (this.gridFilter.newFiltersApplied) {
      this.resetSortingAndPagination();
      this.gridFilter.newFiltersApplied = false;
    }
    this.customerRecoveryRequest = this.initCustomerRecoveryObject();
    this.updateCustomerRecoveryRequestAsPerFilterObject();
    // `My Cases` tab will always use current user name filter and specific selected or active claim statuses for getting the result from API.
    if (tabIndex === 1 && this.gridFilter.filters) {
      this.customerRecoveryRequest.statusIds =
        this.customerRecoveryRequest.statusIds ??
        statusTypeMaster
          .filter((status) => !status.isCompleted)
          .map((status) => status.value);
      this.customerRecoveryRequest.assignedUID = this.userId;
      return;
    }
    // `Completed` tab will always use current user name filter and specific selected statuses or passive claim statuses for getting the result from API.
    if (tabIndex === 2 && this.gridFilter.filters) {
      this.customerRecoveryRequest.statusIds =
        this.customerRecoveryRequest.statusIds ??
        statusTypeMaster
          .filter((status) => status.isCompleted)
          .map((status) => status.value);
      this.customerRecoveryRequest.assignedUID = this.userId;
    }
    // Only add all the pod clusters when pod cluster filter is removed entirely.
    if (!this.customerRecoveryRequest.podClusters && !!this.podCusterFilter) {
      this.addPodClusterInGridFilterAndRecoveryRequest();
    }
  }

  /**
   * We are using user preferences to load the initial state of the grid view.
   */
  loadInitialFiltersForAllCasesGridView() {
    const userPreferences = this._userPreferencesService.userPreferences;
    const screen = userPreferences?.preference.screens?.find(
      (screen) => (screen.id = 1)
    );
    this.gridFilter.filters = {};
    this.gridFilter.enableDefaultFilterConfiguration = true;
    this.gridFilter.newFiltersApplied = false;
    this.gridFilter.defaultFilterApplied = false;

    this.resetSortingAndPagination();
    this.customerRecoveryRequest = this.initCustomerRecoveryObject();
    // Use system default filters if no user-preferences are saved.
    if (!screen || !screen.searchCriteria || !screen.searchCriteria.length) {
      this.assignSystemDefaultFilters();
    }
    screen?.searchCriteria?.forEach((keyVal: KeyValue) => {
      if (!keyVal.value || !keyVal.key || !this.gridFilter.filters) return;
      this.gridFilter.filters[keyVal.key ?? ''] = JSON.parse(keyVal.value);
    });
    this.updateCustomerRecoveryRequestAsPerFilterObject();
  }

  /**
   * We are resetting grid view based on the current tab.
   */
  resetFiltersAsPerCurrentTab(tabIndex: number) {
    this.gridFilter.filters = {};
    this.gridFilter.newFiltersApplied = false;
    this.gridFilter.enableDefaultFilterConfiguration = false;
    this.resetSortingAndPagination();
    this.customerRecoveryRequest = this.initCustomerRecoveryObject();
    // `All Cases` tab will show filter save option and show all statuses of claim.
    if (tabIndex === 0) {
      this.gridFilter.enableDefaultFilterConfiguration = true;
      this.customerRecoveryRequest.statusIds = statusTypeMaster.map(
        (status) => status.value
      );
    }
    // `My Cases` tab will show current user's cases and show all non-complete statuses of claim.
    if (tabIndex === 1) {
      this.customerRecoveryRequest.assignedUID = this.userId;
      this.customerRecoveryRequest.statusIds = statusTypeMaster
        .filter((status) => !status.isCompleted)
        .map((status) => status.value);
    }
    // `Completed` tab will show current user's cases and show all only complete (canceled, completed, autoClosed etc) statuses of claim.
    if (tabIndex === 2) {
      this.customerRecoveryRequest.assignedUID = this.userId;
      this.customerRecoveryRequest.statusIds = statusTypeMaster
        .filter((status) => status.isCompleted)
        .map((status) => status.value);
    }
    this.addPodClusterInGridFilterAndRecoveryRequest();
  }

  addPodClusterInGridFilterAndRecoveryRequest() {
    // Assign cluster filter by default for the current user.
    if (this.podCusterFilter && this.podCusterFilter.value) {
      this.customerRecoveryRequest.podClusters = JSON.parse(
        this.podCusterFilter.value
      );
      this.gridFilter.filters = {
        ...this.gridFilter.filters,
        podCluster: this.customerRecoveryRequest.podClusters,
      };
    }
  }

  /**
   * Resets pagination and sorting to default values
   */
  resetSortingAndPagination() {
    //Resetting grid filter (pagination and sorting) to default values
    this.gridFilter.pageNumber = 1;
    this.gridFilter.pageSize = 25;
    this.gridFilter.sortOrder = SORT_ORDER.ASC;
    this.gridFilter.sortColumn = '';
  }

  initCustomerRecoveryObject(): CustomerRecoveryRequest {
    return {
      pageNumber: this.gridFilter.pageNumber,
      pageSize: this.gridFilter.pageSize,
      sortOrder: this.gridFilter.sortOrder,
      sortColumn: this.gridFilter.sortColumn,
    };
  }

  async setVersionDetailForTemplateAndWorkflow() {
    const rawProcessDetail = await lastValueFrom(
      this._commonService.commonVersionDetailsGet(this.apiVersion)
    );

    if (
      Array.isArray(rawProcessDetail) &&
      (rawProcessDetail as VersionDetails[]).length > 0
    ) {
      sessionStorage.setItem(
        'dcrp-version-details',
        JSON.stringify(rawProcessDetail)
      );
      const template_definitionsIds = this.getLastTemplatesDefinitionIDs(
        rawProcessDetail as VersionDetails[]
      );
      if (template_definitionsIds) {
        await this.getTemplateWithVersions(template_definitionsIds);
      }
    }
  }

  async getTemplateWithVersions(definitionIds: string) {
    const req = {
      workflowType: 'Reefer_Cargo_Workflow', // not yet created template in camunda -- update the type once done
      stage: 'Stage',
      TemplateDefinitionIds: definitionIds,
    };
    const rawTemplateVersions = await lastValueFrom(
      this._workflowService.workflowGetAllTemplates(req)
    );
    if (
      rawTemplateVersions.statusCode == 200 &&
      rawTemplateVersions?.latestTemplates
    )
      sessionStorage.setItem(
        'dcrp-template-version',
        JSON.stringify(rawTemplateVersions.latestTemplates)
      );
  }

  getLastTemplatesDefinitionIDs(list: VersionDetails[]) {
    //get version details for only type template
    const template_versions = list.filter(
      (i) => i.processType == TEMPLATE_TYPE
    );

    if (template_versions && template_versions?.length > 0) {
      //sort thee templates  with version
      const templatesList = template_versions?.sort((a, b) =>
        (a.version ?? 0) > (b.version ?? 0) ? 1 : -1
      );
      let i = 1;
      let definitionIdString = '';
      const length = templatesList.length;
      //appending  latest definitions
      while (i <= 2 && templatesList[length - i]) {
        definitionIdString += definitionIdString ? ',' : '';
        definitionIdString +=
          templatesList[length - i].version +
          '=' +
          templatesList[length - i].definitionId;
        i++;
      }
      return definitionIdString; // return latest two template definition with comma separated
    }
    return '';
  }

  /**
   * We are doing modification of API request object so that it is exactly the way API wants it.
   */
  private prepareCustomerRecoveryRequest() {
    // This conversion is required for API to work properly. Hence formatting the date.
    this.customerRecoveryRequest.dateFrom = this.customerRecoveryRequest
      .dateFrom
      ? this.convert_dd_mm_yyyy_into_dateFormat(
          this.customerRecoveryRequest.dateFrom,
          'dateFrom'
        )
      : undefined;
    this.customerRecoveryRequest.dateTo = this.customerRecoveryRequest.dateTo
      ? this.convert_dd_mm_yyyy_into_dateFormat(
          this.customerRecoveryRequest.dateTo,
          'dateTo'
        )
      : undefined;

    // Lets remove null and empty fields from the request object.
    const customerRecoveryKeyVal = this.customerRecoveryRequest as {
      [key: string]: unknown;
    };

    Object.entries(customerRecoveryKeyVal).forEach(([key, value]) => {
      if (!value && key !== 'importReturn')
        customerRecoveryKeyVal[key] = undefined;
    });
  }

  /**
   * Converting date to a specified format.
   * @param date date
   * @returns
   */
  private convert_dd_mm_yyyy_into_dateFormat(date: string, key: string) {
    const arrDate = date?.split('-');
    if (!date) return undefined;
    else
      return `${arrDate[2].trim()}-${arrDate[1].trim()}-${arrDate[0].trim()}T${key === 'dateFrom' ? '00:00:00.000' : '23:59:59.999'}Z`;
  }

  /**
   * This method groups all the records together as a master and child row, to show linked cases.
   * Use this method to custom update any data if you want. For e.g. We can changing ClaimStatus enum as text in this method.
   * @param listOfCustomerRecoveries All un-grouped customer recovery records.
   * @returns
   */
  private reduceCustomerRecoveriesIntoGroups(
    listOfCustomerRecoveries: CustomerRecovery[],
    tabIndex: number
  ): GridRowData[] {
    return listOfCustomerRecoveries.reduce(
      (previousRows: GridRowData[], currentRow: CustomerRecovery) => {
        // Do any modifications you want with data.
        const currentGridRow = this.generateGridRowFromCustomerRecovery(
          currentRow,
          tabIndex
        );

        const matchingGroupIndex = previousRows.findIndex(
          (x) => x.row['groupNumber'].value === currentRow['groupNumber']
        );

        //If first record of the grid or If record does not belong to the any of the previous groups, create new row.
        if (matchingGroupIndex === -1) {
          previousRows.push({
            row: currentGridRow,
            isMasterRow: false,
            childRows: [],
            showChildRowData: false,
          });
          return previousRows;
        }

        //If record belongs to the previous group, append it.
        if (matchingGroupIndex > -1) {
          previousRows[matchingGroupIndex].childRows?.push(currentGridRow);
          previousRows[matchingGroupIndex].isMasterRow = true;
          return previousRows;
        }
        return previousRows;
      },
      [] as GridRowData[]
    );
  }

  /**
   * This method does a transformation on the api response object and creates grid row object from it.
   * @param customerRecovery original object received from web-api response
   * @returns grid row object created
   */
  private generateGridRowFromCustomerRecovery(
    customerRecovery: CustomerRecovery,
    tabIndex: number
  ): { [key: string]: GridCellData } {
    const gridRowObject: { [key: string]: GridCellData } = {};
    Object.keys(customerRecovery).map((key) => {
      gridRowObject[key] = {
        value: this.getValueForCellByColumn(key, customerRecovery),
        icon: this.getIconForCellByColumn(key, customerRecovery, tabIndex),
        tooltipText: this.getTooltipForCellByColumn(
          key,
          customerRecovery,
          tabIndex
        ),
        disabled: this.getDisabledStatusForCellByColumn(key, customerRecovery),
        onIconClick: this.onCellIconClicked.bind(this),
      } as GridCellData;
    });
    return gridRowObject;
  }

  /**
   * This method handles the callback methods of the cell icon click event.
   * @param cellData - The data of the clicked cell.
   */
  async onCellIconClicked(cellData: any) {
    if (
      cellData &&
      cellData.cellValue &&
      cellData.cellValue.icon === 'arrow-clockwise'
    ) {
      this.onEirAvailabilityIconClicked(cellData);
    }
  }

  /**
   * Handles the icon click event to check image availability and update the recovery status.
   * @param cellData - The data of the clicked cell.
   */
  async onEirAvailabilityIconClicked(cellData: any) {
    this.eirAvailabilityIconClicked = true;
    if (
      cellData &&
      cellData.cellValue &&
      cellData.cellValue.icon === 'arrow-clockwise'
    ) {
      this.logger.actionLogger({
        message:
          `EIR on-demand requested by user for the recovery case Number - ` +
          cellData.cellValue.value,
        type: 'EIRImageAvailabilityRequest',
      } as loggerType);

      this.updateRecoveryWithAvailabilityStatus(
        cellData,
        IA_IN_PROGRESS_VALUE,
        IA_IN_PROGRESS_TEXT,
        ICON_CLOCK_STOPWATCH
      );

      const imageSource = await this.checkImageAvailabilityFromEIR(cellData);

      if (imageSource && imageSource != 4) {
        this.updateRecoveryWithAvailabilityStatus(
          cellData,
          imageSource,
          IA_IMAGE_AVAILABLE_TEXT,
          ICON_PAPER_CLIP
        );
        this.saveImageAvailabilityStatus(
          cellData?.cellValue?.value,
          imageSource
        );
      } else if (!this.eirImageFetchError) {
        this.updateRecoveryWithAvailabilityStatus(
          cellData,
          imageSource,
          'No images available. Check again in approximately 1 hour.',
          ICON_ARROW_CLOCKWISE_TIMES
        );
        this.saveImageAvailabilityStatus(
          cellData?.cellValue?.value,
          imageSource
        );
      }
    }
  }

  /**
   * This method gets the value which we have to show in grid row cell.
   * @param columnName current column name ( property name) of the API Object
   * @param customerRecovery original object received from web-api response
   * @returns
   */
  private getValueForCellByColumn(
    columnName: string,
    customerRecovery: CustomerRecovery
  ) {
    const customerRecoveryKeyValue = customerRecovery as {
      [key: string]: unknown;
    };
    if (columnName === 'claimStatusId')
      return ClaimStatus[customerRecoveryKeyValue[columnName] as number];
    else if (columnName === 'gateInToWorkOrderDays')
      return `${customerRecoveryKeyValue[columnName]} days`;
    else if (
      columnName === 'customerGateInLapseDays' &&
      customerRecoveryKeyValue[columnName]
    )
      return `${customerRecoveryKeyValue[columnName]} days`;
    else if (columnName === 'workOrderStatusCode')
      return workOrderStatusType.find(
        (x) =>
          x.value ===
          parseInt(customerRecoveryKeyValue[columnName] as string, 10)
      )?.label;
    else if (columnName === 'isReadyToInvoice') return '';
    else if (columnName === 'invoiceStatus')
      return getStatusTextFromStatusCode(
        customerRecoveryKeyValue[columnName] as InvoiceStatusEnum
      );
    return customerRecoveryKeyValue[columnName];
  }

  /**
   * This method gets the icon which we have to show in grid row cell.
   * @param columnName current column name ( property name) of the API Object
   * @param customerRecovery original object received from web-api response
   * @returns
   */
  private getIconForCellByColumn(
    columnName: string,
    customerRecovery: CustomerRecovery,
    tabIndex: number
  ) {
    if (columnName === 'woCostUSD' && customerRecovery.isDeltaUpdated)
      return 'info-circle';
    else if (columnName === 'recoveryCaseNumber') {
      if (tabIndex === 1 || tabIndex === 0) {
        if (
          customerRecovery.imageCoverage == 1 ||
          customerRecovery.imageCoverage == 2 ||
          customerRecovery.imageCoverage == 3
        ) {
          return ICON_PAPER_CLIP;
        } else if (tabIndex === 1) {
          return this.isTimeDifferenceGreaterThanThreshold(
            customerRecovery.eirImageLastCheckedOn,
            1 //1 hour
          )
            ? ICON_ARROW_CLOCKWISE
            : ICON_ARROW_CLOCKWISE_TIMES;
        }
      }
    } else if (
      (['claimStatusId'].includes(columnName) &&
        customerRecovery.claimStatusId == 1) ||
      (['claimStatusId'].includes(columnName) &&
        customerRecovery.claimStatusId != 1 && //if not new
        !customerRecovery.workflowVersion)
    ) {
      return 'pencil';
    } else if (
      columnName === 'isReadyToInvoice' &&
      customerRecovery.isReadyToInvoice
    ) {
      return 'receipt';
    }
    return undefined;
  }

  /**
   * This method gets the tooltip [text] which we have to show in grid row cell.
   * @param columnName current column name ( property name) of the API Object
   * @param customerRecovery original object received from web-api response
   * @returns
   */
  private getTooltipForCellByColumn(
    columnName: string,
    customerRecovery: CustomerRecovery,
    tabIndex: number
  ) {
    if (columnName === 'woCostUSD' && customerRecovery.isDeltaUpdated)
      return 'The work order cost has been revised';
    else if (columnName === 'recoveryCaseNumber') {
      if (tabIndex === 1) {
        if (
          customerRecovery.imageCoverage == 1 ||
          customerRecovery.imageCoverage == 2 ||
          customerRecovery.imageCoverage == 3
        ) {
          return IA_IMAGE_AVAILABLE_TEXT;
        } else {
          return this.isTimeDifferenceGreaterThanThreshold(
            customerRecovery.eirImageLastCheckedOn,
            1 //1 hour
          )
            ? 'Check for images'
            : 'No images available. Check again in approximately ' +
                this.getRemainingMinutes(
                  customerRecovery.eirImageLastCheckedOn!,
                  1
                ) +
                ' minutes';
        }
      }
    } else if (
      (['claimStatusId'].includes(columnName) &&
        customerRecovery.claimStatusId == 1) ||
      (['claimStatusId'].includes(columnName) &&
        customerRecovery.claimStatusId != 1 && //if not new
        !customerRecovery.workflowVersion)
    ) {
      return 'Click to update the recovery status';
    } else if (
      columnName === 'isReadyToInvoice' &&
      customerRecovery.isReadyToInvoice
    ) {
      return 'Ready for invoicing';
    }
    return undefined;
  }

  /**
   * This method gets the tooltip [text] which we have to show in grid row cell.
   * @param columnName current column name ( property name) of the API Object
   * @param customerRecovery original object received from web-api response
   * @returns
   */
  private getDisabledStatusForCellByColumn(
    columnName: string,
    customerRecovery: CustomerRecovery
  ) {
    if (
      ['claimStatusId'].includes(columnName) &&
      customerRecovery.claimStatusId &&
      statusTypeMaster
        .filter((status) => status.isCompleted)
        .map((x) => x.value)
        .includes(customerRecovery.claimStatusId)
    ) {
      return true;
    } else if (
      ['recoveryCaseNumber'].includes(columnName) &&
      customerRecovery.comments?.toLowerCase() ==
        DUPLICATE_WORK_ORDER_MSG.toLowerCase()
    ) {
      return true;
    } else if (
      ['recoveryCaseNumber'].includes(columnName) &&
      customerRecovery.claimStatusId != 1 && //if not new
      !customerRecovery.workflowVersion
    ) {
      return true;
    } else if (
      ['claimStatusId', 'comments'].includes(columnName) &&
      customerRecovery.claimStatusId != 1 &&
      customerRecovery.workflowVersion
    ) {
      return true;
    }

    return undefined;
  }

  /**
   * Get filter options by column name.
   * @param columnName column name
   * @param filterMapping filerMapping
   * @returns
   */
  private getFilterOptionsByColumn(columnName: string, filterMapping: any) {
    if (
      columnName == 'woCreatedOn' ||
      columnName == 'importReturn' ||
      columnName == 'customerGateInLapseDays' ||
      columnName == 'imageCoverage'
    )
      return filterMapping.options;
    else if (columnName == 'claimStatusId')
      return statusTypeMaster.filter((i) => !i.hide);
    else if (columnName == 'aboveCoverageBand') return aboveCoverType;
    else if (columnName == 'workOrderStatusCode')
      return workOrderStatusType.filter((i) => i.value != 0);
    else if (columnName == 'woMode') return workOrderMode;
  }

  /**
   *
   * @param columnName name of the current column
   * @returns Observable
   */
  private getFilterOptionsByColumnAsync(
    columnName: string
  ): Observable<DropDownOption[]> | undefined {
    if (columnName === 'assignedToName') {
      return this._customerRecoveryClaimService
        .customerRecoveryClaimsAssignedUsersGet(this.apiVersion)
        .pipe(
          map((response: AssignedUsersResponse | undefined) => {
            // Return an empty array if there's no response or no assigned users
            if (!response || !response.assignedUsers) return [];
            // Map the response to dropdown options
            const users = response.assignedUsers.map((user) => ({
              value: user.userId,
              label: `${user.userName} (${user.userId})`,
              name: user.userId,
            }));

            // Sort the users by label
            users.sort((a, b) => a.label.localeCompare(b.label));

            // Add the default entry for 'System'
            users.unshift({ name: 'System', value: 'SYS', label: 'System' });

            return users;
          }),
          shareReplay(1)
        );
    } else if (
      columnName == 'shopCountryCode' ||
      columnName == 'podCountryName'
    ) {
      return this._globalService.countries$.pipe(
        map((response: CountryDto[] | undefined) => {
          if (!response || response.length < 1) return [];
          return response.map((country) => ({
            value: country.code,
            label: country.name,
            name: country.name,
          }));
        }),
        shareReplay(1)
      );
    } else if (columnName == 'podCluster') {
      return this._globalService.countryClusters$.pipe(
        map((response: ClusterDto[] | undefined) => {
          if (!response || response.length < 1) return [];
          let clusters = response.map((cluster) => ({
            value: cluster.clusterCode,
            label: cluster.clusterName,
            name: cluster.clusterName,
          }));
          if (this.podCusterFilter)
            clusters = clusters.filter((cluster) =>
              (
                JSON.parse(this.podCusterFilter?.value ?? '') as string[]
              ).includes(cluster.value || '')
            );
          return clusters;
        }),
        shareReplay(1)
      );
    } else if (columnName == 'podRegion') {
      return this._globalService.regions$.pipe(
        map((response: Region[] | undefined) => {
          if (!response || response.length < 1) return [];
          return response.map((region) => ({
            value: region.regionCode,
            label: region.regionName,
            name: region.regionName,
          }));
        }),
        shareReplay(1)
      );
    } else return undefined;
  }

  /**
   * This method enables or disables initial specific filter option for specific column based on some condition.
   * @param columnName name of the current column
   * @param screen userPreference details of the current user
   * @returns
   */
  private getFilterDisabledStateByColumn(
    columnName: string,
    screen: Screen,
    tabIndex: number
  ) {
    // Keeping this empty function alive as it may need in future.
    if (columnName == 'imageCoverage' && tabIndex == 2) {
      return true;
    }
    return false;
  }

  /**
   * We are configuring various columns with click type event so that they will be rendered by event type inside grid.
   * @param columnName columnName to configure
   * @returns column click event type
   */
  private selectClickAbleColumnType(
    columnName: string
  ): COLUMN_CLICK_TYPE | undefined {
    switch (columnName) {
      case 'recoveryCaseNumber':
        return environment.enableCaseWorkflow
          ? COLUMN_CLICK_TYPE.LINK_CLICK
          : undefined;
      case 'claimStatusId':
        return COLUMN_CLICK_TYPE.TEXT_CLICK;
      default:
        return COLUMN_CLICK_TYPE.LINK_CLICK;
    }
  }

  /**
   * We are checking if the column provided is clickable or not based on some custom logic.
   * @param columnName columnName to configure
   * @returns column click event type
   */
  private isColumnClickable(columnName: string): boolean {
    if (['comments', 'claimStatusId'].includes(columnName)) return true;
    else if (columnName === 'recoveryCaseNumber')
      return environment.enableCaseWorkflow;
    return false;
  }
  /**
   * We are checking if the column provided has sorting enable or disable.
   * @param columnName columnName to configure
   * @returns column click event type
   */
  private disableSorting(columnName: string): boolean {
    if (['comments', 'invoiceStatus', 'invoiceNumber'].includes(columnName))
      return true;
    return false;
  }

  /**
   * Checks if the time difference between the current time and the given time is greater than the specified threshold in hours.
   *
   * @param lastRecordedTime - The Date object representing the last recorded time.
   * @param thresholdHour - The threshold in hours to compare the time difference against.
   * @returns true if the time difference is greater than the threshold, false otherwise.
   */
  private isTimeDifferenceGreaterThanThreshold(
    lastRecordedTime: Date | undefined,
    thresholdHour: number
  ): boolean {
    if (!lastRecordedTime) return true;
    const currentTime = new Date();
    const lastRecordedTimeDate = new Date(lastRecordedTime);
    // Calculate the difference in milliseconds between the current time and the last recorded time
    const timeDifferenceInMs =
      currentTime.getTime() - lastRecordedTimeDate.getTime();

    // Convert the time difference from milliseconds to hours
    const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

    // Return true if the time difference in hours is greater than the threshold, false otherwise
    return timeDifferenceInHours > thresholdHour;
  }

  /**
   * Calculates the remaining time in minutes between the current time and the given time
   *
   * @param lastRecordedTime - The Date object representing the last recorded time.
   * @returns String with remaining time in hours, minutes, and seconds if the time difference is less than the threshold,
   *          otherwise null.
   */
  private getRemainingMinutes(
    lastRecordedTime: Date,
    thresholdHour: number
  ): string {
    const currentTime = new Date();
    const lastRecordedTimeDate = new Date(lastRecordedTime);

    // Calculate the difference in milliseconds between the current time and the last recorded time
    const timeDifferenceInMs =
      currentTime.getTime() - lastRecordedTimeDate.getTime();

    // Convert the time difference from milliseconds to hours
    const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

    // If the time difference is greater than the threshold, return null
    if (timeDifferenceInHours > thresholdHour) {
      return '00h-00m-00s';
    }

    // Calculate remaining time in hours, minutes, and seconds
    const remainingTimeInMs =
      thresholdHour * 60 * 60 * 1000 - timeDifferenceInMs;
    const remainingMinutes = Math.floor(
      (remainingTimeInMs % (1000 * 60 * 60)) / (1000 * 60)
    );

    return remainingMinutes + '';
  }
}
