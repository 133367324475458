import { Routes } from '@angular/router';
import { CustomerRecoveryComponent } from './components/customer-recovery/customer-recovery.component';
import { CustomerRecoveryWorkflowComponent } from './components/customer-recovery/customer-recovery-workflow/customer-recovery-workflow.component';
import { EmailTemplateEditComponent } from './Admin/EmailTemplate/email-template-edit.component';

export const routes: Routes = [
  { path: '', redirectTo: 'customer-recovery', pathMatch: 'full' },
  { path: 'customer-recovery', component: CustomerRecoveryComponent },
  {
    path: 'customer-recovery/workflow',
    component: CustomerRecoveryWorkflowComponent,
  },
  {
    path: 'admin/email-template/edit',
    pathMatch: 'full',
    component: EmailTemplateEditComponent,
  },
];
