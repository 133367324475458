/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ItemType } from '../../models/itemType';
import { MailDataDtoResponse } from '../../models/mailDataDtoResponse';

@Injectable()
export class BlobsService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * Retrieves the specified blob by name and item type.
   * This method retrieves the blob with the specified name and item type. It returns an Microsoft.AspNetCore.Mvc.IActionResult   indicating the success or failure of the operation. If the blob is found, the method returns an HTTP 200 OK status   code along with the response containing the saved mail data. If the blob is not found, it returns an HTTP 404 Not   Found status code. If there is an error during the retrieval process, it returns an HTTP 500 Internal Server Error   status code.
   * @param name The name of the blob.
   * @param type The type of the item.
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public blobsNameGet(
    name: string,
    type?: ItemType,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<MailDataDtoResponse>;
  public blobsNameGet(
    name: string,
    type?: ItemType,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<MailDataDtoResponse>>;
  public blobsNameGet(
    name: string,
    type?: ItemType,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<MailDataDtoResponse>>;
  public blobsNameGet(
    name: string,
    type?: ItemType,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (name === null || name === undefined) {
      throw new Error(
        'Required parameter name was null or undefined when calling blobsNameGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', <any>type);
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<MailDataDtoResponse>(
      'get',
      `${this.basePath}/blobs/${encodeURIComponent(String(name))}`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
