<div class="workflow-stepper" *ngIf="currentStageId$ | async">
  <div class="step-indicator">
    <mc-step-indicator *ngIf="workflowSteps">
      @for (step of workflowSteps; track step; let index = $index) {
        <mc-step-indicator-item
          fit="medium"
          (click)="showStage(index)"
          [label]="step.name"
          [state]="step.state"></mc-step-indicator-item>
      }
    </mc-step-indicator>
  </div>
</div>
