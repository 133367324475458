/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type LiabilityLetterStatus = 'RESENT' | 'ACCEPTED' | 'REJECTED' | 'NO_RESPONSE';

export const LiabilityLetterStatus = {
    RESENT: 'RESENT' as LiabilityLetterStatus,
    ACCEPTED: 'ACCEPTED' as LiabilityLetterStatus,
    REJECTED: 'REJECTED' as LiabilityLetterStatus,
    NORESPONSE: 'NO_RESPONSE' as LiabilityLetterStatus
};