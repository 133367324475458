<div class="workflow-form">
  <app-workflow-stepper
    [stages]="stages"
    (loadSelectedStage)="
      onStageMovedToNextOrBack($event)
    "></app-workflow-stepper>
  <div>
    <app-app-workflow-tab
      [stages]="stages"
      (stageMovedToNextOrBack)="
        onStageMovedToNextOrBack($event)
      "></app-app-workflow-tab>
  </div>
</div>
