import { Injectable } from '@angular/core';
import { Observable, combineLatest, map, shareReplay } from 'rxjs';
import { CustomerRecoveryCaseDto } from './common/models/customerRecoveryCaseDto';
import { CountryDto } from './common/models/countryDto';
import { CustomerInfoDto } from './common/models/customerInfoDto';
import { LiablePartyDto } from './common/models/liabilePartyDto';
import { gcssBookingInfo } from './common/models/gcssBookingInfo';
import { GlobalService } from './global-service';
import { CaseDamageDetailDto } from './common/models/caseDamageDetailDto';
import { SendMailRequest } from './common/models/sendMailRequest';
import { materialCode } from './components/customer-recovery/temporary-constant';

type KeyValue = {
  key: string;
  value: string;
};

@Injectable({
  providedIn: 'root',
})
export class EmailTemplatePlaceholderService {
  currencyPresentInTemplate: boolean = false;
  emailRequest: SendMailRequest | undefined;
  materialCodes = materialCode;
  constructor(private _globalService: GlobalService) {}

  caseRecoveryInfo$ = combineLatest([
    this._globalService.customerRecoveryData$,
    this._globalService.customerData$,
    this._globalService.countries$,
    this._globalService.bookingCargoDetails$,
    this._globalService.damageDetails$,
  ]).pipe(shareReplay(1));

  placeHolderKeyValues$: Observable<KeyValue[]> = this.caseRecoveryInfo$.pipe(
    map((caseRecoveryInfo) => this.getPlaceHolderKeyValues(caseRecoveryInfo)),
    shareReplay(1)
  );

  /**
   * This function generates an observable which upon subscription replaces the template placeholders.
   * @param emailTemplate template which needs to be populated with values.
   * @returns an observable which has the placeholder populated template
   */
  getPlaceholderPopulatedTemplate(
    emailTemplate: string,
    sendMailRequest: SendMailRequest
  ) {
    this.emailRequest = sendMailRequest;
    return new Observable<string>((subscriber) => {
      const placeHolderKeyValuesSubscription =
        this.placeHolderKeyValues$.subscribe((placeholderKeyValues) => {
          const populatedTemplate = this.populateTemplate(
            emailTemplate,
            placeholderKeyValues
          );
          subscriber.next(populatedTemplate);
        });

      return () => {
        placeHolderKeyValuesSubscription.unsubscribe();
      };
    });
  }

  private populateTemplate(
    emailTemplate: string,
    placeholderKeyValues: KeyValue[]
  ): string {
    const currencyPlaceholder = 'PH_CURRENCY';

    if (!emailTemplate.includes(currencyPlaceholder)) {
      emailTemplate = this.addCurrencyToAmount(
        emailTemplate,
        placeholderKeyValues
      );
    }

    emailTemplate = this.removeRepeatEnd(emailTemplate);

    // Replace placeholders using regular expression
    placeholderKeyValues.forEach((keyValue) => {
      const regex = new RegExp(`(${keyValue.key})`, 'g');
      emailTemplate = emailTemplate.replace(regex, keyValue.value);
    });

    return emailTemplate;
  }

  private addCurrencyToAmount(
    emailTemplate: string,
    placeholderKeyValues: KeyValue[]
  ): string {
    const amountAfterWaiverKeyValue = placeholderKeyValues.find(
      (kv) => kv.key === 'PH_AMOUNT_AFTER_WAIVER'
    );

    const amountBeforeWaiverKeyValue = placeholderKeyValues.find(
      (kv) => kv.key === 'PH_AMOUNT_BEFORE_WAIVER'
    );

    if (amountAfterWaiverKeyValue) {
      emailTemplate = emailTemplate.replaceAll(
        amountAfterWaiverKeyValue.key,
        `${amountAfterWaiverKeyValue.value} PH_CURRENCY`
      );
    }

    if (amountBeforeWaiverKeyValue) {
      emailTemplate = emailTemplate.replaceAll(
        amountBeforeWaiverKeyValue.key,
        `${amountBeforeWaiverKeyValue.value} PH_CURRENCY`
      );
    }

    return emailTemplate;
  }

  private removeRepeatEnd(emailTemplate: string): string {
    return emailTemplate.includes('<!--repeatend-->')
      ? emailTemplate.replaceAll('<!--repeatend-->', '')
      : emailTemplate;
  }

  getPlaceHolderKeyValues([
    customerRecoveryData,
    customerData,
    countries,
    bookingInfo,
    damageDetails,
  ]: [
    CustomerRecoveryCaseDto,
    CustomerInfoDto,
    CountryDto[],
    gcssBookingInfo,
    CaseDamageDetailDto[] | undefined,
  ]) {
    const length = customerData?.address?.length;
    const partLength = length ? Math.ceil(length / 3) : 0;

    const address1 = customerData?.address?.slice(0, partLength).trim();
    const address2 = customerData?.address
      ?.slice(partLength, 2 * partLength)
      .trim();
    const address3 = customerData?.address?.slice(2 * partLength).trim();

    let totalLiabilityAmount = 0;

    if (damageDetails && damageDetails.length > 0) {
      totalLiabilityAmount =
        Math.round(
          damageDetails.reduce((total, damageDetail) => {
            total += damageDetail.amountInCaseCurrency ?? 0;
            return total;
          }, 0) * 100
        ) / 100;
    }

    const keyValues: KeyValue[] = [
      {
        key: 'PH_CURRENCY',
        value: customerRecoveryData?.caseCurrency,
      } as KeyValue,
      {
        key: 'PH_AMOUNT_AFTER_WAIVER',
        value: '' + totalLiabilityAmount,
      } as KeyValue,
      {
        key: 'PH_AMOUNT_BEFORE_WAIVER',
        value: '' + totalLiabilityAmount,
      } as KeyValue,
      {
        key: 'PH_GROUP_DAMAGE',
        value: '',
      } as KeyValue,
      {
        key: 'PH_GROUP_WAIVER',
        value: '',
      } as KeyValue,
      {
        key: 'PH_GROUP_MANUAL_COST',
        value: '',
      } as KeyValue,
      {
        key: 'PH_GROUP_OOSERVICE_COST',
        value: '',
      } as KeyValue,
      {
        key: 'PH_OOS_MATERIAL_CODE_AMOUNT',
        value: damageDetails?.reduce((desc, damage) => {
          desc += damage.amountInCaseCurrency + '<br/>';
          return desc;
        }, ''),
      } as KeyValue,
      {
        key: 'PH_GROUP_ADDIOTIONAL_COST',
        value: '',
      } as KeyValue,
      {
        key: 'PH_LIABILITY_PARTY_NAME',
        value: this.emailRequest?.liabilityLetter?.liabilityPartyName ?? '-',
      } as KeyValue,
      {
        key: 'PH_CONTAINER_NUMBER',
        value: customerRecoveryData?.containerNumber,
      } as KeyValue,
      {
        key: 'PH_DAMAGE_LOC',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_DAMAGE_QTY',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_REPAIR_CODE',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_DAMAGE_LINE_TOTAL',
        value: '0',
      } as KeyValue,
      {
        key: 'PH_ADD_CHARGE_DESC',
        value: damageDetails?.reduce((desc, damage) => {
          desc += damage.description + '<br/>';
          return desc;
        }, ''),
      } as KeyValue,
      {
        key: 'PH_ADD_CHARGE_AMOUNT',
        value: damageDetails?.reduce((desc, damage) => {
          desc += damage.amountInCaseCurrency + '<br/>';
          return desc;
        }, ''),
      } as KeyValue,
      {
        key: 'PH_WAIVED_DESC',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_WAIVED_AMOUNT',
        value: '0',
      } as KeyValue,
      {
        key: 'PH_SENDER_NAME',
        value: sessionStorage.getItem('username') ?? '-',
      } as KeyValue,
      {
        key: 'PH_MATERIAL_CD',
        value: damageDetails?.reduce((desc, damage) => {
          desc +=
            this.materialCodes.find((m) => m.value == damage.materialCode)
              ?.value + '<br/>';
          return desc;
        }, ''),
      } as KeyValue,
      {
        key: 'PH_MATERIAL_CODE_DESC',
        value: damageDetails?.reduce((desc, damage) => {
          desc +=
            this.materialCodes.find((m) => m.value == damage.materialCode)
              ?.label + '<br/>';
          return desc;
        }, ''),
      } as KeyValue,
      {
        key: 'PH_OOS_CHARGE_AMOUNT',
        value: damageDetails?.reduce((desc, damage) => {
          desc += damage.amountInCaseCurrency + '<br/>';
          return desc;
        }, ''),
      } as KeyValue,
      {
        key: 'PH_OOS_CHARGE_DESC',
        value: damageDetails?.reduce((desc, damage) => {
          desc += damage.description + '<br/>';
          return desc;
        }, ''),
      } as KeyValue,
      {
        key: 'PH_REPAIR_NAME',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_SENDER_TELEPHONE_NO',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_SENDER_EMAIL',
        value: sessionStorage.getItem('email') ?? '-',
      } as KeyValue,
      {
        key: 'PH_LETTER_DATE',
        value: this._globalService.getUTCDateAndTime(new Date()),
      } as KeyValue,
      {
        key: 'PH_LETTER_REFERENCE',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_CASE_NUMBER',
        value: customerRecoveryData?.recoveryCaseNumber,
      } as KeyValue,
      {
        key: 'PH_INCIDENT_DATE',
        value: customerRecoveryData?.dateOfIncident
          ? new Date(customerRecoveryData?.dateOfIncident).toDateString()
          : '-',
      } as KeyValue,
      {
        key: 'PH_INCIDENT_LOC',
        value: customerRecoveryData?.placeOfIncident ?? '-',
      } as KeyValue,
      {
        key: 'PH_CONFIRMATION_DATE',
        value: this._globalService.getUTCDateAfterAddingDays(new Date(), 3), //currently setting for 3 days
      } as KeyValue,
      {
        key: 'PH_SCV_CODE',
        value: customerData?.scvCode ?? '-',
      } as KeyValue,
      {
        key: 'PH_CURRENT_LOC',
        value: customerRecoveryData?.shopCountryName ?? '-',
      } as KeyValue,
      {
        key: 'PH_ADDRESS2',
        value: address2 ?? '-',
      } as KeyValue,
      {
        key: 'PH_ADDRESS3',
        value: address3 ?? '-',
      } as KeyValue,
      {
        key: 'PH_ADDRESS1',
        value: address1 ?? '-',
      } as KeyValue,
      {
        key: 'PH_COUNTRY',
        value: countries.filter(
          (country) => country.code == customerData?.countryId
        )[0]?.name,
      } as KeyValue,
      {
        key: 'PH_ZIP_CODE',
        value: customerData?.zipCode,
      } as KeyValue,
      {
        key: 'PH_BL_NUM',
        value: bookingInfo?.invoiceNo,
      } as KeyValue,
      {
        key: 'PH_FACT_CODE',
        value: customerData?.factCode ?? '-',
      } as KeyValue,
      {
        key: 'PH_DAMAGE_EQP_SIZE',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_DAMAGE_EQP_TYPE',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_DAMAGE_COUNTRY_CODE',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_DAMAGE_TYPE',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_POOL_ID',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_MANUAL_CHARGE_AMOUNT',
        value: damageDetails?.reduce((desc, damage) => {
          desc += damage.amountInCaseCurrency + '<br/>';
          return desc;
        }, ''),
      } as KeyValue,
      {
        key: 'PH_MANUAL_CHARGE_DESC',
        value: damageDetails?.reduce((desc, damage) => {
          desc += damage.description + '<br/>';
          return desc;
        }, ''),
      } as KeyValue,
      {
        key: 'PH_MERC_PLUS_CHARGE_AMOUNT',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_MERC_PLUS_CHARGE_DESC',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_NUMBER_OF_CONTAINER',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_BOOK_NUM',
        value: bookingInfo?.bookingNumber ?? '-',
      } as KeyValue,
      {
        key: 'PH_REMARKS',
        value: '-',
      } as KeyValue,
      {
        key: 'PH_GALLERY',
        value: '-',
      } as KeyValue,
    ];

    return keyValues;
  }
}
