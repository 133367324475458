<lib-panel>
  <header>{{ item?.label }}</header>
  <sub-header>
    <ng-container
      *ngTemplateOutlet="
        checkEirAvailabilityAndSendVendorNotification
      "></ng-container>
  </sub-header>
  <content>
    <div
      #attachmentDiv
      class="attachment-page"
      (drop)="!disable ? onFilesDropped($event) : false"
      (dragover)="!disable ? onFileDragOver($event) : false"
      (dragleave)="!disable ? onDragLeave($event) : false">
      <ng-container
        *ngTemplateOutlet="vendorEmailNotificationList"></ng-container>
      <ng-container
        *ngTemplateOutlet="
          inspectionGridAndUnassignedCaseImages
        "></ng-container>
      <div class="section"><div>Assign to case</div></div>
      <ng-container *ngTemplateOutlet="errorImageUpload"></ng-container>
      <div class="case-assignment">
        <div class="file-uploader-wrapper">
          <ng-container *ngTemplateOutlet="fileUploadSection"></ng-container>
        </div>
        <div class="case-attachment">
          <ng-container
            *ngTemplateOutlet="caseAssignedImageSection"></ng-container>
        </div>
      </div>
    </div>
  </content>
</lib-panel>

<ng-template #checkEirAvailabilityAndSendVendorNotification>
  <div class="sub-header" *ngIf="customerRecoveryData$ | async as recoveryData">
    {{ item?.subLabel }}
    <div *ngIf="emailsSentToVendorsLength > 0">
      <mc-label>
        Last Updated
        {{ getUTCTime(recoveryData?.eirImageLastCheckedOn) }}
      </mc-label>
      <mc-tooltip>
        <mc-button
          fit="small"
          slot="trigger"
          hiddenlabel
          variant="plain"
          icon="arrow-clockwise"
          [disabled]="disableEirCheck || disable"
          (click)="onEirAvailabilityChecked()"></mc-button>
        <span *ngIf="isTimeDifferenceValidForEirExecution">
          check for eEIR/DERT
        </span>
        <span *ngIf="!isTimeDifferenceValidForEirExecution">
          No eEIR/DERT available. Check again in approximately
          {{ getRemainingMinutes(recoveryData.eirImageLastCheckedOn!!, 1) }}
          minutes
        </span>
      </mc-tooltip>
    </div>
    <!--When notifications are not sent show this button else use the notification list panel to send further emails-->
    <mc-button
      *ngIf="emailsSentToVendorsLength === 0 && inspectionRecordsLength > 0"
      variant="filled"
      appearance="neutral"
      fit="small"
      label="Send notification to vendor"
      (click)="onSendEmailButtonClicked()"></mc-button>
  </div>
</ng-template>

<ng-template #inspectionGridAndUnassignedCaseImages>
  <ng-container
    *ngIf="
      eirInspectionGridData$ | async as eirInspectionGridData;
      else sendVendorNotification
    ">
    <app-grid
      [schema]="eirInspectionGridSchema"
      [data]="eirInspectionGridData"
      [showPanel]="false"
      [showRowSelector]="true"
      [showPagination]="false"
      [enableSorting]="false"
      [rowSelectorType]="'radio'"
      (rowSelectionChange)="rowSelectionChanged($event)"></app-grid>
  </ng-container>

  <ng-container
    *ngIf="
      unselectedImagesFromCurrentSelectedEir$ | async as images;
      else initialImageLoader
    ">
    <div *ngIf="images.length > 0">
      <lib-image-lister
        [images]="images"
        [disable]="disable ?? false"
        (imagesSelected)="onEirRelatedImageSelected($event)"></lib-image-lister>
    </div>
  </ng-container>
</ng-template>

<ng-template #sendVendorNotification>
  <div
    class="vendor-notification-container"
    *ngIf="emailsSentToVendorsLength === 0">
    <mc-label>eEIR/DERT not found</mc-label>
    <div
      *ngIf="customerRecoveryData$ | async as recoveryData"
      class="last-updated-details">
      <mc-label>
        Last Updated
        {{ getUTCTime(recoveryData?.eirImageLastCheckedOn) }}
      </mc-label>
      <mc-tooltip>
        <mc-button
          fit="small"
          slot="trigger"
          hiddenlabel
          variant="plain"
          icon="arrow-clockwise"
          [disabled]="disableEirCheck || disable"
          [loading]="areEirImagesLoading"
          (click)="onEirAvailabilityChecked()"></mc-button>
        <span *ngIf="isTimeDifferenceValidForEirExecution">
          check for eEIR/DERT
        </span>
        <span *ngIf="!isTimeDifferenceValidForEirExecution">
          No eEIR/DERT available. Check again in approximately
          {{ getRemainingMinutes(recoveryData.eirImageLastCheckedOn!!, 1) }}
          minutes
        </span>
      </mc-tooltip>
    </div>
    <ng-container
      *ngIf="{
        value: isPodCountryExcludedForVendorEmailNotification$ | async
      } as result">
      <mc-button
        *ngIf="!result.value"
        variant="filled"
        appearance="neutral"
        [disabled]="disable"
        fit="small"
        label="Send notification to vendor"
        (click)="onSendEmailButtonClicked()"></mc-button>
      <mc-notification
        *ngIf="result.value"
        body="Send notification to vendor feature is disabled for this country"
        appearance="info"
        fit="small"
        verticalalign="middle"
        icon="exclamation-octagon"></mc-notification>
    </ng-container>
  </div>
</ng-template>

<ng-template #vendorEmailNotificationList>
  <ng-container
    *ngIf="emailsSentToVendorsAsTemplate$ | async as emailsSentToVendors">
    <lib-panel
      [hasAccordion]="inspectionRecordsLength > 0"
      [openAccordion]="false">
      <header>Notifications to Vendor</header>
      <content>
        <div class="vendor-email-list-wrapper">
          @for (emailEntitiesAsFormItems of emailsSentToVendors; track $index) {
            <div *ngIf="$index > 0" class="hr"></div>
            <div class="vendor-email-notification-list">
              <app-lib-form [items]="emailEntitiesAsFormItems"></app-lib-form>
              <mc-button
                label="View Notification"
                variant="filled"
                appearance="neutral"
                fit="medium"
                padding="default"
                [disabled]="disable"
                (click)="viewEmailNotificationClicked($index)"></mc-button>
              <mc-button
                *ngIf="$index === 0"
                label="Resend"
                variant="filled"
                fit="medium"
                padding="default"
                [disabled]="disable"
                (click)="resendEmailNotificationClicked($index)"></mc-button>
            </div>
          }
          <ng-container *ngTemplateOutlet="viewDetailEmailModal"></ng-container>
        </div>
      </content>
    </lib-panel>
  </ng-container>
</ng-template>

<ng-template #fileUploadSection>
  <div
    class="file-uploader"
    [class.disabled]="disableFileUpload"
    (click)="disableFileUpload ? false : fileUploader.click()">
    <input
      type="file"
      #fileUploader
      [disabled]="disableFileUpload"
      style="display: none"
      [accept]="validFormats"
      multiple="true"
      (change)="onImagesManuallySelected($event)" />
    <mc-icon icon="tray-arrow-up" size="24"></mc-icon>
    <div>Drag and drop or browse files</div>
  </div>
  <div class="file-uploader-hint" [class.disabled]="disableFileUpload">
    Only images (png, jpg & jpeg), max file size: 2MB
  </div>
</ng-template>

<ng-template #caseAssignedImageSection>
  <ng-container
    *ngIf="
      selectedImagesForCurrentCase$ | async as selectedImages;
      else initialImageLoader
    ">
    <ng-container *ngIf="selectedImages.length > 0">
      <div>eEir Images</div>
      <div>
        <lib-image-lister
          [images]="selectedImages"
          [disable]="disable ?? false"
          (imageRemoved)="onEirRelatedImageRemoved($event)"></lib-image-lister>
      </div>
    </ng-container>
  </ng-container>
  <ng-container
    *ngIf="uploadedImagesForTheCurrentCase$ | async as uploadedImages">
    <div *ngIf="isUploadingInProgress && uploadedImages.length === 0">
      <ng-container
        *ngTemplateOutlet="
          imageLoader;
          context: {
            placeholders: uploadedFileCount
          }
        "></ng-container>
    </div>
    <ng-container *ngIf="uploadedImages.length > 0">
      <div>Manually uploaded files</div>
      <div>
        <lib-image-lister
          [images]="uploadedImages"
          [showPlaceHolder]="isUploadingInProgress"
          [numberOfPlaceholders]="uploadedFileCount"
          [disable]="disable ?? false"
          (imageRemoved)="onUploadedImagesRemoved($event)"></lib-image-lister>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #imageLoader let-placeholders="placeholders">
  <lib-image-lister
    [images]="[]"
    [showPlaceHolder]="true"
    [disable]="disable ?? false"
    [numberOfPlaceholders]="placeholders"></lib-image-lister>
</ng-template>

<ng-template #initialImageLoader>
  <lib-image-lister
    [images]="[]"
    [showPlaceHolder]="true"
    [disable]="disable ?? false"
    [numberOfPlaceholders]="5"></lib-image-lister>
</ng-template>

<ng-template #errorImageUpload>
  <mc-notification
    *ngIf="errorMessage !== ''"
    [body]="errorMessage"
    appearance="error"
    icon="exclamation-octagon"></mc-notification>
</ng-template>

<ng-template #viewDetailEmailModal>
  <mc-modal
    *ngIf="openDetailedEmailModal"
    [open]="true"
    escapecloseactiondisabled
    backdropcloseactiondisabled
    dimension="large"
    (closed)="openDetailedEmailModal = false"
    (closing)="openDetailedEmailModal = false">
    <ng-container *ngIf="emailsSentToVendors$ | async as emailsSentToVendors">
      <span slot="heading" class="heading-body">
        <div class="heading">Notification Sent</div>
        <div class="subject">
          <span>
            {{ emailsSentToVendors?.[currentViewedEmailNotification]?.subject }}
          </span>
          <span>
            {{
              emailsSentToVendors?.[currentViewedEmailNotification]?.sentOn
                | date: 'd MMM y h:mm:ss a'
            }}
          </span>
        </div>
      </span>
      <div class="mds-text--medium-normal modal-body">
        <div class="to-cc">
          <span>TO</span>
          <span>
            {{ emailsSentToVendors?.[currentViewedEmailNotification]?.to }}
          </span>
        </div>
        <div class="to-cc">
          <span>CC</span>
          <span>
            {{ emailsSentToVendors?.[currentViewedEmailNotification]?.cc }}
          </span>
        </div>
        <div class="body" [innerHTML]="emailBody">
          {{ emailsSentToVendors?.[currentViewedEmailNotification]?.body }}
        </div>
      </div>
    </ng-container>
    <mc-button
      focusstartanchor
      slot="primaryAction"
      appearance="primary"
      (click)="resendEmailNotificationClicked(currentViewedEmailNotification)"
      dialogaction="ok">
      Resend
    </mc-button>
  </mc-modal>
</ng-template>
