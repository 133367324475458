<lib-panel *ngIf="customerRecoveryData$ | async">
  <header>{{ item?.label }}</header>
  <content>
    <app-grid
      [schema]="invoiceGridSchema"
      [data]="invoiceSummaryGridData$ | async"
      [footerSchema]="footerSchema"
      [showPanel]="false"
      [showRowSelector]="false"
      [showPagination]="false"
      [enableSorting]="false"></app-grid>
  </content>
</lib-panel>
