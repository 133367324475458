<div>
  <form [formGroup]="claimForm">
    <mc-modal
      heading="status"
      (closing)="onModelClose($event)"
      [open]="true"
      escapecloseactiondisabled
      backdropcloseactiondisabled>
      <span slot="heading" class="heading">
        {{ column === 'claimStatusId' ? 'Status' : 'Comments' }}
        <mc-text-and-icon
          slot="trigger"
          label="CASE: {{ this.statusDetails?.caseNumber }}"
          icon="briefcase"
          size="small"></mc-text-and-icon>
      </span>
      <ng-container>
        <div *ngIf="column === 'claimStatusId'">
          <mc-radio-group
            ngDefaultControl
            formControlName="claimStatus"
            legend="Select case status"
            (change)="caseStatusChanged($event)"
            aria-label="Select case status"
            orientation="horizontal">
            <ng-container *ngFor="let status of newStatusList">
              <mc-radio
                [value]="status"
                [label]="status"
                name="claimStatus"
                [checked]="status === selectedStatusCode"></mc-radio>
            </ng-container>
          </mc-radio-group>
          <div
            style="padding-top: 12px"
            *ngIf="selectedStatusCode === 'Closed'">
            <mc-select
              ngDefaultControl
              formControlName="cancelReason"
              label="Reason for closing"
              placeholder="Select a reason"
              optionswidth="trigger">
              <ng-container *ngFor="let cancelReason of cancelReasonsList">
                <mc-option [value]="cancelReason">{{ cancelReason }}</mc-option>
              </ng-container>
            </mc-select>
          </div>
        </div>
        <div>
          <div class="text-area-header">
            {{ StatusModalView.subHeaderText }}
          </div>
          <div>
            <textarea
              id="comments"
              name="comments"
              [rows]="StatusModalView.textAreaHeight"
              style="padding-top: 12px"
              formControlName="comments"
              placeholder="Enter your comments here"
              class="text-area-box"></textarea>
          </div>
        </div>
      </ng-container>
      <mc-button
        slot="primaryAction"
        appearance="primary"
        (click)="onStatusUpdateClick($event)"
        [disabled]="
          selectedStatusCode !== 'Closed'
            ? !claimForm.dirty
            : !(
                claimForm.get('cancelReason')?.value &&
                claimForm.get('cancelReason')?.value.length > 0
              )
        ">
        {{ StatusModalView.buttonText }}
      </mc-button>
      <mc-button
        slot="secondaryAction"
        appearance="neutral"
        variant="outlined"
        (click)="onModelClose($event)">
        Cancel
      </mc-button>
    </mc-modal>
  </form>
</div>
