import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  DropDownOption,
  PanelComponent,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import {
  catchError,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { GlobalService } from '../../../global-service';
import { CaseInvoiceDetailPostDto } from '../../../common/models/caseInvoiceDetailPostDto';
import { CollectionOfficeDto } from '../../../common/models/collectionOfficeDto';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import * as toastMessagesTemplate from '../../../common/toast-service-messages';

@Component({
  selector: 'invoice-letter',
  standalone: true,
  imports: [CommonModule, PanelComponent, ReactiveFormsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './invoice-letter.component.html',
  styleUrl: './invoice-letter.component.scss',
})
export class InvoiceLetterComponent implements OnInit {
  apiVersion: string = '1.0';
  userId: string = sessionStorage.getItem('userId') || '';
  collectionOfficeCode: string = '';
  closeCase: boolean = false;
  invoiceForm!: FormGroup;
  toastMessages = toastMessagesTemplate.default;
  ngOnInit() {
    this._globalService.updateNextButtonStateOnFormValidation(false);
  }

  customerRecoveryData$ = this._globalService.customerRecoveryData$.pipe(
    tap((recoveryData) => {
      this.closeCase =
        recoveryData.recoveryStatusId === 6 ||
        recoveryData.recoveryStatusId === 7;
      this.invoiceForm = new FormGroup({
        remarks: new FormControl({ value: '', disabled: this.closeCase }),
        collectionOffice: new FormControl(
          { value: '', disabled: this.closeCase },
          [Validators.required]
        ),
      });
    })
  );

  /**
   *Main observable Collection Office
   */
  collectionOffices$?: Observable<CollectionOfficeDto[] | undefined> =
    this._globalService.commonCollectionOffices$;

  /**
   * observable holding dropdown value of templates
   */
  collectionOfficeDropDown$?: Observable<DropDownOption[] | null> =
    this.collectionOffices$?.pipe(
      switchMap(async (offices) => {
        if (!offices) return null;
        const recoveryData = await firstValueFrom(
          this._globalService.customerRecoveryData$
        );
        offices = offices.filter(
          (siteOffices) =>
            siteOffices.coCountryCode == recoveryData.podCountryCode &&
            siteOffices.coOperator == recoveryData.operatorCode
        );
        return offices?.map((office) => {
          return {
            label: office.coName,
            value: office.coNumber,
          } as DropDownOption;
        });
      }),
      tap((res) => {
        //TODO Temporary as few case number don't have collection office
        console.log(`case offices`);
        console.log(res);
      })
    );

  factCode$: Observable<string | null> =
    this._globalService.LiablePartyData$.pipe(
      map((liabilityParty) => {
        if (!liabilityParty) return '-';
        return liabilityParty.customBookingParty?.factCustomerCode ?? '-';
      })
    );

  constructor(
    private _globalService: GlobalService,
    private _toasterService: ToasterService,
    private _customerRecoveryService: CustomerRecoveryClaimService
  ) {}

  onDrpDwnCollectionOfficeSelected(event: any) {
    this.collectionOfficeCode = event.detail.value;
    this._globalService.updateNextButtonStateOnFormValidation(true);
  }

  onCollectionOfficeTextChange(event: Event) {
    if (!((event as InputEvent).target as HTMLInputElement).value)
      this._globalService.updateNextButtonStateOnFormValidation(false);
  }

  async createInvoice() {
    const factCode = await firstValueFrom(this.factCode$);
    const customerRecoveryData = await firstValueFrom(
      this._globalService.customerRecoveryData$
    );
    const invoiceReq = {
      factCode: factCode,
      factReferenceNumber: '',
      remarks: this.invoiceForm?.get('remarks')?.value,
      collectionOfficeCode: this.collectionOfficeCode,
      version: await this.getNewInvoiceVersion(),
      userId: this.userId,
    } as CaseInvoiceDetailPostDto;

    const invoiceDetailsPostResponse = await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsCaseIdInvoiceDetailsPost(
          customerRecoveryData.caseId ?? 0,
          invoiceReq,
          this.apiVersion
        )
        .pipe(
          catchError((error) => {
            this._toasterService.showToast({
              message: this.toastMessages.invoicing.createNewInvoice,
              type: 'error',
            });
            throw error;
          })
        )
    );
    this._globalService.updateNextButtonStateOnFormValidation(false);
    this._toasterService.showToast({
      message: this.toastMessages.invoicing.createNewInvoice,
      type: 'success',
    });
    this._globalService.reloadInvoices();
    this._globalService.updateIssueInvoiceVisibility(false);
    return invoiceDetailsPostResponse;
  }

  async getNewInvoiceVersion() {
    const previousInvoices = await firstValueFrom(
      this._globalService.invoices$
    );
    if (!previousInvoices || previousInvoices.length === 0) return '001';
    previousInvoices.sort(
      (letter1, letter2) =>
        new Date(letter2.createdDate ?? '').getTime() -
        new Date(letter1.createdDate ?? '').getTime()
    );
    const lastInvoice = previousInvoices[0];
    const newInvoice = Number(lastInvoice.version) + 1;
    return ('00' + newInvoice).slice(-3);
  }
}
