import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  LibFormComponent,
  PanelComponent,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { Observable, tap } from 'rxjs';
import { GlobalService } from '../../../global-service';

@Component({
  selector: 'app-workflow-container-info',
  standalone: true,
  imports: [CommonModule, LibFormComponent, PanelComponent],
  templateUrl: './app-workflow-container-info.component.html',
  styleUrl: './app-workflow-container-info.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppWorkflowContainerInfoComponent {
  @Input() item!: TemplateModel;
  items: TemplateModel[] = [];
  caseDetail!: CustomerRecoveryCaseDto;

  constructor(private globalService: GlobalService) {}

  customerRecoveryData$: Observable<CustomerRecoveryCaseDto> =
    this.globalService.customerRecoveryData$.pipe(
      tap((recoveryData) => {
        this.caseDetail = recoveryData;
        this.items = this.item.items as TemplateModel[];
        this.bindContainerData();
      })
    );

  bindContainerData() {
    this.items.forEach((item: TemplateModel) => {
      const customerRecoveryKeyValue = this.caseDetail as unknown as {
        [key: string]: unknown;
      };
      item.value =
        item.name == 'locationId'
          ? `${customerRecoveryKeyValue[item.name as string]} - ${customerRecoveryKeyValue['shopCountryName']}`
          : customerRecoveryKeyValue[item.name as string]; // need to add this in shared -
    });
  }
}
