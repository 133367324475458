import { CommonModule, DatePipe } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  LibFormComponent,
  PanelComponent,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { lastValueFrom, map, tap } from 'rxjs';
import { DropDownOption } from '@maersk-global/angular-shared-library/lib/models/drop-down';
import { CountryDto } from '../../../common/models/countryDto';
import { CarrierCodeDto } from '../../../common/models/carrierCodeDto';
import { ClusterDto } from '../../../common/models/clusterDto';
import { CurrencyAndExchangeRateDto } from '../../../common/models/currencyAndExchangeRateDto';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { GlobalService } from '../../../global-service';
import { CommonService } from '../../../common/services/customer-recovery/common.service';

@Component({
  selector: 'app-workflow-overview',
  standalone: true,
  imports: [CommonModule, LibFormComponent, PanelComponent],
  templateUrl: './app-workflow-overview.component.html',
  styleUrl: './app-workflow-overview.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe],
})
export class AppWorkflowOverviewComponent {
  @Input() item!: TemplateModel;
  @Input() disable?: boolean | false;

  items: TemplateModel[] = [];
  podCountry!: CountryDto[];
  operaters!: CarrierCodeDto[];
  clusters!: ClusterDto[];
  CurrencyAndExchangeRate!: CurrencyAndExchangeRateDto[];
  caseDetail?: CustomerRecoveryCaseDto;

  DATEOFINCIDENT: string = 'dateOfIncident';
  PODLOCALCURRENCY: string = 'podLocalCurrency';
  EXCHANGERATE: string = 'exchangerate';
  PODCOUNTRY: string = 'podCountryName';
  PODCLUSTER: string = 'podCountryClusterName';
  OPERATORCODE: string = 'operatorCode';

  apiVersion: string = '1.0';
  constructor(
    private datePipe: DatePipe,
    private globalService: GlobalService,
    private _commonService: CommonService
  ) {}

  customerRecoveryData$ = this.globalService.customerRecoveryData$.pipe(
    tap((recoveryData) => {
      this.caseDetail = recoveryData;
      this.loadOverviewData();
    })
  );

  loadOverviewData() {
    this.items = this.item.items as TemplateModel[];
    this.item?.items?.forEach(async (overview: TemplateModel) => {
      overview.disabled = this.disable;
      if (overview.type == 'select' && overview.name) {
        overview.options = await this.getFilterOptionsByColumn(overview.name);
        if (overview.value && overview.name == this.PODLOCALCURRENCY) {
          this.bindExchangesRateValue(overview.value);
        }
      }
    });
    this.bindOverViewData();
  }

  ngOnChange(changes: SimpleChanges) {
    if (changes['disable']) {
      this.item?.items?.forEach(async (i: TemplateModel) => {
        this.item?.items?.forEach(async (i: TemplateModel) => {
          i.disabled = this.disable;
        });
      });
    }
  }

  bindOverViewData() {
    if (this.item.loaded) return;
    const keyValueCustomerDetail = this.caseDetail as unknown as {
      [key: string]: unknown;
    };

    this.items.forEach((item: TemplateModel) => {
      item.value = keyValueCustomerDetail[item.name as string];
      if (item.name == this.PODLOCALCURRENCY) {
        item.value =
          this.caseDetail?.caseCurrency ??
          this.caseDetail?.podLocalCurrency ??
          'USD';
        item.onValueChanged = (value) => { this.bindExchangesRateValue.call(this,value); }
      }

      if (item.name == this.DATEOFINCIDENT && item.value) {
        item.value = this.datePipe.transform(
          new Date(keyValueCustomerDetail[item.name] as string),
          'dd-MM-yyyy'
        );
      }
    });
    this.item.loaded = true;
  }

  bindExchangesRateValue(value: string) {
    //Checking if the dataset is populated
    if (!this.CurrencyAndExchangeRate){
      return;
    }
    
    const exchangeRateObj = this.CurrencyAndExchangeRate.find(
      (currency) => currency.quotationCurrency == value
    );
    this.items.filter((item: TemplateModel) => {
      if (item.name == this.EXCHANGERATE) {
        const transformDate = exchangeRateObj?.quotationDateTime
          ? this.datePipe.transform(
              new Date(exchangeRateObj?.quotationDateTime),
              'd MMM y'
            )
          : '';
        item.value = `${exchangeRateObj?.exchangeRatePerUnit}  ${transformDate}`;
      }
    });
  }

  /**
   * Get filter options by column name.
   * @param itemName column name
   * @returns
   *
   *  Promise<ClaimStatusResponse>
   */
  private async getFilterOptionsByColumn(
    columnName: string
  ): Promise<DropDownOption[]> {
    if (columnName == this.PODCOUNTRY) {
      this.podCountry = (await lastValueFrom(
        this.globalService.countries$
      )) as CountryDto[];
      return this.maptoDropDowOptionFromCountry(this.podCountry);
    } else if (columnName == this.OPERATORCODE) {
      this.operaters = (await lastValueFrom(
        this.globalService.carrierCodes$
      )) as CarrierCodeDto[];
      return this.maptoDropDowOptionFromCarrierCodes(this.operaters);
    } else if (columnName == this.PODCLUSTER) {
      this.clusters = (await lastValueFrom(
        this.globalService.countryClusters$
      )) as ClusterDto[];
      return this.maptoDropDowOptionFromClusters(this.clusters);
    } else if (columnName == this.PODLOCALCURRENCY) {
      //Fetching work order date for fetching exchange rates
      let woDate = this.caseDetail?.workOrderDate
        ? new Date(this.caseDetail?.workOrderDate).toISOString()
        : new Date().toUTCString();
      this.CurrencyAndExchangeRate = (await lastValueFrom(
        this._commonService
          .commonCurrencyAndExchangeRatesForQuotationDateGet(
            woDate,
            this.apiVersion
          )
          .pipe(map((response) => (response?.data ? response.data : null)))
      )) as CurrencyAndExchangeRateDto[];

      return this.maptoDropDowOptionFromCurrencies(
        this.CurrencyAndExchangeRate
      );
    }
    return [] as DropDownOption[];
  }

  maptoDropDowOptionFromCountry(list: CountryDto[]) {
    const options = list.map((country: CountryDto) => {
      return {
        label: country.name,
        value: country.name,
      } as DropDownOption;
    });
    return options as DropDownOption[];
  }

  maptoDropDowOptionFromCarrierCodes(list: CarrierCodeDto[]) {
    const options = list.map((country: CarrierCodeDto) => {
      return {
        label: country.operatorName,
        value: country.operatorCode,
      } as DropDownOption;
    });
    return options as DropDownOption[];
  }

  maptoDropDowOptionFromCurrencies(list: CurrencyAndExchangeRateDto[]) {
    const options = list.map((country: CurrencyAndExchangeRateDto) => {
      return {
        label: country.quotationCurrency,
        value: country.quotationCurrency,
      } as DropDownOption;
    });
    return options as DropDownOption[];
  }

  maptoDropDowOptionFromClusters(list: ClusterDto[]) {
    const options = list.map((country: ClusterDto) => {
      return {
        label: country.clusterName,
        value: country.clusterName,
      } as DropDownOption;
    });
    return options as DropDownOption[];
  }

  formValidityChanged(isValid: boolean) {
    this.globalService.updateNextButtonStateOnFormValidation(isValid);
  }
}
