<lib-panel *ngIf="cargoDetail$ | async">
  <header>{{ item.label }}</header>
  <left-corner-menu>
    <div *ngIf="false">
      <a (click)="showHideContainerMovesLink()" class="header-link">
        {{
          showContainerMoves ? 'Hide container moves' : 'View container moves'
        }}
      </a>
    </div>
  </left-corner-menu>
  <content>
    <div>
      <app-lib-form [items]="items"></app-lib-form>
    </div>
    <div class="section" *ngIf="showContainerMoves"></div>
    <div
      [style.display]="showContainerMoves ? 'block' : 'none'"
      class="sub-fields">
      <div class="moves-filter" *ngIf="customerRecoveryData$ | async">
        <mc-input-date
          ngDefaultControl
          formControlName="checkin-date"
          label=""
          startofweek="1"
          format="DD/MM/YYYY"
          locale="en-IN"
          nextlabel="Next month"
          previouslabel="Previous month"
          hiddenlabel
          placeholder="DD/MM/YYYY"
          [disabled]="disable"
          fit="small"
          width="232px"
          [value]="selectedFromDate"
          (input)="OnDateSelectHandler('from', $event)"></mc-input-date>

        <mc-input-date
          ngDefaultControl
          formControlName="checkin-date"
          label=""
          startofweek="1"
          format="DD/MM/YYYY"
          locale="en-IN"
          nextlabel="Next month"
          previouslabel="Previous month"
          hiddenlabel
          placeholder="DD/MM/YYYY"
          [disabled]="disable"
          fit="small"
          width="232px"
          [value]="selectedToDate"
          (input)="OnDateSelectHandler('to', $event)"></mc-input-date>

        <mc-button
          label="Update moves"
          fit="small"
          [disabled]="disable"
          (click)="updateMoves()"></mc-button>
        <mc-button
          label="Cancel"
          fit="small"
          appearance="neutral"
          [disabled]="disable"
          (click)="setDateRangeForContainerMoves()"></mc-button>

        <span
          class="errorMessage"
          [style.display]="invalidDateSelected ? 'block' : 'none'"
          style="color: red">
          Invalid date format selected
        </span>
      </div>
      <div class="movesData">
        <app-workflow-container-moves
          [item]="containerMovesItem"
          [fromDate]="containerMovesFromDate!"
          [toDate]="containerMovesToDate!"
          [caseNumber]="caseNumber!"
          [recoveryData]="recoveryData"
          [disable]="disable"
          [containerNumber]="containerNumber"></app-workflow-container-moves>
      </div>
    </div>
  </content>
</lib-panel>

<div *ngIf="updateBookingNumberModal">
  <mc-modal
    [open]="updateBookingNumberModal"
    escapecloseactiondisabled
    backdropcloseactiondisabled
    heading="Change Booking Number"
    dimension="small"
    fit="small"
    (closed)="updateBookingNumberModal = false; newBookingNo = ''"
    (closing)="updateBookingNumberModal = false; newBookingNo = ''">
    <mc-input
      ngDefaultControl
      label="Booking Number"
      fit="small"
      [name]="item.name"
      placeholder=" Enter Booking Number"
      (input)="bookingChangeOnTextChange($event)"></mc-input>

    <mc-button
      focusstartanchor
      slot="primaryAction"
      appearance="primary"
      (click)="updateBookingNumberConfirm()">
      Update
    </mc-button>
    <mc-button
      focusendanchor
      slot="secondaryAction"
      appearance="neutral"
      variant="outlined"
      (click)="updateBookingNumberModal = false">
      Cancel
    </mc-button>
  </mc-modal>
</div>
