import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  SimpleChanges,
  input,
} from '@angular/core';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import {
  LibFormComponent,
  PanelComponent,
} from '@maersk-global/angular-shared-library';
import { gcssBookingInfo } from '../../../common/models/gcssBookingInfo';
import { Observable, tap } from 'rxjs';
import { GlobalService } from '../../../global-service';

@Component({
  selector: 'app-workflow-cargo-details',
  standalone: true,
  imports: [CommonModule, LibFormComponent, PanelComponent],
  templateUrl: './app-workflow-cargo-details.component.html',
  styleUrl: './app-workflow-cargo-details.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppWorkflowCargoDetailsComponent {
  @Input() item!: TemplateModel;
  items: TemplateModel[] = [];
  @Input() itemValue!: gcssBookingInfo | null;
  @Input() containerNumber!: string;
  weightAsKg: boolean = true;
  lbs: number = 2.2046;
  CargoDetails!: gcssBookingInfo;
  initialLabels = {
    stuffingWeight: 'Stuffed Weight',
    totalCommodityVolume: 'Total Commodity Volume',
    stuffingVolume: 'Stuffed Volume',
    totalCommodityWeight: 'Total Commodity Weight',
  } as {
    [key: string]: string;
  };

  constructor(private globalService: GlobalService) {}
  ngOnInit() {
    this.items = this.item.items as TemplateModel[];
  }

  cargoDetail$: Observable<gcssBookingInfo> =
    this.globalService.bookingCargoDetails$.pipe(
      tap((cargo) => {
        this.CargoDetails = cargo;
        this.bindValueToItems();
      })
    );

  bindValueToItems() {
    if (this.CargoDetails)
      this.items.forEach((item: TemplateModel) => {
        item.label = this.filterForMetricCheckForLabel(item.label, item.name);
        const bookingCargoKeyValue = this.CargoDetails as {
          [key: string]: unknown;
        };
        item.value = this.filterForMetricCheckForValue(
          bookingCargoKeyValue[item.name as string],
          item.name
        );
      });
  }

  filterForMetricCheckForValue(value?: any, name?: string): any {
    if (
      name &&
      [
        'stuffingWeight',
        'totalCommodityVolume',
        'stuffingVolume',
        'totalCommodityWeight',
      ].includes(name)
    ) {
      value = this.formatDecimal(this.weightAsKg ? value : value * this.lbs);
    }
    return value;
  }

  filterForMetricCheckForLabel(label?: string, name?: string): string {
    if (
      name &&
      [
        'stuffingWeight',
        'totalCommodityVolume',
        'stuffingVolume',
        'totalCommodityWeight',
      ].includes(name)
    ) {
      label = this.weightAsKg
        ? `${this.initialLabels[name]} (kgs)`
        : `${this.initialLabels[name]} (Lbs)`;
    }
    return label ?? '';
  }

  onWeightClick() {
    this.bindValueToItems();
  }

  private formatDecimal(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
