/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type StorageType = 0 | 1 | 2;

export const StorageType = {
    NUMBER_0: 0 as StorageType,
    NUMBER_1: 1 as StorageType,
    NUMBER_2: 2 as StorageType
};