import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  ALIGN,
  GridColumnSchema,
  GridComponent,
  GridRowData,
} from '@maersk-global/angular-shared-library';
import { FooterRowData } from '@maersk-global/angular-shared-library/lib/models/footer-row-data';
import { FooterSchema } from '@maersk-global/angular-shared-library/lib/models/footer-schema';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { WorkOrderAndLineItemsDto } from '../../../common/models/workOrderAndLineItemsDto';
import { GlobalService } from '../../../global-service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-workflow-liability-details',
  standalone: true,
  imports: [CommonModule, GridComponent],
  templateUrl: './app-workflow-liability-details.component.html',
  styleUrl: './app-workflow-liability-details.component.scss',
})
export class AppWorkflowLiabilityDetailsComponent {
  @Input({ required: true }) item!: TemplateModel;
  @Input() workOrders: WorkOrderAndLineItemsDto[] = [];
  items: TemplateModel[] = [];

  costData: GridRowData[] = [];
  gridSchema: GridColumnSchema[] = [];
  costFooter: FooterRowData | null = null;
  footerSchema: FooterSchema[] = [];
  currencyCode = 'USD';
  recoveryData?: CustomerRecoveryCaseDto;
  exchangeRate = 1.0;
  constructor(private globalService: GlobalService) {}

  customerRecoveryData$ = this.globalService.customerRecoveryData$.pipe(
    tap((recoveryData) => {
      this.recoveryData = recoveryData;
      //Fetching currency and exchange rate
      if (
        recoveryData.caseCurrency &&
        recoveryData.exchangeRateUSDCaseCurrency
      ) {
        this.currencyCode = recoveryData.caseCurrency as string;
        this.exchangeRate = recoveryData.exchangeRateUSDCaseCurrency as number;
      }
      //Binding schema
      this.bindSchema();
      this.bindGridData();
    })
  );

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['workOrders'].currentValue &&
      changes['workOrders'].currentValue.length > 0
    ) {
      this.bindGridData();
    }
  }

  /**
   * Binds schema for liability grid and footer
   */
  bindSchema() {
    //Binding grid schema
    if (this.item && this.item.items && this.item.items[0].items) {
      this.gridSchema = this.item.items[0].items.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          displayName: y.label?.replace('Local', this.currencyCode),
          align: y.valueType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
          hidden: y.name === 'costLocal' && this.currencyCode === 'USD',
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
        } as GridColumnSchema;
        return column;
      });
    }

    //Binding footer schema
    if (this.item && this.item.items && this.item.items[1].items) {
      this.footerSchema = this.item.items[1].items.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          align: y.valueType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
          hidden: y.name === 'costLocal' && this.currencyCode === 'USD',
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
          colSpan: y.colspan,
        } as FooterSchema;
        return column;
      });
    }
  }

  /**
   * Binds data for liability grid and footer
   */
  bindGridData() {
    if (!(this.workOrders && this.workOrders.length > 0)) return;
    // Calculate costs for all work orders
    const cost = this.calculateCost(this.workOrders);

    // Filter items which are required for grid data and footer data
    this.items = this.item.items?.filter((o) => o.sequence) as TemplateModel[];

    // Iterate through items and update values
    this.items.forEach((item: TemplateModel) => {
      switch (item.name) {
        case 'woCostUSD':
          item.value = this.formatDecimal(cost.workOrderCostIncludingTax);
          break;
        case 'withinCoverageCostUSD':
          item.value = this.formatDecimal(cost.withInCoverageCost);
          break;
        case 'recoverableCostUSD':
          item.value = this.formatDecimal(cost.recoverableCost);
          break;
        case 'aboveCoverageCostUSD':
          const aboveCoverageCost =
            cost.recoverableCost - cost.withInCoverageCost > 0
              ? cost.recoverableCost - cost.withInCoverageCost
              : 0;
          item.value = this.formatDecimal(aboveCoverageCost);
          break;
        case 'handlingDamageCostUSD':
          item.value = this.recoveryData?.handlingDamageCostUSD ?? 0;
          break;
        default:
          break;
      }
    });

    // Populate grid data excluding aboveCoverageCostUSD
    this.costData = this.items
      .filter((o) => o.name !== 'aboveCoverageCostUSD')
      .map((item: TemplateModel) => ({
        row: {
          costType: { value: item.label },
          costLocal: {
            value: this.formatDecimal(item.value / this.exchangeRate),
          },
          costUSD: { value: item.value },
        },
        isMasterRow: false,
        showChildRowData: false,
      })) as GridRowData[];

    // Populate footer data for aboveCoverageCostUSD
    const aboveCoverageItem = this.items.find(
      (o) => o.name === 'aboveCoverageCostUSD'
    );
    if (aboveCoverageItem) {
      this.costFooter = {
        row: {
          costType: { value: aboveCoverageItem.label },
          costLocal: {
            value: this.formatDecimal(
              aboveCoverageItem.value / this.exchangeRate
            ),
          },
          costUSD: { value: aboveCoverageItem.value },
        },
      } as FooterRowData;
    }
  }

  calculateCost(workOrders: WorkOrderAndLineItemsDto[]) {
    let cost = {
      workOrderCostIncludingTax: 0,
      withInCoverageCost: 0,
      recoverableCost: 0,
    };

    workOrders.forEach((order) => {
      if (Array.isArray(order.workOrderLineItemDto)) {
        if (![4, 5, 6].includes(order.recoveryStatusId!)) {
          cost.withInCoverageCost += order.withinCoverageCostUSD!!;
        }

        cost.workOrderCostIncludingTax += order.workOrderCostUSD!!;
        cost.recoverableCost += order.recoverableCostUSD!!;
      }
    });

    // Format all cost values to have at most 2 decimal digits
    cost.workOrderCostIncludingTax = this.formatDecimal(
      cost.workOrderCostIncludingTax
    );
    cost.withInCoverageCost = this.formatDecimal(cost.withInCoverageCost);
    cost.recoverableCost = this.formatDecimal(cost.recoverableCost);

    return cost;
  }

  private formatDecimal(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
