import { Injectable } from '@angular/core';
import {
  Subject,
  Subscription,
  debounceTime,
  fromEvent,
  merge,
  takeUntil,
  timer,
} from 'rxjs';
import { AuthHelper } from '../../../helper/auth-helper';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
const TIMEOUT_DURATION: number = environment.sessionTimeoutTime;

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService {
  private timeoutSubscription: Subscription = new Subscription();
  private _authHelper: AuthHelper = new AuthHelper(this._cookieService);
  private destroy$: Subject<void> = new Subject<void>();
  constructor(private _cookieService: CookieService) {
    this.captureEvent();
  }

  captureEvent() {
    const mousemove$ = fromEvent(document, 'mousemove');
    const mousedown$ = fromEvent(document, 'mousedown');
    const mouseup$ = fromEvent(document, 'mouseup');
    const click$ = fromEvent(document, 'click');
    const scroll$ = fromEvent(document, 'scroll');
    const keydown$ = fromEvent(document, 'keydown');
    const keypress$ = fromEvent(document, 'keypress');
    const keyup$ = fromEvent(document, 'keyup');

    // Merge all observables
    const activity$ = merge(
      mousemove$,
      mousedown$,
      mouseup$,
      click$,
      scroll$,
      keydown$,
      keypress$,
      keyup$
    );

    // Subscribe to activity and reset timeout
    activity$.pipe(debounceTime(400)).subscribe(() => this.resetTimeout());
  }

  resetTimeout() {
    // Clear existing timeout subscription
    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }

    this.timeoutSubscription = timer(TIMEOUT_DURATION)
      .pipe(takeUntil(this.destroy$))
      .subscribe((o) => {
        this._authHelper.logout();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
