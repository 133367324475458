/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { VendorImageEmailExclusionCountryDtoIEnumerableResponse } from '../../models/vendorImageEmailExclusionCountryDtoIEnumerableResponse';
import { StringStringDictionaryResponse } from '../../models/stringStringDictionaryResponse';
import { ConfigDtoIEnumerableResponse } from '../../models/configDtoIEnumerableResponse';

@Injectable()
export class ApplicationConfigService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * Returns all VendorImageEmailExclusionCountry records
   * Returns list of countries, for which sending of email, asking vendor to send image of damaged container is prohibited.
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public applicationConfigsExcludedVendorEmailsGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<VendorImageEmailExclusionCountryDtoIEnumerableResponse>;
  public applicationConfigsExcludedVendorEmailsGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<
    HttpResponse<VendorImageEmailExclusionCountryDtoIEnumerableResponse>
  >;
  public applicationConfigsExcludedVendorEmailsGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<
    HttpEvent<VendorImageEmailExclusionCountryDtoIEnumerableResponse>
  >;
  public applicationConfigsExcludedVendorEmailsGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    return this.httpClient.request<VendorImageEmailExclusionCountryDtoIEnumerableResponse>(
      'get',
      `${this.basePath}/application-configs/excluded-vendor-emails`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Returns all the configurations.
   * Fetches all the configuration from the database and returns as Enumerable of ConfigDto.
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public applicationConfigsGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ConfigDtoIEnumerableResponse>;
  public applicationConfigsGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ConfigDtoIEnumerableResponse>>;
  public applicationConfigsGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ConfigDtoIEnumerableResponse>>;
  public applicationConfigsGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ConfigDtoIEnumerableResponse>(
      'get',
      `${this.basePath}/application-configs`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
