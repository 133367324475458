<ng-container *ngIf="workOrders$ | async as workOrders">
  <ng-container *ngFor="let workOrder of workOrders; let i = index">
    <lib-panel>
      <header>WO-{{ workOrder.workOrderNumber }}</header>
      <left-corner-menu>
        <a
          (click)="
            showHideWorkOrderItemsLink(i, workOrder.workOrderLineItemDto)
          "
          class="header-link">
          {{ showWorkOrderItems[i] ? 'Hide line items' : 'View line items' }}
        </a>
      </left-corner-menu>
      <content>
        <app-lib-form [items]="workOrdersConverted[i]"></app-lib-form>
        <div
          [style.display]="showWorkOrderItems[i] ? 'block' : 'none'"
          class="work-order-line-items">
          <app-grid
            [schema]="lineItemsGridSchema!"
            [data]="lineItemsGridData[i]"
            [showPanel]="false"
            [showPagination]="false"
            [showRowSelector]="true"
            [rowSelectorType]="'checkbox'"
            [enableSorting]="false"></app-grid>
        </div>
      </content>
    </lib-panel>
  </ng-container>
</ng-container>
