[
  {
    "value": "AG",
    "label": "Antigua and Barbuda"
  },
  {
    "value": "AI",
    "label": "Anguilla"
  },
  {
    "value": "AL",
    "label": "Albania"
  },
  {
    "value": "AM",
    "label": "Armenia"
  },
  {
    "value": "AN",
    "label": "Netherlands Antilles"
  },
  {
    "value": "AO",
    "label": "Angola"
  },
  {
    "value": "AR",
    "label": "Argentina"
  },
  {
    "value": "AT",
    "label": "Austria"
  },
  {
    "value": "AU",
    "label": "Australia"
  },
  {
    "value": "AW",
    "label": "Aruba"
  },
  {
    "value": "AZ",
    "label": "Azerbaijan"
  },
  {
    "value": "BA",
    "label": "Bosnia and Herzegovina"
  },
  {
    "value": "BB",
    "label": "Barbados"
  },
  {
    "value": "BD",
    "label": "Bangladesh"
  },
  {
    "value": "BE",
    "label": "Belgium"
  },
  {
    "value": "BG",
    "label": "Bulgaria"
  },
  {
    "value": "BH",
    "label": "Bahrain"
  },
  {
    "value": "BM",
    "label": "Bermuda Island"
  },
  {
    "value": "BQ",
    "label": "Bonaire Sint Eustatius and Saba"
  },
  {
    "value": "BR",
    "label": "Brazil"
  },
  {
    "value": "BS",
    "label": "Bahamas"
  },
  {
    "value": "CH",
    "label": "Switzerland"
  },
  {
    "value": "CM",
    "label": "Cameroon"
  },
  {
    "value": "CN",
    "label": "China"
  },
  {
    "value": "CO",
    "label": "Colombia"
  },
  {
    "value": "CR",
    "label": "Costa Rica"
  },
  {
    "value": "CU",
    "label": "Cuba"
  },
  {
    "value": "CW",
    "label": "Curacao"
  },
  {
    "value": "CY",
    "label": "Cyprus"
  },
  {
    "value": "CZ",
    "label": "Czech Republic"
  },
  {
    "value": "DE",
    "label": "Germany"
  },
  {
    "value": "DJ",
    "label": "Djibouti"
  },
  {
    "value": "DK",
    "label": "Denmark"
  },
  {
    "value": "DM",
    "label": "Dominica"
  },
  {
    "value": "DO",
    "label": "Dominican Republic"
  },
  {
    "value": "EC",
    "label": "Ecuador"
  },
  {
    "value": "EE",
    "label": "Estonia"
  },
  {
    "value": "ES",
    "label": "Spain"
  },
  {
    "value": "ET",
    "label": "Ethiopia"
  },
  {
    "value": "FI",
    "label": "Finland"
  },
  {
    "value": "FO",
    "label": "Faroe Islands"
  },
  {
    "value": "FR",
    "label": "France"
  },
  {
    "value": "GB",
    "label": "United Kingdom"
  },
  {
    "value": "GD",
    "label": "Grenada"
  },
  {
    "value": "GE",
    "label": "Georgia"
  },
  {
    "value": "GH",
    "label": "Ghana"
  },
  {
    "value": "GP",
    "label": "Guadeloupe"
  },
  {
    "value": "GQ",
    "label": "Equatorial Guinea"
  },
  {
    "value": "GR",
    "label": "Greece"
  },
  {
    "value": "GT",
    "label": "Guatemala"
  },
  {
    "value": "GY",
    "label": "Guyana"
  },
  {
    "value": "HK",
    "label": "Hong Kong China"
  },
  {
    "value": "HN",
    "label": "Honduras"
  },
  {
    "value": "HR",
    "label": "Croatia"
  },
  {
    "value": "HT",
    "label": "Haiti"
  },
  {
    "value": "HU",
    "label": "Hungary"
  },
  {
    "value": "ID",
    "label": "Indonesia"
  },
  {
    "value": "IE",
    "label": "Ireland"
  },
  {
    "value": "IL",
    "label": "Israel"
  },
  {
    "value": "IN",
    "label": "India"
  },
  {
    "value": "IQ",
    "label": "Iraq"
  },
  {
    "value": "IS",
    "label": "Iceland"
  },
  {
    "value": "IT",
    "label": "Italy"
  },
  {
    "value": "JM",
    "label": "Jamaica"
  },
  {
    "value": "JO",
    "label": "Jordan"
  },
  {
    "value": "JP",
    "label": "Japan"
  },
  {
    "value": "KE",
    "label": "Kenya"
  },
  {
    "value": "KH",
    "label": "Cambodia"
  },
  {
    "value": "KN",
    "label": "St Kitts-Nevis"
  },
  {
    "value": "KR",
    "label": "Korea, South"
  },
  {
    "value": "KW",
    "label": "Kuwait"
  },
  {
    "value": "KY",
    "label": "Cayman Island"
  },
  {
    "value": "LC",
    "label": "St Lucia"
  },
  {
    "value": "LK",
    "label": "Sri Lanka"
  },
  {
    "value": "LT",
    "label": "Lithuania"
  },
  {
    "value": "LV",
    "label": "Latvia"
  },
  {
    "value": "MD",
    "label": "Moldova"
  },
  {
    "value": "ME",
    "label": "Montenegro"
  },
  {
    "value": "MK",
    "label": "Macedonia"
  },
  {
    "value": "MQ",
    "label": "Martinique"
  },
  {
    "value": "MS",
    "label": "Montserrat"
  },
  {
    "value": "MT",
    "label": "Malta"
  },
  {
    "value": "MV",
    "label": "Maldives"
  },
  {
    "value": "MX",
    "label": "Mexico"
  },
  {
    "value": "MY",
    "label": "Malaysia"
  },
  {
    "value": "NI",
    "label": "Nicaragua"
  },
  {
    "value": "NL",
    "label": "Netherlands"
  },
  {
    "value": "NO",
    "label": "Norway"
  },
  {
    "value": "NP",
    "label": "Nepal"
  },
  {
    "value": "NZ",
    "label": "New Zealand"
  },
  {
    "value": "PA",
    "label": "Panama"
  },
  {
    "value": "PL",
    "label": "Poland"
  },
  {
    "value": "PR",
    "label": "Puerto Rico"
  },
  {
    "value": "PT",
    "label": "Portugal"
  },
  {
    "value": "RE",
    "label": "Reunion"
  },
  {
    "value": "RO",
    "label": "Romania"
  },
  {
    "value": "RS",
    "label": "Serbia"
  },
  {
    "value": "RU",
    "label": "Russia"
  },
  {
    "value": "RW",
    "label": "Rwanda"
  },
  {
    "value": "SA",
    "label": "Saudi Arabia"
  },
  {
    "value": "SE",
    "label": "Sweden"
  },
  {
    "value": "SG",
    "label": "Singapore"
  },
  {
    "value": "SI",
    "label": "Slovenia"
  },
  {
    "value": "SK",
    "label": "Slovakia"
  },
  {
    "value": "SM",
    "label": "San Marino"
  },
  {
    "value": "SO",
    "label": "Somalia"
  },
  {
    "value": "SR",
    "label": "Suriname"
  },
  {
    "value": "SS",
    "label": "South Sudan"
  },
  {
    "value": "SV",
    "label": "El Salvador"
  },
  {
    "value": "SX",
    "label": "Sint Maarten"
  },
  {
    "value": "TC",
    "label": "Turks and Caicos"
  },
  {
    "value": "TD",
    "label": "Chad"
  },
  {
    "value": "TH",
    "label": "Thailand"
  },
  {
    "value": "TR",
    "label": "Turkey"
  },
  {
    "value": "TT",
    "label": "Trinidad and Tobago"
  },
  {
    "value": "TW",
    "label": "Taiwan China"
  },
  {
    "value": "UG",
    "label": "Uganda"
  },
  {
    "value": "UY",
    "label": "Uruguay"
  },
  {
    "value": "VC",
    "label": "Saint Vincent and the Grenadines"
  },
  {
    "value": "VE",
    "label": "Venezuela"
  },
  {
    "value": "VG",
    "label": "Virgin Island"
  },
  {
    "value": "VN",
    "label": "Vietnam"
  },
  {
    "value": "XK",
    "label": "Kosovo"
  },
  {
    "value": "AD",
    "label": "Andorra"
  },
  {
    "value": "AE",
    "label": "United Arab Emirates"
  },
  {
    "value": "AS",
    "label": "American Samoa"
  },
  {
    "value": "BF",
    "label": "Burkina Faso"
  },
  {
    "value": "BI",
    "label": "Burundi"
  },
  {
    "value": "BJ",
    "label": "Benin"
  },
  {
    "value": "BN",
    "label": "Brunei"
  },
  {
    "value": "BO",
    "label": "Bolivia"
  },
  {
    "value": "BW",
    "label": "Botswana"
  },
  {
    "value": "BZ",
    "label": "Belize"
  },
  {
    "value": "CA",
    "label": "Canada"
  },
  {
    "value": "CD",
    "label": "Congo, Dem. Rep. of"
  },
  {
    "value": "CF",
    "label": "Central African Republic"
  },
  {
    "value": "CI",
    "label": "Ivory Coast"
  },
  {
    "value": "CL",
    "label": "Chile"
  },
  {
    "value": "CV",
    "label": "Cape Verde Island"
  },
  {
    "value": "DZ",
    "label": "Algeria"
  },
  {
    "value": "EG",
    "label": "Egypt"
  },
  {
    "value": "FJ",
    "label": "Fiji Islands"
  },
  {
    "value": "GA",
    "label": "Gabon"
  },
  {
    "value": "GM",
    "label": "Gambia"
  },
  {
    "value": "GN",
    "label": "Guinea"
  },
  {
    "value": "LA",
    "label": "Laos"
  },
  {
    "value": "LB",
    "label": "Lebanon"
  },
  {
    "value": "LI",
    "label": "Liechtenstein"
  },
  {
    "value": "LR",
    "label": "Liberia"
  },
  {
    "value": "LS",
    "label": "Lesotho"
  },
  {
    "value": "LU",
    "label": "Luxemburg"
  },
  {
    "value": "LY",
    "label": "Libya"
  },
  {
    "value": "MA",
    "label": "Morocco"
  },
  {
    "value": "MG",
    "label": "Madagascar"
  },
  {
    "value": "ML",
    "label": "Mali"
  },
  {
    "value": "MM",
    "label": "Myanmar (Burma)"
  },
  {
    "value": "MN",
    "label": "Mongolia"
  },
  {
    "value": "MR",
    "label": "Mauritania"
  },
  {
    "value": "MU",
    "label": "Mauritius"
  },
  {
    "value": "MW",
    "label": "Malawi"
  },
  {
    "value": "MZ",
    "label": "Mozambique"
  },
  {
    "value": "NA",
    "label": "Namibia"
  },
  {
    "value": "NE",
    "label": "Niger"
  },
  {
    "value": "NG",
    "label": "Nigeria"
  },
  {
    "value": "OM",
    "label": "Oman"
  },
  {
    "value": "PE",
    "label": "Peru"
  },
  {
    "value": "PF",
    "label": "French Polynesia"
  },
  {
    "value": "PG",
    "label": "Papua New Guinea"
  },
  {
    "value": "PH",
    "label": "Philippines"
  },
  {
    "value": "PK",
    "label": "Pakistan"
  },
  {
    "value": "PY",
    "label": "Paraguay"
  },
  {
    "value": "QA",
    "label": "Qatar"
  },
  {
    "value": "SB",
    "label": "Solomon Islands"
  },
  {
    "value": "SC",
    "label": "Seychelles"
  },
  {
    "value": "SD",
    "label": "Sudan"
  },
  {
    "value": "SL",
    "label": "Sierra Leone"
  },
  {
    "value": "SN",
    "label": "Senegal"
  },
  {
    "value": "SY",
    "label": "Syria"
  },
  {
    "value": "SZ",
    "label": "Swaziland"
  },
  {
    "value": "TG",
    "label": "Togo"
  },
  {
    "value": "TL",
    "label": "Timor Leste"
  },
  {
    "value": "TN",
    "label": "Tunisia"
  },
  {
    "value": "TO",
    "label": "Tonga"
  },
  {
    "value": "TZ",
    "label": "Tanzania"
  },
  {
    "value": "UA",
    "label": "Ukraine"
  },
  {
    "value": "US",
    "label": "United States"
  },
  {
    "value": "VI",
    "label": "Virgin Islands (US)"
  },
  {
    "value": "WS",
    "label": "Samoa"
  },
  {
    "value": "YE",
    "label": "Yemen"
  },
  {
    "value": "ZA",
    "label": "South Africa"
  },
  {
    "value": "ZM",
    "label": "Zambia"
  },
  {
    "value": "ZW",
    "label": "Zimbabwe"
  }
]