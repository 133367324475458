import { Inject, Injectable, Optional } from '@angular/core';
import { StageDetailsRequest } from '../../models/stage-details-request';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { StageTemplateResponse } from '../../models/stage-template-response';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ResponseStatus } from '../../models/response-status';
import { MigrationRequest } from '../../models/migration-request';
import { TemplateRequestDTO } from '../../models/template-request';
import { StageTemplateResponseDTO } from '../../models/templates-reponse';
import { CompleteTaskRequestDTO } from '../../models/completeTaskRequestDTO';

@Injectable()
export class WorkflowService {
  protected basePath = environment.workflow_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}
  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   * Workflow stage
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public workflowStageDetailsByBusinessKey(
    body: StageDetailsRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<StageTemplateResponse>;
  public workflowStageDetailsByBusinessKey(
    body: StageDetailsRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<StageTemplateResponse>>;
  public workflowStageDetailsByBusinessKey(
    body: StageDetailsRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<StageTemplateResponse>>;
  public workflowStageDetailsByBusinessKey(
    body: StageDetailsRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling workflowStageDetailsByBusinessKey.'
      );
    }

    let headers = this.defaultHeaders;

    const access_token: string =
      localStorage.getItem(environment.iam_key + 'access_token') ?? '';
    // authentication (oauth2) required
    if (access_token) {
      const accessToken = localStorage.getItem(
        environment.iam_key + 'access_token'
      );
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<StageTemplateResponse>(
      'post',
      `${this.basePath}/api/Workflow/Stage`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Workflow get all templates
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public workflowGetAllTemplates(
    body: TemplateRequestDTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<StageTemplateResponseDTO>;
  public workflowGetAllTemplates(
    body: TemplateRequestDTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<StageTemplateResponseDTO>>;
  public workflowGetAllTemplates(
    body: TemplateRequestDTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<StageTemplateResponseDTO>>;
  public workflowGetAllTemplates(
    body: TemplateRequestDTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling workflowGetAllTemplates.'
      );
    }

    let headers = this.defaultHeaders;

    const access_token: string =
      localStorage.getItem(environment.iam_key + 'access_token') ?? '';
    // authentication (oauth2) required
    if (access_token) {
      const accessToken = localStorage.getItem(
        environment.iam_key + 'access_token'
      );
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<StageTemplateResponseDTO>(
      'post',
      `${this.basePath}/api/Workflow/GetAllTemplates`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Workflow Migrate
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public workflowProcessMigration(
    body: MigrationRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ResponseStatus>;
  public workflowProcessMigration(
    body: MigrationRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ResponseStatus>>;
  public workflowProcessMigration(
    body: MigrationRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ResponseStatus>>;
  public workflowProcessMigration(
    body: MigrationRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling workflowProcessMigration.'
      );
    }

    let headers = this.defaultHeaders;

    const access_token: string =
      localStorage.getItem(environment.iam_key + 'access_token') ?? '';
    // authentication (oauth2) required
    if (access_token) {
      const accessToken = localStorage.getItem(
        environment.iam_key + 'access_token'
      );
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<ResponseStatus>(
      'post',
      `${this.basePath}/api/Workflow/Migrate`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   * Workflow Stage complete task
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public workflowCompleteTask(
    body: CompleteTaskRequestDTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<StageTemplateResponseDTO>;
  public workflowCompleteTask(
    body: CompleteTaskRequestDTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<StageTemplateResponseDTO>>;
  public workflowCompleteTask(
    body: CompleteTaskRequestDTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<StageTemplateResponseDTO>>;
  public workflowCompleteTask(
    body: CompleteTaskRequestDTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling workflowProcessMigration.'
      );
    }

    let headers = this.defaultHeaders;

    const access_token: string =
      localStorage.getItem(environment.iam_key + 'access_token') ?? '';
    // authentication (oauth2) required
    if (access_token) {
      const accessToken = localStorage.getItem(
        environment.iam_key + 'access_token'
      );
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<StageTemplateResponseDTO>(
      'post',
      `${this.basePath}/api/Workflow/Stage/CompleteTask`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
