/**
 * DCRP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
  HttpParams,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { CustomerRecoveryRequest } from '../../models/customer-recovery-request';
import { environment } from '../../../../environments/environment';
import { ClaimStatusRequest } from '../../models/claim-status-request';
import { UserClaimsAssignmentRequest } from '../../models/user-claims-assignment-request';
import { AssignedUsersResponse } from '../../models/assignedUsersResponse';
import { GcssBookingInfoResponse } from '../../models/gcssBookingInforesponse';
import { InspectionResponse } from '../../models/inspection-response';
import { CaseDetailsDto } from '../../models/caseDetailsDto';
import { CaseActivityLogsDtoListResponse } from '../../models/caseActivityLogsDtoListResponse';
import { UserClaimsUnassignmentRequest } from '../../models/user-claims-unassignment-request';
import { CaseDetailsDtoResponse } from '../../models/caseDetailsDtoResponse';
import { BooleanResponse } from '../../models/booleanResponse';
import { CaseDamageDetailDtoListResponse } from '../../models/caseDamageDetailDtoListResponse';
import { CaseDamageDetailDto } from '../../models/caseDamageDetailDto';
import { CaseDamageDetailResponseResponse } from '../../models/caseDamageDetailSaveResponse';
import { GcssBookingPartyDtoListResponse } from '../../models/gcssBookingPartyDtoListResponse';
import { InspectionDetailsDTO } from '../../models/inspectionDetailsDTO';
import { LiablePartyDto } from '../../models/liabilePartyDto';
import { LiablePartyDtoResponse } from '../../models/liablePartyDtoResponse';
import { UpdateStatusResponse } from '../../models/updateStatusResponse';
import { LiabilityLetterDtoListResponse } from '../../models/liabilityLetterDtoListResponse';
import { UpdateLiabilityLetterStatus } from '../../models/updateLiabilityLetterStatus';
import { InvoicingStatusResponse } from '../../models/invoicingStatusResponse';
import { CaseAssignmentStatusResponse } from '../../models/caseAssignmentStatusResponse';
import { CaseInvoiceDetailPostDto } from '../../models/caseInvoiceDetailPostDto';
import { CaseInvoiceDetailDtoListResponse } from '../../models/caseInvoiceDetailDtoListResponse';
import { CancelInvoicePostDto } from '../../models/cancelInvoicePostDto';

@Injectable()
export class CustomerRecoveryClaimService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseAssignmentPost(
    body: UserClaimsAssignmentRequest,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseAssignmentStatusResponse>;
  public customerRecoveryClaimsCaseAssignmentPost(
    body: UserClaimsAssignmentRequest,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseAssignmentStatusResponse>>;
  public customerRecoveryClaimsCaseAssignmentPost(
    body: UserClaimsAssignmentRequest,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseAssignmentStatusResponse>>;
  public customerRecoveryClaimsCaseAssignmentPost(
    body: UserClaimsAssignmentRequest,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<CaseAssignmentStatusResponse>(
      'post',
      `${this.basePath}/customer-recovery-claims/case-assignment`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * @param body - case unassignment request
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseUnassignmentPost(
    body: UserClaimsUnassignmentRequest,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public customerRecoveryClaimsCaseUnassignmentPost(
    body: UserClaimsUnassignmentRequest,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public customerRecoveryClaimsCaseUnassignmentPost(
    body: UserClaimsUnassignmentRequest,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public customerRecoveryClaimsCaseUnassignmentPost(
    body: UserClaimsUnassignmentRequest,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<UserClaimsUnassignmentRequest>(
      'post',
      `${this.basePath}/customer-recovery-claims/case-unassignment`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsClaimscountPost(
    body?: CustomerRecoveryRequest,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public customerRecoveryClaimsClaimscountPost(
    body?: CustomerRecoveryRequest,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public customerRecoveryClaimsClaimscountPost(
    body?: CustomerRecoveryRequest,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public customerRecoveryClaimsClaimscountPost(
    body?: CustomerRecoveryRequest,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/customer-recovery-claims/count`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsListPost(
    body?: CustomerRecoveryRequest,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public customerRecoveryClaimsListPost(
    body?: CustomerRecoveryRequest,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public customerRecoveryClaimsListPost(
    body?: CustomerRecoveryRequest,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public customerRecoveryClaimsListPost(
    body?: CustomerRecoveryRequest,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/customer-recovery-claims/list`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param caseNumber
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsClaimStatusCaseNumberPut(
    body: ClaimStatusRequest,
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public customerRecoveryClaimsClaimStatusCaseNumberPut(
    body: ClaimStatusRequest,
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public customerRecoveryClaimsClaimStatusCaseNumberPut(
    body: ClaimStatusRequest,
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public customerRecoveryClaimsClaimStatusCaseNumberPut(
    body: ClaimStatusRequest,
    caseNumber: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling customerRecoveryClaimsClaimStatusCaseNumberPut.'
      );
    }

    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling customerRecoveryClaimsClaimStatusCaseNumberPut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<ClaimStatusRequest>(
      'put',
      `${this.basePath}/customer-recovery-claims/claim-status/${encodeURIComponent(String(caseNumber))}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param viewname
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsListByGroupIdPost(
    body?: string[],
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public customerRecoveryClaimsListByGroupIdPost(
    body?: string[],
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public customerRecoveryClaimsListByGroupIdPost(
    body?: string[],
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public customerRecoveryClaimsListByGroupIdPost(
    body?: string[],
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (!body) {
      throw new Error(
        'Required parameter groupIds was null or undefined when calling customerRecoveryClaimsListByGroupIdGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<any>(
      'post',
      `${this.basePath}/customer-recovery-claims/list-by-groupid`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param caseNumber
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseNumberInspectionsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InspectionResponse>;
  public customerRecoveryClaimsCaseNumberInspectionsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InspectionResponse>>;
  public customerRecoveryClaimsCaseNumberInspectionsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InspectionResponse>>;
  public customerRecoveryClaimsCaseNumberInspectionsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling customerRecoveryClaimsCaseNumberInspectionsGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // authentication (Bearer) required
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<InspectionResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseNumber))}/inspections`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update image availability
   *
   * @param body
   * @param caseNumber
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseNumberImageAvailabilityPut(
    body: InspectionDetailsDTO,
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public customerRecoveryClaimsCaseNumberImageAvailabilityPut(
    body: InspectionDetailsDTO,
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public customerRecoveryClaimsCaseNumberImageAvailabilityPut(
    body: InspectionDetailsDTO,
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public customerRecoveryClaimsCaseNumberImageAvailabilityPut(
    body: InspectionDetailsDTO,
    caseNumber: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling customerRecoveryClaimsCaseNumberImageAvailabilityPut.'
      );
    }

    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling customerRecoveryClaimsCaseNumberImageAvailabilityPut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'put',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(
        String(caseNumber)
      )}/eir-image-availability`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param caseNumber
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseNumberBookingDetailsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GcssBookingInfoResponse>;
  public customerRecoveryClaimsCaseNumberBookingDetailsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GcssBookingInfoResponse>>;
  public customerRecoveryClaimsCaseNumberBookingDetailsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GcssBookingInfoResponse>>;
  public customerRecoveryClaimsCaseNumberBookingDetailsGet(
    caseNumber: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling customerRecoveryClaimsCaseNumberBookingDetailsGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // authentication (Bearer) required
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<GcssBookingInfoResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseNumber))}/booking-details`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsAssignedUsersGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<AssignedUsersResponse>;
  public customerRecoveryClaimsAssignedUsersGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<AssignedUsersResponse>>;
  public customerRecoveryClaimsAssignedUsersGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<AssignedUsersResponse>>;
  public customerRecoveryClaimsAssignedUsersGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);
    return this.httpClient.request<AssignedUsersResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/assigned-users`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get claim detail by recovery case number
   * Possible Exceptions: DCRPInternalServerException, DCRPBadRequestException.
   * @param caseNumber Recovery case number
   * @param containerNumber
   * @param fromDateTime
   * @param toDateTime
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseNumberGet(
    caseNumber: string,
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseDetailsDtoResponse>;
  public customerRecoveryClaimsCaseNumberGet(
    caseNumber: string,
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseDetailsDtoResponse>>;
  public customerRecoveryClaimsCaseNumberGet(
    caseNumber: string,
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseDetailsDtoResponse>>;
  public customerRecoveryClaimsCaseNumberGet(
    caseNumber: string,
    containerNumber: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling customerRecoveryClaimsCaseNumberGet.'
      );
    }

    if (containerNumber === null || containerNumber === undefined) {
      throw new Error(
        'Required parameter containerNumber was null or undefined when calling customerRecoveryClaimsCaseNumberGet.'
      );
    }

    let queryParameters = new HttpParams();
    if (containerNumber !== undefined && containerNumber !== null) {
      queryParameters = queryParameters.set(
        'ContainerNumber',
        <any>containerNumber
      );
    }
    if (fromDateTime !== undefined && fromDateTime !== null) {
      queryParameters = queryParameters.set(
        'FromDateTime',
        <any>this.getDateStringFromDate(fromDateTime)
      );
    }
    if (toDateTime !== undefined && toDateTime !== null) {
      queryParameters = queryParameters.set(
        'ToDateTime',
        <any>this.getDateStringFromDate(toDateTime)
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);
    return this.httpClient.request<CaseDetailsDtoResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseNumber))}`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update Claim detail and add document/images.
   *
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsUpdatePost(
    body: CaseDetailsDto,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public customerRecoveryClaimsUpdatePost(
    body: CaseDetailsDto,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public customerRecoveryClaimsUpdatePost(
    body: CaseDetailsDto,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public customerRecoveryClaimsUpdatePost(
    body: CaseDetailsDto,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'put',
      `${this.basePath}/customer-recovery-claims`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Gets the case activity logs
   *
   * @param caseId
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsActivityLogsCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseActivityLogsDtoListResponse>;
  public customerRecoveryClaimsActivityLogsCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseActivityLogsDtoListResponse>>;
  public customerRecoveryClaimsActivityLogsCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseActivityLogsDtoListResponse>>;
  public customerRecoveryClaimsActivityLogsCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    return this.httpClient.request<CaseActivityLogsDtoListResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/activity-logs/${encodeURIComponent(String(caseId))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  /**
   * Gets the damage details logs
   *
   * @param caseId
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsDamageDetailCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseDamageDetailDtoListResponse>;
  public customerRecoveryClaimsDamageDetailCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseDamageDetailDtoListResponse>>;
  public customerRecoveryClaimsDamageDetailCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseDamageDetailDtoListResponse>>;
  public customerRecoveryClaimsDamageDetailCaseIdGet(
    caseId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsDamageDetailCaseIdGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // authentication (Bearer) required
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<CaseDamageDetailDtoListResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/damage-details/${encodeURIComponent(String(caseId))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update Claim detail and add/delete damage detail.
   *
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsDamageDetailsSavePost(
    body?: Array<CaseDamageDetailDto>,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseDamageDetailResponseResponse>;
  public customerRecoveryClaimsDamageDetailsSavePost(
    body?: Array<CaseDamageDetailDto>,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseDamageDetailResponseResponse>>;
  public customerRecoveryClaimsDamageDetailsSavePost(
    body?: Array<CaseDamageDetailDto>,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseDamageDetailResponseResponse>>;
  public customerRecoveryClaimsDamageDetailsSavePost(
    body?: Array<CaseDamageDetailDto>,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // authentication (Bearer) required
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);
    return this.httpClient.request<CaseDamageDetailResponseResponse>(
      'post',
      `${this.basePath}/customer-recovery-claims/damage-details`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param caseId
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdPartiesGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GcssBookingPartyDtoListResponse>;
  public customerRecoveryClaimsCaseIdPartiesGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GcssBookingPartyDtoListResponse>>;
  public customerRecoveryClaimsCaseIdPartiesGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GcssBookingPartyDtoListResponse>>;
  public customerRecoveryClaimsCaseIdPartiesGet(
    caseNumber: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdPartiesGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // authentication (Bearer) required
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<GcssBookingPartyDtoListResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseNumber))}/parties`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update booking number for case
   *
   * @param caseId
   * @param bookingNumber
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsBookingNumberUpdatePost(
    caseNumber: string,
    bookingNumber: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public customerRecoveryClaimsBookingNumberUpdatePost(
    caseNumber: string,
    bookingNumber: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public customerRecoveryClaimsBookingNumberUpdatePost(
    caseNumber: string,
    bookingNumber: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public customerRecoveryClaimsBookingNumberUpdatePost(
    caseNumber: string,
    bookingNumber: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsBookingNumberUpdatePost.'
      );
    }

    if (bookingNumber === null || bookingNumber === undefined) {
      throw new Error(
        'Required parameter bookingNumber was null or undefined when calling customerRecoveryClaimsBookingNumberUpdatePost.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // authentication (Bearer) required
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'put',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseNumber))}/bookings/${encodeURIComponent(String(bookingNumber))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  /**
   *
   *
   * @param caseNumber
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseNumberLiablePartyGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LiablePartyDtoResponse>;
  public customerRecoveryClaimsCaseNumberLiablePartyGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LiablePartyDtoResponse>>;
  public customerRecoveryClaimsCaseNumberLiablePartyGet(
    caseNumber: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LiablePartyDtoResponse>>;
  public customerRecoveryClaimsCaseNumberLiablePartyGet(
    caseNumber: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling customerRecoveryClaimsCaseNumberInspectionsGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // authentication (Bearer) required
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<LiablePartyDtoResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseNumber))}/liable-party`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param caseNumber
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseNumberLiablePartyPost(
    caseNumber: string,
    body?: LiablePartyDto,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public customerRecoveryClaimsCaseNumberLiablePartyPost(
    caseNumber: string,
    body?: LiablePartyDto,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public customerRecoveryClaimsCaseNumberLiablePartyPost(
    caseNumber: string,
    body?: LiablePartyDto,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public customerRecoveryClaimsCaseNumberLiablePartyPost(
    caseNumber: string,
    body?: LiablePartyDto,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseNumber === null || caseNumber === undefined) {
      throw new Error(
        'Required parameter caseNumber was null or undefined when calling customerRecoveryClaimsClaimStatusCaseNumberPut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<UpdateStatusResponse>(
      'post',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseNumber))}/liable-party`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param caseId
   * @param liabilityLetterId
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdLiabilityLetterLiabilityLetterIdPut(
    caseId: number,
    liabilityLetterId: number,
    body?: UpdateLiabilityLetterStatus,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public customerRecoveryClaimsCaseIdLiabilityLetterLiabilityLetterIdPut(
    caseId: number,
    liabilityLetterId: number,
    body?: UpdateLiabilityLetterStatus,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public customerRecoveryClaimsCaseIdLiabilityLetterLiabilityLetterIdPut(
    caseId: number,
    liabilityLetterId: number,
    body?: UpdateLiabilityLetterStatus,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public customerRecoveryClaimsCaseIdLiabilityLetterLiabilityLetterIdPut(
    caseId: number,
    liabilityLetterId: number,
    body?: UpdateLiabilityLetterStatus,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdLiabilityLetterLiabilityLetterIdPut.'
      );
    }

    if (liabilityLetterId === null || liabilityLetterId === undefined) {
      throw new Error(
        'Required parameter liabilityLetterId was null or undefined when calling customerRecoveryClaimsCaseIdLiabilityLetterLiabilityLetterIdPut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'put',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseId))}/liability-letter/${encodeURIComponent(String(liabilityLetterId))}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param caseId
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdLiabilityLettersGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LiabilityLetterDtoListResponse>;
  public customerRecoveryClaimsCaseIdLiabilityLettersGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LiabilityLetterDtoListResponse>>;
  public customerRecoveryClaimsCaseIdLiabilityLettersGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LiabilityLetterDtoListResponse>>;
  public customerRecoveryClaimsCaseIdLiabilityLettersGet(
    caseId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdLiabilityLettersGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<LiabilityLetterDtoListResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseId))}/liability-letters`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Checks if the recovery case is ready to invoice after it has sent liability letter with status No Response
   *
   * @param caseId
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdIsReadyToInvoiceGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InvoicingStatusResponse>;
  public customerRecoveryClaimsCaseIdIsReadyToInvoiceGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InvoicingStatusResponse>>;
  public customerRecoveryClaimsCaseIdIsReadyToInvoiceGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InvoicingStatusResponse>>;
  public customerRecoveryClaimsCaseIdIsReadyToInvoiceGet(
    caseId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdIsReadyToInvoiceGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    return this.httpClient.request<InvoicingStatusResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseId))}/invoice-status`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Cancels an invoice for a specific case.
   * This API allows cancelling an invoice associated with a case identified by caseId   and caseInvoiceDetailId. The cancellation reason and user initiating the action   are provided in the request body (cancelRequest).
   * @param body The cancellation request details including reason and user.
   * @param caseId The ID of the case for which the invoice is to be cancelled.
   * @param caseInvoiceDetailId The ID of the specific invoice detail to be cancelled.
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdCancelInvoiceCaseInvoiceDetailIdPut(
    body: CancelInvoicePostDto,
    caseId: number,
    caseInvoiceDetailId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public customerRecoveryClaimsCaseIdCancelInvoiceCaseInvoiceDetailIdPut(
    body: CancelInvoicePostDto,
    caseId: number,
    caseInvoiceDetailId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public customerRecoveryClaimsCaseIdCancelInvoiceCaseInvoiceDetailIdPut(
    body: CancelInvoicePostDto,
    caseId: number,
    caseInvoiceDetailId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public customerRecoveryClaimsCaseIdCancelInvoiceCaseInvoiceDetailIdPut(
    body: CancelInvoicePostDto,
    caseId: number,
    caseInvoiceDetailId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling customerRecoveryClaimsCaseIdCancelInvoiceCaseInvoiceDetailIdPut.'
      );
    }

    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdCancelInvoiceCaseInvoiceDetailIdPut.'
      );
    }

    if (caseInvoiceDetailId === null || caseInvoiceDetailId === undefined) {
      throw new Error(
        'Required parameter caseInvoiceDetailId was null or undefined when calling customerRecoveryClaimsCaseIdCancelInvoiceCaseInvoiceDetailIdPut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'put',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseId))}/cancel-invoice/${encodeURIComponent(String(caseInvoiceDetailId))}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Retrieves invoice details for a specific case.
   * This API retrieves all invoice details associated with a case identified by caseId.
   * @param caseId The ID of the case for which invoice details are to be retrieved.
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdInvoiceDetailsGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseInvoiceDetailDtoListResponse>;
  public customerRecoveryClaimsCaseIdInvoiceDetailsGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseInvoiceDetailDtoListResponse>>;
  public customerRecoveryClaimsCaseIdInvoiceDetailsGet(
    caseId: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseInvoiceDetailDtoListResponse>>;
  public customerRecoveryClaimsCaseIdInvoiceDetailsGet(
    caseId: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdInvoiceDetailsGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<CaseInvoiceDetailDtoListResponse>(
      'get',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseId))}/invoice-details`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Adds invoice details for a specific case.
   * This API allows adding invoice details for a case identified by caseId.  The details of the invoice are provided in the request body (caseInvoiceDetail).
   * @param caseId The ID of the case to which the invoice details will be added.
   * @param body The details of the invoice to be added.
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerRecoveryClaimsCaseIdInvoiceDetailsPost(
    caseId: number,
    body?: CaseInvoiceDetailPostDto,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public customerRecoveryClaimsCaseIdInvoiceDetailsPost(
    caseId: number,
    body?: CaseInvoiceDetailPostDto,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public customerRecoveryClaimsCaseIdInvoiceDetailsPost(
    caseId: number,
    body?: CaseInvoiceDetailPostDto,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public customerRecoveryClaimsCaseIdInvoiceDetailsPost(
    caseId: number,
    body?: CaseInvoiceDetailPostDto,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (caseId === null || caseId === undefined) {
      throw new Error(
        'Required parameter caseId was null or undefined when calling customerRecoveryClaimsCaseIdInvoiceDetailsPost.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'post',
      `${this.basePath}/customer-recovery-claims/${encodeURIComponent(String(caseId))}/invoice-details`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  getDateStringFromDate(date: Date) {
    // Extract day, month, and year from the date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    // Concatenate day, month, and year with '/' separator
    const dateString = `${year}/${month}/${day}`;

    return dateString;
  }
}
