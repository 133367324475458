import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowService } from '../../../common/services/customer-recovery/workflow.service';
import { map, Observable, tap } from 'rxjs';
import { AppWorkflowFormComponent } from '../../custom-workflow/workflow-form/app-workflow-form.component';
import { CommonModule } from '@angular/common';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CustomerRecoveryWorkflowDataManager } from './customer-recovery-workflow-data-manager';
import { GlobalService } from '../../../global-service';
import { WorkOrderAndLineItemsService } from '../../../common/services/customer-recovery/work-order-and-line-items.service';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { PageSkeletonLoaderComponent } from '@maersk-global/angular-shared-library';
import { recoveryTab } from '../../../common/enum/recovery-tab';

@Component({
  selector: 'app-customer-recovery-workflow',
  standalone: true,
  imports: [
    AppWorkflowFormComponent,
    CommonModule,
    PageSkeletonLoaderComponent,
  ],
  templateUrl: './customer-recovery-workflow.component.html',
  styleUrl: './customer-recovery-workflow.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CustomerRecoveryClaimService, WorkOrderAndLineItemsService],
})
export class CustomerRecoveryWorkflowComponent {
  caseDetails$?: Observable<CustomerRecoveryCaseDto | undefined>;
  stages: TemplateModel[] = [];
  anchorTaskId$?: Observable<string>;
  currentStageId$?: Observable<number>;
  anchorStageId$?: Observable<number>;
  recoveryWorkFlowDataManger: CustomerRecoveryWorkflowDataManager;
  tabName$: Observable<string> = this.globalService.currentTabIndex$.pipe(
    map((index) => recoveryTab[index])
  );

  constructor(
    route: ActivatedRoute,
    private router: Router,
    workflowService: WorkflowService,
    customerRecoveryClaimService: CustomerRecoveryClaimService,
    private globalService: GlobalService
  ) {
    this.recoveryWorkFlowDataManger = new CustomerRecoveryWorkflowDataManager(
      workflowService,
      customerRecoveryClaimService,
      route,
      router,
      globalService
    );

    this.currentStageId$ =
      this.recoveryWorkFlowDataManger.recoveryStatusId$?.pipe(
        tap((_) => {
          this.stages = this.recoveryWorkFlowDataManger.stages;
        })
      );
    this.caseDetails$ = this.globalService.caseDetails$;
  }

  onNavigateBackToCaseList() {
    this.router.navigate(['customer-recovery']);
  }

  stageMovedToNextOrBack(refreshAnchorNeeded: boolean) {
    if (refreshAnchorNeeded)
      this.recoveryWorkFlowDataManger.refreshAnchorData();
  }
}
