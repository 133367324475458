import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  interval,
  map,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { CustomerRecoveryCaseDto } from './common/models/customerRecoveryCaseDto';
import { CommonService } from './common/services/customer-recovery/common.service';
import { CountryDto } from './common/models/countryDto';
import { CarrierCodeDto } from './common/models/carrierCodeDto';
import { ApplicationConfigService } from './common/services/common/applicationConfig.service';
import { VendorImageEmailExclusionCountryDto } from './common/models/vendorImageEmailExclusionCountryDto';
import { CustomerRecoveryClaimService } from './common/services/customer-recovery/customer-recovery-claim.service';
import { LiabilityLetterDto } from './common/models/liabilityLetterDto';
import { ConfigDto } from './common/models/configDto';
import { InvoicingStatus } from './common/models/invoicingStatus';
import { CustomerInfoDto } from './common/models/customerInfoDto';
import { LiablePartyDto } from './common/models/liabilePartyDto';
import { gcssBookingInfo } from './common/models/gcssBookingInfo';
import { CaseDamageDetailDto } from './common/models/caseDamageDetailDto';
import { CaseInvoiceDetailDto } from './common/models/caseInvoiceDetailDto';
import { CollectionOfficeDtoIEnumerableResponse } from './common/models/collectionOfficeDtoIEnumerableResponse';
import { CollectionOfficeDto } from './common/models/collectionOfficeDto';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { WorkflowService } from './common/services/customer-recovery/workflow.service';
import { DCRP_WORKFLOW } from './common/constants/app.constants';
import { StageTemplateResponse } from './common/models/stage-template-response';
import { workflowStages } from './components/customer-recovery/temporary-constant';
import { Region } from './common/models/region';
import { RegionIEnumerableResponse } from './common/models/regionIEnumerableResponse';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private apiVersion: string = '1.0';
  constructor(
    private route: ActivatedRoute,
    private _commonService: CommonService,
    private _applicationConfigService: ApplicationConfigService,
    private _customerRecoveryClaimService: CustomerRecoveryClaimService,
    private _workflowService: WorkflowService
  ) {}

  private customerRecoveryDataSubject$$: BehaviorSubject<CustomerRecoveryCaseDto> =
    new BehaviorSubject<CustomerRecoveryCaseDto>({});

  private customerDataSubject$$: BehaviorSubject<CustomerInfoDto> =
    new BehaviorSubject<CustomerInfoDto>({});

  private shouldShowLiabilityDetailsSubject$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private shouldReloadLiabilityLetters$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private shouldShowIssueInvoiceScreenSubject$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private shouldReloadInvoices$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private bookingNumber$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  private tabIndex$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private shouldReloadDamageDetails$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private shouldReloadLiabilityPartyDetails$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private currentStageId$$: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);
  /**
   * This observable holds the current selected customerRecoveryCaseDetails.
   * We share the same response to its observables until changed.
   */
  customerRecoveryData$: Observable<CustomerRecoveryCaseDto> =
    this.customerRecoveryDataSubject$$.asObservable().pipe(shareReplay(1));

  /**
   * This observable holds the current selected customer data.
   * We share the same response to its observables until changed.
   */
  customerData$: Observable<CustomerInfoDto> = this.customerDataSubject$$
    .asObservable()
    .pipe(shareReplay(1));

  refreshAnchorFlowData$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private anchorTaskIdLoaded$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private anchorStageId$$: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);

  anchorStageId$: Observable<number> = this.anchorStageId$$.asObservable();

  currentStageId$: Observable<number> = this.currentStageId$$.asObservable();

  anchorTaskIdLoaded$: Observable<boolean> =
    this.anchorTaskIdLoaded$$.asObservable();

  /**
   * This observable holds the current selected liability party data
   * We share the same response to its observables until changed.
   */
  LiablePartyData$: Observable<LiablePartyDto | undefined> = combineLatest([
    this.customerRecoveryData$,
    this.shouldReloadLiabilityPartyDetails$$.asObservable(),
  ]).pipe(
    switchMap(([customerRecoveryData]) => {
      return this._customerRecoveryClaimService
        .customerRecoveryClaimsCaseNumberLiablePartyGet(
          customerRecoveryData.recoveryCaseNumber ?? '',
          this.apiVersion
        )
        .pipe(map((response) => response as LiablePartyDto));
    }),
    shareReplay(1)
  );

  countries$: Observable<CountryDto[]> = this._commonService
    .commonCountriesGet(this.apiVersion)
    .pipe(shareReplay(1));

  carrierCodes$: Observable<CarrierCodeDto[]> = this._commonService
    .commonCarrierCodesGet(this.apiVersion)
    .pipe(shareReplay(1));

  countryClusters$: Observable<CountryDto[]> = this._commonService
    .commonCountryClustersGet(this.apiVersion)
    .pipe(shareReplay(1));

  regions$: Observable<Region[] | undefined> = this._commonService
    .commonRegionsGet(this.apiVersion)
    .pipe(
      map((response: RegionIEnumerableResponse) => {
        if (!response || !response.isSuccess || !response.data)
          return undefined;
        return response.data;
      }),
      shareReplay(1)
    );

  currencyAndExchangeRates$: Observable<CountryDto[]> = this._commonService
    .commonCurrencyAndExchangeRatesGet(this.apiVersion)
    .pipe(shareReplay(1));

  commonCollectionOffices$: Observable<CollectionOfficeDto[] | undefined> =
    this._commonService.commonCollectionOfficeGet().pipe(
      map((response: CollectionOfficeDtoIEnumerableResponse) => {
        if (!response || !response.isSuccess || !response.data)
          return undefined;
        return response.data;
      }),
      tap((res) => {
        console.log(`all offices`);
        console.log(res);
      }),
      shareReplay(1)
    );

  vendorEmailExclusionCountries$: Observable<
    Array<VendorImageEmailExclusionCountryDto> | undefined
  > = this._applicationConfigService
    .applicationConfigsExcludedVendorEmailsGet(this.apiVersion)
    .pipe(
      map((countriesExcluded) => countriesExcluded.data),
      shareReplay(1)
    );

  appConfig$: Observable<Array<ConfigDto> | undefined> =
    this._applicationConfigService.applicationConfigsGet(this.apiVersion).pipe(
      map((response) => response.data),
      shareReplay(1)
    );

  liabilityLetters$: Observable<Array<LiabilityLetterDto> | undefined> =
    combineLatest([
      this.customerRecoveryData$,
      this.shouldReloadLiabilityLetters$$.asObservable(),
    ]).pipe(
      switchMap(([customerRecoveryCase]) => {
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsCaseIdLiabilityLettersGet(
            customerRecoveryCase.caseId ?? 0,
            this.apiVersion
          )
          .pipe(map((response) => response.data));
      }),
      shareReplay(1)
    );

  shouldShowLiabilityDetails$: Observable<boolean> = combineLatest([
    this.shouldShowLiabilityDetailsSubject$$.asObservable(),
    this.liabilityLetters$,
  ]).pipe(
    map(
      ([shouldShowLiabilityDetails, liabilityLetters]) =>
        shouldShowLiabilityDetails ||
        !liabilityLetters ||
        liabilityLetters.length === 0
    ),
    shareReplay(1)
  );

  invoices$: Observable<Array<CaseInvoiceDetailDto> | undefined> =
    combineLatest([
      this.customerRecoveryData$,
      this.shouldReloadInvoices$$.asObservable(),
    ]).pipe(
      switchMap(([customerRecoveryCase]) => {
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsCaseIdInvoiceDetailsGet(
            customerRecoveryCase.caseId ?? 0,
            this.apiVersion
          )
          .pipe(map((response) => response.data));
      })
      //shareReplay(1)
    );

  shouldShowIssueInvoiceScreen$: Observable<boolean> = combineLatest([
    this.shouldShowIssueInvoiceScreenSubject$$.asObservable(),
    this.invoices$,
    this.countries$,
    this.customerRecoveryData$,
  ]).pipe(
    map(
      ([
        shouldShowIssueInvoiceScreen,
        invoices,
        countries,
        customerRecoveryCase,
      ]) =>
        (shouldShowIssueInvoiceScreen || !invoices || invoices.length === 0) &&
        !!countries.find(
          (country) =>
            country.code === customerRecoveryCase.podCountryCode &&
            country.isS4HanaMigrated
        )
    ),
    shareReplay(1)
  );

  caseDetails$: Observable<CustomerRecoveryCaseDto | undefined> =
    this.route.queryParamMap.pipe(
      switchMap((params: ParamMap) => {
        const caseNumber = params.get('caseNumber') as string;
        const containerNumber = params.get('containerNumber') as string;
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsCaseNumberGet(caseNumber, containerNumber)
          .pipe(map((response) => response.data?.customerRecoveryCaseDto));
      }),
      tap((recoveryData) => {
        if (!recoveryData) return undefined;
        this.updateCustomerRecoveryData(
          recoveryData as CustomerRecoveryCaseDto
        );
        const currentStageId =
          recoveryData?.recoveryStatusId == 6 ||
          recoveryData?.recoveryStatusId == 7
            ? !recoveryData.workFlowStage
              ? 1
              : workflowStages[recoveryData?.workFlowStage]
            : recoveryData?.recoveryStatusId;
        this.updateCurrentStageId(currentStageId as number);
      }),

      shareReplay(1)
    );

  anchorFlowTaskId$: Observable<string> = combineLatest([
    this.route.queryParamMap,
    this.refreshAnchorFlowData$$.asObservable(),
  ]).pipe(
    switchMap(([params]) => {
      const caseNumber = params.get('caseNumber') as string;
      const req = {
        businessKey: DCRP_WORKFLOW + '_' + caseNumber,
        workflowType: DCRP_WORKFLOW,
        requireTemplate: false,
        templateVersion: '',
      };
      return this._workflowService.workflowStageDetailsByBusinessKey(req);
    }),
    tap((anchorData) => {
      this.anchorStageId$$.next(
        anchorData?.stageVariables?.CurrentStage?.value
      );
      this.anchorTaskIdLoaded$$.next(true);
    }),
    map((anchorData) => anchorData?.id ?? '')
  );

  private invoiceCreationPermissionInitial$: Observable<InvoicingStatus> =
    combineLatest([this.customerRecoveryData$, this.liabilityLetters$]).pipe(
      switchMap(([customerRecoveryCase, letters]) => {
        if (
          !letters ||
          letters.length === 0 ||
          !customerRecoveryCase.caseId ||
          !letters.find(
            (letter) =>
              letter.liabilityLetterStatus === 'ACCEPTED' ||
              letter.liabilityLetterStatus === 'NO_RESPONSE'
          )
        )
          return of({
            totalSecondsRemainingToInvoice: 0,
            isReadyToInvoice: false,
          });
        return this._customerRecoveryClaimService
          .customerRecoveryClaimsCaseIdIsReadyToInvoiceGet(
            customerRecoveryCase.caseId
          )
          .pipe(map((response) => response.data!));
      }),
      shareReplay(1)
    );

  invoiceCreationPermission$: Observable<
    InvoicingStatus & { displayMessage: string }
  > = combineLatest([
    this.invoiceCreationPermissionInitial$,
    this.invoiceCreationPermissionInitial$.pipe(
      switchMap((caseInvoiceStatus) => {
        return interval(1000).pipe(
          take((caseInvoiceStatus.totalSecondsRemainingToInvoice ?? 1) + 1)
        );
      })
    ),
  ]).pipe(
    map(([caseInvoiceStatus, seconds]) => {
      const displayMessage = this.convertSecondsToDayHourMinSec(
        (caseInvoiceStatus.totalSecondsRemainingToInvoice ?? 1) - seconds
      );
      if (
        !caseInvoiceStatus.isReadyToInvoice &&
        seconds < (caseInvoiceStatus.totalSecondsRemainingToInvoice ?? 1)
      )
        return {
          totalSecondsRemainingToInvoice:
            caseInvoiceStatus.totalSecondsRemainingToInvoice,
          isReadyToInvoice: caseInvoiceStatus.isReadyToInvoice,
          displayMessage: displayMessage,
        };
      else
        return {
          totalSecondsRemainingToInvoice: 0,
          isReadyToInvoice:
            caseInvoiceStatus.isReadyToInvoice ||
            caseInvoiceStatus.totalSecondsRemainingToInvoice !== 0,
          displayMessage: displayMessage,
        };
    })
  );

  private enableNextBtnFormValidation$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  updateNextButtonStateOnFormValidation(enable: boolean) {
    this.enableNextBtnFormValidation$$.next(enable);
  }

  enableCloseBtn$: Observable<boolean> = combineLatest([
    this.customerRecoveryData$,
    this.currentStageId$,
    this.invoices$,
  ]).pipe(
    map(
      ([recoveryData, stageId, invoices]) =>
        !(
          recoveryData.recoveryStatusId == 6 ||
          recoveryData.recoveryStatusId == 7 ||
          stageId == 5 ||
          (invoices && invoices.length > 0)
        )
    )
  );

  reopenCase$: Observable<boolean> = this.customerRecoveryData$.pipe(
    map((recoveryData) => {
      if (recoveryData.recoveryStatusId == 6) return true;
      return false;
    })
  );

  disableForm$: Observable<boolean> = combineLatest([
    this.liabilityLetters$,
    this.customerRecoveryData$,
    this.currentStageId$,
  ]).pipe(
    map(([letters, recoveryData, stageId]) => {
      if (
        recoveryData.recoveryStatusId == 6 ||
        recoveryData.recoveryStatusId == 7
      ) {
        return true;
      }
      if (stageId == 1 || stageId == 2) return !!letters && letters.length > 0;
      return false;
    })
  );

  enableNextBtn$: Observable<boolean> = combineLatest([
    this.customerRecoveryData$,
    this.currentStageId$,
    this.enableNextBtnFormValidation$$.asObservable(),
    this.anchorStageId$,
    this.anchorTaskIdLoaded$$.asObservable(),
  ]).pipe(
    map(
      ([
        recoveryData,
        stageId,
        formValid,
        anchorStageID,
        anchorTaskIdLoaded,
      ]) => {
        let enableFlag: boolean = true;
        if (
          recoveryData.recoveryStatusId === 6 ||
          recoveryData.recoveryStatusId === 7
        )
          enableFlag = enableFlag && stageId === anchorStageID ? false : true;
        if (stageId === 5) enableFlag = enableFlag && false;
        return enableFlag && formValid && anchorTaskIdLoaded;
      }
    )
  );

  hideNextBtn$: Observable<boolean> = combineLatest([
    this.currentStageId$,
    this.shouldShowIssueInvoiceScreen$,
  ]).pipe(
    map(([stageId, showInvoiceScreen]) => {
      let hiddenFlag: boolean = false;
      if (stageId === 4 && !showInvoiceScreen) hiddenFlag = true;
      return hiddenFlag;
    })
  );

  bookingCargoDetails$: Observable<gcssBookingInfo> = combineLatest([
    this.customerRecoveryData$,
    this.bookingNumber$$.asObservable(),
  ]).pipe(
    switchMap(([customerRecoveryDto, bookingNo]) => {
      return this._customerRecoveryClaimService
        .customerRecoveryClaimsCaseNumberBookingDetailsGet(
          customerRecoveryDto.recoveryCaseNumber ?? '',
          this.apiVersion
        )
        .pipe(
          map((response) => response.gcssBookingInfo!),
          shareReplay(1)
        );
    })
  );

  damageDetails$: Observable<CaseDamageDetailDto[] | undefined> = combineLatest(
    [
      this.customerRecoveryData$,
      this.shouldReloadDamageDetails$$.asObservable(),
    ]
  ).pipe(
    switchMap(([customerRecoveryData]) => {
      return this._customerRecoveryClaimService
        .customerRecoveryClaimsDamageDetailCaseIdGet(
          customerRecoveryData.caseId ?? 0,
          this.apiVersion
        )
        .pipe(map((response) => response.data));
    }),
    shareReplay(1)
  );

  /**
   * This observable holds the current selected .current tab in
   * We share the same response to its observables until changed.
   */
  currentTabIndex$: Observable<number | 0> = this.tabIndex$$.asObservable();

  reloadDamageDetails(): void {
    this.shouldReloadDamageDetails$$.next(true);
  }

  reloadLiabilityPartyDetails(): void {
    this.shouldReloadLiabilityPartyDetails$$.next(true);
  }

  reloadLiabilityLetters() {
    this.shouldReloadLiabilityLetters$$.next(true);
  }

  reloadInvoices() {
    this.shouldReloadInvoices$$.next(true);
  }

  updateLiabilityDetailsVisibility(value: boolean) {
    this.shouldShowLiabilityDetailsSubject$$.next(value);
  }

  updateIssueInvoiceVisibility(value: boolean) {
    this.shouldShowIssueInvoiceScreenSubject$$.next(value);
  }

  /**
   * We update the shared customerRecoveryCaseDetails with this method.
   * @param data new customer recovery case details
   */
  updateCustomerRecoveryData(data: CustomerRecoveryCaseDto): void {
    this.customerRecoveryDataSubject$$.next(data);
  }

  /**
   * We update the shared customerRecoveryCaseDetails with this method.
   * @param data new customer recovery case details
   */
  updateCurrentStageId(stageId: number): void {
    this.currentStageId$$.next(stageId);
  }

  updateAnchorLoaded(load: boolean): void {
    this.anchorTaskIdLoaded$$.next(load);
  }
  /**
   * We update the shared CustomerData with this method.
   * @param data new customer info
   */
  updateCustomerData(data: CustomerInfoDto): void {
    this.customerDataSubject$$.next(data);
  }

  /**
   * We update the current booking and cargo details with this method.
   * @param data new stage id
   */
  updateBookingNumber(bookingNo: string): void {
    this.bookingNumber$$.next(bookingNo);
  }

  updateTabSelected(tabIndex: number) {
    this.tabIndex$$.next(tabIndex);
  }

  deepClone(obj: any): any {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => this.deepClone(item));
    }

    const clonedObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        clonedObj[key] = this.deepClone(obj[key]);
      }
    }
    return clonedObj;
  }

  getUTCDateAndTime(date: Date | undefined): string {
    if (!date) return '--:--:-- --:--:--';

    const d = new Date(date);
    const year = d.getUTCFullYear().toString();
    const month = (d.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
    const day = d.getUTCDate().toString().padStart(2, '0');
    const hours = d.getUTCHours().toString().padStart(2, '0');
    const minutes = d.getUTCMinutes().toString().padStart(2, '0');
    const seconds = d.getUTCSeconds().toString().padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  convertDateTimeToUTCDateTime(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
  }

  getUTCDateAfterAddingDays(date: Date | undefined, daysToAdd: number): string {
    if (!date) return '--:--:--';

    const d = new Date(date);
    let daysAdded = 0;

    while (daysAdded < daysToAdd) {
      d.setUTCDate(d.getUTCDate() + 1);
      // Check if the new date is a weekend
      const dayOfWeek = d.getUTCDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        // 0 = Sunday, 6 = Saturday
        daysAdded++;
      }
    }

    const year = d.getUTCFullYear().toString();
    const month = (d.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
    const day = d.getUTCDate().toString().padStart(2, '0');

    return `${day}-${month}-${year}`;
  }

  convertSecondsToDayHourMinSec(seconds: number) {
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return `${d > 0 ? `${d}d` : '00d'} : ${h > 0 ? `${h}h` : '00h'} : ${m > 0 ? `${m}m` : '00m'} : ${s > 0 ? `${s}s` : '00s'}`;
  }
}
