/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type WorkFlowStage =
  | 'New'
  | 'DamageEstimation'
  | 'Liability'
  | 'Invoice'
  | 'Complete';

export const WorkFlowStage = {
  New: 'New' as WorkFlowStage,
  DamageEstimation: 'DamageEstimation' as WorkFlowStage,
  Liability: 'Liability' as WorkFlowStage,
  Invoice: 'Invoice' as WorkFlowStage,
  Complete: 'Complete' as WorkFlowStage,
};
