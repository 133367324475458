import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';

import { WorkflowSteps } from '../../../common/models/workflowSteps';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { stageLoad } from '../../../common/models/stageLoad';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { GlobalService } from '../../../global-service';

@Component({
  selector: 'app-workflow-stepper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-workflow-stepper.component.html',
  styleUrl: './app-workflow-stepper.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppWorkflowStepperComponent {
  @Input({ required: true }) stages!: TemplateModel[] | null;
  @Output() loadSelectedStage = new EventEmitter<stageLoad>();
  nextStageId: number = 0;
  workflowSteps?: WorkflowSteps[] = [];
  currentStageSequence: number = 0;
  anchorStageId: number = 1;

  constructor(private globalService: GlobalService) {}

  currentStageId$: Observable<number> = combineLatest([
    this.globalService.currentStageId$,
    this.globalService.anchorStageId$,
  ]).pipe(
    tap(([id, anchorId]) => {
      this.anchorStageId = anchorId;
      this.loadStepper(id);
    }),
    map(([currentStageId]) => currentStageId)
  );

  loadStepper(stageId: number) {
    this.currentStageSequence =
      this.stages?.filter((o) => o.id == stageId)[0]?.sequence ?? 0;
    this.workflowSteps = this.stages
      ?.filter((o) => !o.hidden)
      .sort((a, b) => ((a.sequence ?? 0) > (b.sequence ?? 0) ? 1 : -1))
      ?.map((o) => {
        if (o.sequence == undefined) return;
        return {
          name: o.label,
          state:
            o.sequence >= this.currentStageSequence
              ? o.sequence == this.currentStageSequence
                ? 'current'
                : 'pending'
              : 'completed',
        } as WorkflowSteps;
      }) as WorkflowSteps[];
  }

  showStage(sequence: number) {
    if (this.currentStageSequence == sequence) return;
    const anchorSequenceID =
      this.stages?.filter((o) => o.id == this.anchorStageId)[0]?.sequence ?? 0;
    if (sequence > anchorSequenceID) return;
    this.currentStageSequence = sequence;
    const stageID =
      this.stages?.filter((o) => o.sequence == sequence)[0]?.id ?? 0;
    const newStage = this.stages?.filter((i: any) => i.id == stageID)[0];
    if (newStage) {
      this.loadSelectedStage.emit({
        template: newStage,
        refreshAnchorNeeded: false,
      } as stageLoad);
      this.globalService.updateCurrentStageId(stageID);
    }
  }
}
