import {
  BehaviorSubject,
  Observable,
  combineLatest,
  lastValueFrom,
  map,
  tap,
} from 'rxjs';
import {
  TEMPLATE_TYPE,
  WORKFLOW_TYPE,
} from '../../../common/constants/app.constants';
import { VersionDetails } from '../../../common/models/version-details';
import { WorkflowService } from '../../../common/services/customer-recovery/workflow.service';
import { StageTemplateResponse } from '../../../common/models/stage-template-response';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import * as template from '../../../../assets/json/dcrp-workflow.json';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { GlobalService } from '../../../global-service';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { CaseDetailsDto } from '../../../common/models/caseDetailsDto';
export class CustomerRecoveryWorkflowDataManager {
  //Latest Template and workflow version details
  latestWorkFlowVersionDetail?: VersionDetails;
  latestTemplateVersionDetail?: VersionDetails;

  latestTemplate?: any;
  anchorData?: StageTemplateResponse;
  //Session list for processVersion and templateVersionList
  processVersionListSession?: VersionDetails[];
  templateVersionListSession?: any;

  stages: TemplateModel[] = [];
  currentStageId: number = 0;

  localTemplate: any = (template as any).default;
  caseDetails!: CustomerRecoveryCaseDto;
  apiVersion: string = '1.0';

  recoveryStatusId$: Observable<number> = combineLatest([
    this.globalService.currentStageId$,
    this.globalService.customerRecoveryData$,
  ]).pipe(
    tap(([stageId, recoveryData]) => {
      this.caseDetails = recoveryData as CustomerRecoveryCaseDto;
      this.currentStageId = stageId == 7 || stageId == 6 ? 1 : stageId ?? 1;
      this.initialPropertiesForVersioning();
      this.templateBindingOnStageChange();
    }),
    map(([stageId, recoveryData]) =>
      stageId == 7 || stageId == 6 ? 1 : stageId ?? 1
    )
  );

  constructor(
    private workflowService: WorkflowService,
    private customerRecoveryClaimService: CustomerRecoveryClaimService,
    private route: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService
  ) {}

  templateBindingOnStageChange() {
    if (environment.localTemplate) {
      //local template for development always latest template
      this.methodUsingLocalTemplate();
    } else {
      //this.methodForNonlocalTemplate();
    }
  }

  methodUsingLocalTemplate() {
    let locTem = JSON.parse(JSON.stringify(this.localTemplate));
    this.stages = locTem.stages;
  }

  setCurrentStageVariableBasedOnTemplate(stages: any) {
    let filterCurrentStage = stages?.templates?.filter(
      (i: any) => i.CurrentStageTemplate.value.id == this.currentStageId
    )[0];
    this.stages = filterCurrentStage.Stageheaders?.value
      ?.stages as TemplateModel[];
  }

  refreshAnchorData() {
    this.globalService.updateAnchorLoaded(false);
    this.globalService.refreshAnchorFlowData$$.next(true);
  }

  initialPropertiesForVersioning() {
    this.processVersionListSession = sessionStorage.getItem(
      'dcrp-version-details'
    )
      ? JSON.parse(sessionStorage.getItem('dcrp-version-details') ?? '')
      : null;
    this.templateVersionListSession = sessionStorage.getItem(
      'dcrp-template-version'
    )
      ? JSON.parse(sessionStorage.getItem('dcrp-template-version') ?? '')
      : null;

    if (
      this.processVersionListSession &&
      this.templateVersionListSession &&
      this.templateVersionListSession.length > 0
    ) {
      this.latestTemplate = this.templateVersionListSession?.reduce(
        (item: any, curr: any) => {
          return item.version < curr.version ? curr : item;
        }
      );

      this.latestWorkFlowVersionDetail = this.processVersionListSession
        ?.filter((i: VersionDetails) => i.processType == WORKFLOW_TYPE)
        ?.reduce((item: VersionDetails, curr: VersionDetails) => {
          return item.version ?? 0 < (curr.version ?? 0) ? curr : item;
        });

      this.latestTemplateVersionDetail = this.processVersionListSession
        ?.filter((i: VersionDetails) => i.processType == TEMPLATE_TYPE)
        ?.reduce((item: VersionDetails, curr: VersionDetails) => {
          return item.version ?? 0 < (curr.version ?? 0) ? curr : item;
        });
    }
  }

  //Needed code -- temporary commented
  // //Template from anchor
  // methodForNonlocalTemplate() {
  //   if (this.latestTemplateVersionDetail && this.latestWorkFlowVersionDetail) {
  //     let migrationNeeded = false;
  //     let CurrentTemplateVersionModified = !this.caseDetails.templateVersion
  //       ? this.latestTemplateVersionDetail.version
  //       : (this.caseDetails.templateVersion as number);
  //     let currentWorkflowVersionModified = !this.caseDetails.workFlowVersion
  //       ? this.latestWorkFlowVersionDetail.version
  //       : (this.caseDetails?.workFlowVersion as number);

  //     let workflowVersionList = this.processVersionListSession?.filter(
  //       (i) => i.processType == WORKFLOW_TYPE
  //     ) as VersionDetails[];
  //     let workflowVersionFromDetail = workflowVersionList?.filter(
  //       (i) =>
  //         i.definitionId == this.anchorData?.taskDetails?.processDefinitionId
  //     )[0]?.version;
  //     // migrate check for bpm(workflow)
  //     if (
  //       this.anchorData?.taskDetails?.processDefinitionId !=
  //       this.latestWorkFlowVersionDetail?.definitionId
  //     ) {
  //       //check claim not running in latest template
  //       //check can be upgrade
  //       if (!this.caseDetails?.stopUpgrade) {
  //         //CheckIfVersionCanBeMigrated return which version to migrate
  //         currentWorkflowVersionModified = this.checkIfVersionCanBeMigrated(
  //           this.latestWorkFlowVersionDetail,
  //           currentWorkflowVersionModified,
  //           workflowVersionList
  //         );
  //         //Check if claim need migration

  //         if (
  //           currentWorkflowVersionModified != this.caseDetails?.workFlowVersion
  //         ) {
  //           migrationNeeded = true;
  //         }
  //       }
  //     } else if (
  //       workflowVersionFromDetail &&
  //       currentWorkflowVersionModified != workflowVersionFromDetail
  //     ) {
  //       currentWorkflowVersionModified = this.latestWorkFlowVersionDetail
  //         ?.version as number;
  //     }

  //     //migrate check for dmn (template)
  //     if (
  //       this.latestTemplateVersionDetail?.version !=
  //       this.caseDetails?.templateVersion
  //     ) {
  //       // if claim not using latest template
  //       if (!this.caseDetails.stopUpgrade) {
  //         let templateVersionList = this.processVersionListSession?.filter(
  //           (i) => i.processType == TEMPLATE_TYPE
  //         ) as VersionDetails[];
  //         //CheckIfVersionCanBeMigrated return which version to migrate
  //         CurrentTemplateVersionModified = this.checkIfVersionCanBeMigrated(
  //           this.latestTemplateVersionDetail,
  //           CurrentTemplateVersionModified,
  //           templateVersionList
  //         );
  //         //Check if claim need migration
  //       }
  //     }
  //     //
  //     if (migrationNeeded) {
  //       this.migrate(
  //         this.anchorData?.taskDetails?.processDefinitionId ?? '',
  //         currentWorkflowVersionModified,
  //         CurrentTemplateVersionModified
  //       );
  //     } else if (
  //       currentWorkflowVersionModified != this.caseDetails.workFlowVersion ||
  //       CurrentTemplateVersionModified != this.caseDetails.templateVersion
  //     ) {
  //       this.updateClaimDataWithLatestVersion(
  //         currentWorkflowVersionModified,
  //         CurrentTemplateVersionModified
  //       );
  //     }
  //     this.templateBindAndExistCheck();
  //   }
  // }

  // async migrate(
  //   SourceWorkflowDefinitionId: string,
  //   targetWorkflowVersion: number,
  //   templateVersion: number
  // ) {
  //   this.caseDetails.templateVersion = templateVersion;
  //   let targetWorkflowDefinitionId = this.processVersionListSession?.filter(
  //     (i) =>
  //       i.version == targetWorkflowVersion && i.processType == WORKFLOW_TYPE
  //   )[0].definitionId;
  //   const migrate = {
  //     businessKey: this.anchorData?.businessKey,
  //     SourceWorkflowDefinitionId: SourceWorkflowDefinitionId,
  //     TargetWorkflowDefinitionId: targetWorkflowDefinitionId,
  //   };
  //   const migrationResponse = await lastValueFrom(
  //     this.workflowService.workflowProcessMigration(migrate)
  //   );
  //   if (migrationResponse && migrationResponse.statusCode == 200) {
  //     this.updateClaimDataWithLatestVersion(
  //       targetWorkflowVersion,
  //       templateVersion
  //     );
  //   }
  // }

  async updateClaimDataWithLatestVersion(
    migrateWorkflowVersion: number,
    migrateTemplateVersion: number
  ) {
    const request = this.caseDetails;
    request.workFlowVersion = migrateWorkflowVersion;
    request.templateVersion = migrateTemplateVersion;
    if (
      this.latestTemplateVersionDetail?.version != migrateTemplateVersion ||
      this.latestWorkFlowVersionDetail?.version != migrateWorkflowVersion
    ) {
      request.stopUpgrade = true;
    }
    const caseRequest = {
      customerRecoveryCaseDto: request,
    } as CaseDetailsDto;

    await lastValueFrom(
      this.customerRecoveryClaimService
        .customerRecoveryClaimsUpdatePost(caseRequest, this.apiVersion)
        .pipe(
          map((response) => {
            if (response.data) {
              this.globalService.updateCustomerRecoveryData(request);
            }
          })
        )
    );
  }

  async templateBindAndExistCheck() {
    let templateFromSession = this.templateVersionListSession?.filter(
      (i: any) => i.version == this.caseDetails.templateVersion
    )[0];
    if (templateFromSession) {
      this.setCurrentStageVariableBasedOnTemplate(
        templateFromSession?.templates
      );
    } else {
      const req = {
        workflowType: 'Reefer_Cargo_Workflow', // TODO need to change once dmn in created for dcrp
        stage: 'Stage',
        TemplateDefinitionIds: `${this.caseDetails.templateVersion} = ${this.processVersionListSession?.filter((i) => i.version == this.caseDetails.templateVersion && i.processType == TEMPLATE_TYPE)[0]?.definitionId} `,
      };
      //TODO need to add new template which is not present in session
      let template = await lastValueFrom(
        this.workflowService.workflowGetAllTemplates(req)
      );
    }
  }

  // checkIfVersionCanBeMigrated(
  //   latestProcess: VersionDetails,
  //   version: number,
  //   processList: VersionDetails[]
  // ) {
  //   //return version which case need to be updated else return its current version only
  //   while (version != latestProcess.version) {
  //     const versionForMigrationCheck = processList.filter(
  //       (i) => i.version == version + 1
  //     )[0];
  //     if (
  //       !versionForMigrationCheck?.lowestStageImpact ||
  //       (this.getSequenceBasedOnStageID(
  //         this.anchorData?.stageVariables?.CurrentStage?.value ?? 0
  //       ) != null && // if deleted
  //         this.getSequenceBasedOnStageID(
  //           this.anchorData?.stageVariables?.CurrentStage?.value ?? 0
  //         ) <=
  //           this.getSequenceBasedOnStageID(
  //             versionForMigrationCheck.lowestStageImpact
  //           ))
  //     ) {
  //       version++;
  //     } else {
  //       return version;
  //     }
  //   }
  //   return version;
  // }

  getSequenceBasedOnStageID(stageId: number) {
    //return sequence of stage id of always latest template
    let headers = this.latestTemplate.templates[0].Stageheaders.value;
    let searchSequence = headers?.stages?.filter((i: any) => i.id == stageId);
    return searchSequence?.length > 0 ? searchSequence[0]?.sequence : null;
  }
}
