import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import {
  ALIGN,
  GridComponent,
  GridRowData,
  GridCellData,
  GridColumnSchema,
  LibFormComponent,
  ModalNotificationService,
  Toast,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { GlobalService } from '../../../global-service';
import {
  BehaviorSubject,
  Observable,
  lastValueFrom,
  map,
  merge,
  skip,
  tap,
} from 'rxjs';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { ContainerMove } from '../../../common/models/containerMove';
import { UPDATEBOOKINGNOMSG } from '../../../common/constants/app.constants';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
@Component({
  selector: 'app-workflow-container-moves',
  standalone: true,
  imports: [LibFormComponent, CommonModule, GridComponent],
  templateUrl: './app-workflow-container-moves.component.html',
  styleUrl: './app-workflow-container-moves.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppWorkflowContainerMovesComponent {
  @Input({ required: true }) item!: TemplateModel;
  @Input() fromDate!: string;
  @Input() toDate!: string;
  @Input() containerNumber: string | undefined;
  @Input() caseNumber!: string;
  @Input() recoveryData!: CustomerRecoveryCaseDto;
  @Input() disable?: boolean | false;
  gridData: GridRowData[] | null = null;
  gridSchema: GridColumnSchema[] = [];
  Schema: GridColumnSchema[] = [];
  containerMovesSchema: GridColumnSchema[] = [];
  containerMovesGridData$?: Observable<GridRowData[] | null>;
  /**
   * Subject to hold the latest list of moves.
   */
  movesSubject$$: BehaviorSubject<GridRowData[] | null> = new BehaviorSubject<
    GridRowData[] | null
  >([]);
  /**
   * Initial list of moves.
   */
  movesInitial$?: Observable<GridRowData[] | null>;
  selectedMoves?: [{ [key: string]: unknown }];
  constructor(
    private customerRecoveryService: CustomerRecoveryClaimService,
    private globalService: GlobalService,
    private modalService: ModalNotificationService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.loadContainerMoves();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item'] && changes['item'].currentValue) {
      this.loadSchema();
    }

    this.loadContainerMoves();
  }

  loadSchema() {
    if (this.item && this.item.items) {
      this.gridSchema = this.item.items.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          displayName: y.label,
          align: ALIGN.LEFT,
          hidden: false,
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),

          disableSort: true,
          filterGroupId: 0,
        } as GridColumnSchema;
        return column;
      });
    }
  }

  loadContainerMoves() {
    const { fromDate, toDate, containerNumber, caseNumber } = this;
    const parsedFromDate = fromDate ? this.parseDateFromString(fromDate) : null;
    const parsedToDate = toDate ? this.parseDateFromString(toDate) : null;

    if (!parsedFromDate || !parsedToDate || !containerNumber) return;

    this.movesInitial$ = this.customerRecoveryService
      .customerRecoveryClaimsCaseNumberGet(
        caseNumber,
        containerNumber,
        parsedFromDate,
        parsedToDate
      )
      .pipe(
        map((response) => {
          const { data } = response;
          if (!data || !data.containerMoves) return null;
          return data.containerMoves.map((move) =>
            this.generateGridDataFromContainerMoves(move)
          );
        }),
        tap((moves) => {
          this.movesSubject$$.next(moves);
        })
      );

    this.containerMovesGridData$ = merge(
      this.movesInitial$,
      this.movesSubject$$.asObservable()
    );
  }

  private generateGridDataFromContainerMoves(
    containerMove: ContainerMove
  ): GridRowData {
    const customerRecoveryKeyValue = containerMove as unknown as {
      [key: string]: unknown;
    };
    const gridRowObject: { [key: string]: GridCellData } = {};
    Object.keys(containerMove).map((key) => {
      gridRowObject[key] = {
        disabled: this.disable,
        value: customerRecoveryKeyValue[key],
      } as GridCellData;
    });
    return {
      row: gridRowObject,
    } as GridRowData;
  }

  parseDateFromString(dateInDDMMYYYY: string) {
    let separator = '/';
    if (!dateInDDMMYYYY.includes(separator)) {
      separator = '-';
    }
    const [day, month, year] = dateInDDMMYYYY.split(separator).map(Number);
    return new Date(year, month - 1, day); // Month is zero-based, so subtract 1
  }

  rowSelectionChanged(moves: { [key: string]: unknown }[]) {
    this.selectedMoves = undefined;
    if (!moves || moves.length == 0 || moves.length > 1) {
      this.selectedMoves = undefined;
      return;
    }
    if (moves && moves.length == 1) {
      this.selectedMoves = moves as [{ [key: string]: unknown }];
      const bookingNumber = this.selectedMoves?.map(
        (row) => (row['bookingNumber'] as GridCellData).value as string
      )[0];
      if (!bookingNumber) return;
      this.modalService.openModal({
        primaryButtonMessage: 'Update',
        message: UPDATEBOOKINGNOMSG,
        header: 'Update Booking Number',
        dimension: 'small',
        secondaryButtonMessage: 'Cancel',
        onPrimaryBtnClick: this.updateBookingNumber.bind(this),
        onSecondaryBtnClick: this.unCheckRadioSelection.bind(this),
      });
    }
  }

  async updateBookingNumber() {
    const bookingNumber = this.selectedMoves?.map(
      (row) => (row['bookingNumber'] as GridCellData).value as string
    )[0];
    if (bookingNumber)
      await lastValueFrom(
        this.customerRecoveryService
          .customerRecoveryClaimsBookingNumberUpdatePost(
            this.caseNumber,
            bookingNumber,
            '1.0'
          )
          .pipe(
            map((response) => {
              if (!response.data) return;
              this.recoveryData.bookingNumber = bookingNumber;
              this.globalService.updateCustomerRecoveryData(this.recoveryData);
              this.unCheckRadioSelection();
              this.toaster.showToast({
                message: 'Booking Number updated!',
                type: 'success',
              } as Toast);
            })
          )
      );
  }

  unCheckRadioSelection() {
    const moves = this.movesSubject$$.value;
    moves?.forEach((i) => (i.isRowSelected = false));
    this.movesSubject$$.next(moves as GridRowData[]);
  }
}
