<div *ngIf="isSiteUndermaintenance; else siteNotUndermaintenance">
  <div class="container">
    <h1>Site Launching Soon</h1>
    <p>
      We're currently working hard to bring you an amazing website. Please check
      back later!
    </p>
  </div>
</div>
<ng-template #siteNotUndermaintenance>
  <div *ngIf="loggedIn; else notloggedin">
    <app-header>
      <img src="assets/svg/logo.svg" />
      <span>Claims Management System</span>
    </app-header>
    <app-sidenav></app-sidenav>
    <div>
      <app-content>
        <router-outlet></router-outlet>
      </app-content>
    </div>
  </div>
  <div style="visibility: hidden">{{ login_url }}</div>
  <ng-template #notloggedin>
    <div class="authentication-container">
      <div class="authentication-box">
        <div class="authentication-message">
          <span>
            User not authenticated. Kindly authenticate using this
            <a [href]="login_url" (click)="redirectToLogin()">link</a>
            .
          </span>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>
<app-modal-notification></app-modal-notification>
<lib-toaster></lib-toaster>
<lib-email-modal></lib-email-modal>
<lib-loader></lib-loader>
