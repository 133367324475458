<ng-container *ngIf="caseDetails$ | async as caseDetails; else pageLoader">
  <div class="workflow" *ngIf="currentStageId$ | async as currentStageId">
    <div class="bc-header">
      <div class="breadcrumb">
        <span class="path1">
          <a (click)="onNavigateBackToCaseList()">{{ tabName$ | async }}</a>
          /
        </span>
        <span class="path2">Case {{ caseDetails.recoveryCaseNumber }}</span>
      </div>
    </div>

    <div class="header">
      <span class="headerText">
        Case - {{ caseDetails.recoveryCaseNumber }}
      </span>
      <mc-icon icon="info-circle"></mc-icon>
    </div>

    <div>
      <app-workflow-form
        [stages]="stages"
        (stageMovedToNextOrBack)="
          stageMovedToNextOrBack($event)
        "></app-workflow-form>
    </div>
  </div>
</ng-container>

<ng-template #pageLoader>
  <lib-page-skeleton-loader></lib-page-skeleton-loader>
</ng-template>
