/**
 * DCRP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UserPreferenceDTO } from '../../models/userPreferenceDTO';
import { VersionDetails } from '../../models/version-details';
import { CurrencyAndExchangeRateDto } from '../../models/currencyAndExchangeRateDto';
import { ClusterDto } from '../../models/clusterDto';
import { CountryDto } from '../../models/countryDto';
import { CarrierCodeDto } from '../../models/carrierCodeDto';
import { CaseStatusDto } from '../../models/case-status';
import { WorkOrdersResponse } from '../../models/work-order-status';
import { CustomerInfoDtoResponse } from '../../models/customerInfoDtoResponse';
import { CurrencyAndExchangeRateDtoListResponse } from '../../models/currencyAndExchangeRateDtoListResponse';
import { CollectionOfficeDtoIEnumerableResponse } from '../../models/collectionOfficeDtoIEnumerableResponse';
import { GeoSiteDtoIEnumerableResponse } from '../../models/geoSiteDtoIEnumerableResponse';
import { RegionIEnumerableResponse } from '../../models/regionIEnumerableResponse';

@Injectable()
export class CommonService {
  protected basePath = environment.dcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param viewname
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonViewdetailsViewnameGet(
    viewname: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public commonViewdetailsViewnameGet(
    viewname: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public commonViewdetailsViewnameGet(
    viewname: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public commonViewdetailsViewnameGet(
    viewname: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (viewname === null || viewname === undefined) {
      throw new Error(
        'Required parameter viewname was null or undefined when calling commonViewdetailsViewnameGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<any>(
      'get',
      `${this.basePath}/common/view-details/${encodeURIComponent(String(viewname))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param userId
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonUserPreferenceDelete(
    userId: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserPreferenceDTO>;
  public commonUserPreferenceDelete(
    userId: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserPreferenceDTO>>;
  public commonUserPreferenceDelete(
    userId: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserPreferenceDTO>>;
  public commonUserPreferenceDelete(
    userId: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling commonUserPreferenceDelete.'
      );
    }

    let queryParameters = new HttpParams();
    if (userId !== undefined && userId !== null) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<UserPreferenceDTO>(
      'delete',
      `${this.basePath}/common/user-preference`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param userId
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonUserPreferenceGet(
    userId: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserPreferenceDTO>;
  public commonUserPreferenceGet(
    userId: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserPreferenceDTO>>;
  public commonUserPreferenceGet(
    userId: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserPreferenceDTO>>;
  public commonUserPreferenceGet(
    userId: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error(
        'Required parameter userId was null or undefined when calling commonUserPreferenceGet.'
      );
    }

    let queryParameters = new HttpParams();
    if (userId !== undefined && userId !== null) {
      queryParameters = queryParameters.set('userId', <any>userId);
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<UserPreferenceDTO>(
      'get',
      `${this.basePath}/common/user-preference`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonUserPreferencePost(
    body: UserPreferenceDTO,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserPreferenceDTO>;
  public commonUserPreferencePost(
    body: UserPreferenceDTO,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserPreferenceDTO>>;
  public commonUserPreferencePost(
    body: UserPreferenceDTO,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserPreferenceDTO>>;
  public commonUserPreferencePost(
    body: UserPreferenceDTO,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling commonUserPreferencePost.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<UserPreferenceDTO>(
      'post',
      `${this.basePath}/common/user-preference`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonUserPreferencePut(
    body: UserPreferenceDTO,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserPreferenceDTO>;
  public commonUserPreferencePut(
    body: UserPreferenceDTO,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserPreferenceDTO>>;
  public commonUserPreferencePut(
    body: UserPreferenceDTO,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserPreferenceDTO>>;
  public commonUserPreferencePut(
    body: UserPreferenceDTO,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling commonUserPreferencePut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<UserPreferenceDTO>(
      'put',
      `${this.basePath}/common/user-preference`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonVersionDetailsGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<VersionDetails[]>;
  public commonVersionDetailsGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<VersionDetails[]>>;
  public commonVersionDetailsGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<VersionDetails[]>>;
  public commonVersionDetailsGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<VersionDetails>(
      'get',
      `${this.basePath}/common/workflow-template-versions`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonCountriesGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CountryDto[]>;
  public commonCountriesGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CountryDto[]>>;
  public commonCountriesGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CountryDto[]>>;
  public commonCountriesGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<CountryDto>(
      'get',
      `${this.basePath}/common/countries`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonCountryClustersGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ClusterDto[]>;
  public commonCountryClustersGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ClusterDto[]>>;
  public commonCountryClustersGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ClusterDto[]>>;
  public commonCountryClustersGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<ClusterDto>(
      'get',
      `${this.basePath}/common/country-clusters`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonCurrencyAndExchangeRatesGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CurrencyAndExchangeRateDto[]>;
  public commonCurrencyAndExchangeRatesGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CurrencyAndExchangeRateDto[]>>;
  public commonCurrencyAndExchangeRatesGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CurrencyAndExchangeRateDto[]>>;
  public commonCurrencyAndExchangeRatesGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<CurrencyAndExchangeRateDto>(
      'get',
      `${this.basePath}/common/currency-and-exchange-rates`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Returns currency exchange rates for the provided date
   * @param quotationDateTime
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonCurrencyAndExchangeRatesForQuotationDateGet(
    quotationDateTime: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CurrencyAndExchangeRateDtoListResponse>;
  public commonCurrencyAndExchangeRatesForQuotationDateGet(
    quotationDateTime: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CurrencyAndExchangeRateDtoListResponse>>;
  public commonCurrencyAndExchangeRatesForQuotationDateGet(
    quotationDateTime: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CurrencyAndExchangeRateDtoListResponse>>;
  public commonCurrencyAndExchangeRatesForQuotationDateGet(
    quotationDateTime: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<CurrencyAndExchangeRateDto>(
      'get',
      `${this.basePath}/common/currency-exchange-rate?quotationDateTime=${encodeURIComponent(String(quotationDateTime))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonCarrierCodesGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CarrierCodeDto[]>;
  public commonCarrierCodesGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CarrierCodeDto[]>>;
  public commonCarrierCodesGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CarrierCodeDto[]>>;
  public commonCarrierCodesGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);
    return this.httpClient.request<CarrierCodeDto>(
      'get',
      `${this.basePath}/common/carrier-codes`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get list of valid case status for a case.
   *
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonCaseStatusGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<CaseStatusDto>>;
  public commonCaseStatusGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<CaseStatusDto>>>;
  public commonCaseStatusGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<CaseStatusDto>>>;
  public commonCaseStatusGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<Array<CaseStatusDto>>(
      'get',
      `${this.basePath}/common/case-status`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get work order status details.
   *
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonWorkOrderStatusGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<WorkOrdersResponse>;
  public commonWorkOrderStatusGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<WorkOrdersResponse>>;
  public commonWorkOrderStatusGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<WorkOrdersResponse>>;
  public commonWorkOrderStatusGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);
    return this.httpClient.request<WorkOrdersResponse>(
      'get',
      `${this.basePath}/common/work-order/status`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   *
   *
   * @param cmdCustomerCode
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonCustomersCmdCustomerCodeGet(
    cmdCustomerCode: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CustomerInfoDtoResponse>;
  public commonCustomersCmdCustomerCodeGet(
    cmdCustomerCode: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CustomerInfoDtoResponse>>;
  public commonCustomersCmdCustomerCodeGet(
    cmdCustomerCode: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CustomerInfoDtoResponse>>;
  public commonCustomersCmdCustomerCodeGet(
    cmdCustomerCode: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (cmdCustomerCode === null || cmdCustomerCode === undefined) {
      throw new Error(
        'Required parameter cmdCustomerCode was null or undefined when calling commonCustomersCmdCustomerCodeGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);
    return this.httpClient.request<CustomerInfoDtoResponse>(
      'get',
      `${this.basePath}/common/customers/${encodeURIComponent(String(cmdCustomerCode))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Returns Collection Office Master Data and will be used in invoicing
   *
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonCollectionOfficeGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CollectionOfficeDtoIEnumerableResponse>;
  public commonCollectionOfficeGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CollectionOfficeDtoIEnumerableResponse>>;
  public commonCollectionOfficeGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CollectionOfficeDtoIEnumerableResponse>>;
  public commonCollectionOfficeGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);
    return this.httpClient.request<CollectionOfficeDtoIEnumerableResponse>(
      'get',
      `${this.basePath}/common/collection-offices`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Returns matching Geo Site Codes available
   *
   * @param geoSiteCodePattern Geo Site Code pattern
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonGeographySitesGeoSiteCodePatternGet(
    geoSiteCodePattern: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GeoSiteDtoIEnumerableResponse>;
  public commonGeographySitesGeoSiteCodePatternGet(
    geoSiteCodePattern: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GeoSiteDtoIEnumerableResponse>>;
  public commonGeographySitesGeoSiteCodePatternGet(
    geoSiteCodePattern: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GeoSiteDtoIEnumerableResponse>>;
  public commonGeographySitesGeoSiteCodePatternGet(
    geoSiteCodePattern: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (geoSiteCodePattern === null || geoSiteCodePattern === undefined) {
      throw new Error(
        'Required parameter geoSiteCodePattern was null or undefined when calling commonGeographySitesGeoSiteCodePatternGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);
    return this.httpClient.request<GeoSiteDtoIEnumerableResponse>(
      'get',
      `${this.basePath}/common/geography/sites/${encodeURIComponent(String(geoSiteCodePattern))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  

  /**
   *
   * Fetches list of regions
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public commonRegionsGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RegionIEnumerableResponse>;
  public commonRegionsGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RegionIEnumerableResponse>>;
  public commonRegionsGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RegionIEnumerableResponse>>;
  public commonRegionsGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];

    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<RegionIEnumerableResponse>(
      'get',
      `${this.basePath}/common/regions`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
