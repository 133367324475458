import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Theme } from './theme';
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private style!: HTMLLinkElement;
  private themeCssId: string = 'theme-css-id';
  constructor(@Inject(DOCUMENT) private document: Document) {}
  setTheme(theme: Theme, renderer: Renderer2) {
    this.removeExisitingTheme(renderer);
    this.style = renderer.createElement('link') as HTMLLinkElement;
    renderer.setProperty(this.style, 'rel', 'stylesheet');

    // The CSS file name has to match with the bundle name given in angular.json for the theme css files
    renderer.setProperty(this.style, 'href', `${theme}.css`);

    // Assign an id to the link element so that it can be removed when the theme is changed
    renderer.setProperty(this.style, 'id', this.themeCssId);

    // Add the link element to the head of the document for theme changes to take effect
    renderer.appendChild(this.document.head, this.style);
  }
  private removeExisitingTheme(renderer: Renderer2) {
    const themeIDHtmlElement = this.document.getElementById(this.themeCssId);
    if (themeIDHtmlElement) {
      renderer.removeChild(this.document.head, themeIDHtmlElement);
    }
  }
}
