import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AppWorkflowTabComponent } from '../app-workflow-tab/app-workflow-tab.component';
import { AppWorkflowStepperComponent } from '../app-workflow-stepper/app-workflow-stepper.component';
import { CommonModule } from '@angular/common';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { GlobalService } from '../../../global-service';
import { stageLoad } from '../../../common/models/stageLoad';
import { map, Observable, tap } from 'rxjs';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';

@Component({
  selector: 'app-workflow-form',
  standalone: true,
  imports: [AppWorkflowTabComponent, AppWorkflowStepperComponent, CommonModule],
  templateUrl: './app-workflow-form.component.html',
  styleUrl: './app-workflow-form.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppWorkflowFormComponent {
  @Input({ required: true }) stages!: TemplateModel[] | null;
  @Output() stageMovedToNextOrBack = new EventEmitter<boolean>();
  stagesLabel: string[] = [];

  ngOnInit(): void {
    this.stages?.forEach((element) => {
      if (element.name) this.stagesLabel.push(element.name);
    });
  }

  onStageMovedToNextOrBack(event: stageLoad) {
    this.stageMovedToNextOrBack.emit(event.refreshAnchorNeeded);
  }
}
