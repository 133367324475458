/**
 * CC DCRP API
 * Connected Claims Damaged Container Recovery API's.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type InvoiceStatusEnum = 'InvoiceSubmitted' | 'InvoiceRequested' | 'InvoiceRequestFailed' | 'InvoiceIssued' | 'InvoiceNotIssued' | 'CancellationSubmitted' | 'InvoiceCancellationRequested' | 'InvoiceCancellationRequestFailed' | 'InvoiceCancelled' | 'PaymentReceived';

export const InvoiceStatusEnum = {
    InvoiceSubmitted: 'InvoiceSubmitted' as InvoiceStatusEnum,
    InvoiceRequested: 'InvoiceRequested' as InvoiceStatusEnum,
    InvoiceRequestFailed: 'InvoiceRequestFailed' as InvoiceStatusEnum,
    InvoiceIssued: 'InvoiceIssued' as InvoiceStatusEnum,
    InvoiceNotIssued: 'InvoiceNotIssued' as InvoiceStatusEnum,
    CancellationSubmitted: 'CancellationSubmitted' as InvoiceStatusEnum,
    InvoiceCancellationRequested: 'InvoiceCancellationRequested' as InvoiceStatusEnum,
    InvoiceCancellationRequestFailed: 'InvoiceCancellationRequestFailed' as InvoiceStatusEnum,
    InvoiceCancelled: 'InvoiceCancelled' as InvoiceStatusEnum,
    PaymentReceived: 'PaymentReceived' as InvoiceStatusEnum
};